import React, { useEffect, useRef, useState } from "react";
import { classList as cl } from "../../../utilities/funcs.js";

import { Location } from "../../../models/location.js";

import { MiniMap } from "../../Map/Map.js";
import styles from "./LocationSelect.css";
import inputStyles from "./SearchBarInput.css";
import { useApiGetPlaceList } from "../../../hooks/api-hooks.js";

const LocationSelect = ({
  parent,
  onLocationChange,
  placeholder,
  fa,
  color = "inherit",
  flexGrow,
  showMap,
  setSuggestInput,
  onSearchComplete,
  setCurrentLocationInput,
  isEnabled,
  currentLocation,
}) => {
  const spinnerFa = "fa-solid fa-spinner fa-fw fa-spin";

  const rollbackInputValue = useRef();
  const inputMatchStateTimer = useRef();
  const inputRef = useRef();
  const outerDivRef = useRef();

  const [inputValue, setInputValue] = useState("");
  const [inputMatchState, setInputMatchState] = useState(null); // null for loading, false for no match, true for match
  const [fetchingUserLonLat, setFetchingUserLonLat] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const { data: places, error, isLoading } = useApiGetPlaceList();

  const loading = () => {
    return fetchingUserLonLat || isLoading;
  };

  const handleSetValue = (newValue, ignoreSetValue = false) => {
    const text = newValue === null ? "" : newValue.displayText;
    if (!ignoreSetValue) {
      onLocationChange(newValue);
    }
    setInputValue(text);
    rollbackInputValue.current = text;
  };

  const handlePlaceClicked = (placeName) => {
    setInputValue(placeName);
  };

  const handleOnLocateButtonClick = async () => {
    const getUserLonLat = async () => {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: 5000,
        });
      });
      return [pos.coords.longitude, pos.coords.latitude];
    };

    try {
      setFetchingUserLonLat(true);
      const lonLat = await getUserLonLat();
      setTimeout(() => {
        handleSetValue(Location.fromLonLat(...lonLat));
        setFetchingUserLonLat(false);
      }, 0);
    } catch (err) {
      setFetchingUserLonLat(false);
      alert("We were unable to get your current location.");
      console.warn(err);
    }
  };

  useEffect(() => {
    if (currentLocation !== undefined) {
      setInputValue(currentLocation);
    }
  }, [currentLocation]);

  const handleOnFocus = () => {
    setHasFocus(true);
    setSuggestInput("loc_" + inputValue);
  };

  const handleOnBlur = (e) => {
    if (outerDivRef.current?.contains(e.relatedTarget)) {
      // if focus goes to map after clicking a marker, put focus back on input
      inputRef.current?.focus();
      return;
    }
    setHasFocus(false);
    // setInputValue(rollbackInputValue.current);
    if (!parent.current?.contains(e.relatedTarget)) {
      setSuggestInput("");
    }
  };

  const handleOnChange = async (event) => {
    const val = event.target.value;
    setInputValue(val);
    setSuggestInput("loc_" + val);

    let locationObj = await Location.fromZipCodeStr(val);
    if (locationObj) {
      setCurrentLocationInput(locationObj); // This will have all the required properties
    } else {
      setCurrentLocationInput(val); // Fallback to raw value if not a valid zip
    }
  };

  const handleOnKeyDown = async (event) => {
    if (event.key === "Enter") {
      let test;
      let isValid = false;

      if (inputValue !== "") {
        if ((test = await Location.fromLonLatStr(inputValue)) !== null) {
          handleSetValue(test);
          isValid = true;
        } else if (
          (test = await Location.fromZipCodeStr(inputValue)) !== null
        ) {
          handleSetValue(test);
          isValid = true;
        } else if (
          (test = await Location.fromDisplayNameStr(inputValue)) !== null
        ) {
          handleSetValue(test);
          isValid = true;
        }
      }

      if (isValid) {
        setSuggestInput("");
        onSearchComplete?.();
      }
    }
  };

  // set inputMatchState to null (loading), then debounce before setting inputMatchState to reflect inputValue
  useEffect(() => {
    setInputMatchState(null);
    clearTimeout(inputMatchStateTimer.current);
    inputMatchStateTimer.current = setTimeout(async () => {
      if ((await Location.fromLonLatStr(inputValue)) !== null) {
        setInputMatchState(true);
      } else if ((await Location.fromZipCodeStr(inputValue)) !== null) {
        setInputMatchState(true);
      } else if ((await Location.fromDisplayNameStr(inputValue)) !== null) {
        setInputMatchState(true);
      } else {
        setInputMatchState(false);
      }
    }, 250);
  }, [inputValue]);

  if (error) {
    console.warn("Error fetching place list:", error);
  }

  return (
    <div
      className={cl(styles.container, inputStyles.container)}
      style={{ flexGrow }}
      ref={outerDivRef}
    >
      <i className={loading() ? spinnerFa : fa} style={{ color: color }} />
      <input
        ref={inputRef}
        type="text"
        value={loading() ? "" : inputValue}
        disabled={loading()}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        placeholder={loading() ? "" : placeholder}
        spellCheck={false}
      />
      {isEnabled && inputValue == "" && (
        <button
          className={cl(
            styles.locateButton,
            "fa-solid",
            `fa-location-crosshairs`,
          )}
          style={{ color: color }}
          title="Locate me"
          disabled={loading()}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={handleOnLocateButtonClick}
        />
      )}
      {hasFocus && inputValue !== "" && (
        <i
          className={cl(
            styles.check,
            ...{
              null: ["fa-solid fa-spinner fa-spin", styles.loading],
              true: ["fa-regular fa-circle-check", styles.valid],
              false: ["fa-regular fa-circle-xmark", styles.invalid],
            }[inputMatchState],
          )}
        />
      )}
      {!loading() && showMap && places && (
        <MiniMap
          show={hasFocus}
          places={places}
          onPlaceClicked={handlePlaceClicked}
        />
      )}
    </div>
  );
};

export default LocationSelect;
