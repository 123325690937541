// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MF099nbN25YFOYlUYGiz {
  display: inline-flex;
  align-items: center;
  width: 100%;
  background-color: white;
  border: 1px solid grey;
  border-radius: 16px;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.TuOTFKmUvm9CYmcnEONq {
  position: absolute;
  background-color: yellow;
}

@media only screen and (max-width: 640px) {
  .MF099nbN25YFOYlUYGiz {
    flex-direction: column;
  }

  .MF099nbN25YFOYlUYGiz > * {
    width: 100%;
  }

  .MF099nbN25YFOYlUYGiz > .aHRJ4ijH17bwstQq2wko {
    height: 0;
    width: calc(100% - 1em);
    border-top: 1px solid lightgrey;
  }
}

@media only screen and (min-width: 641px) {
  .MF099nbN25YFOYlUYGiz > .aHRJ4ijH17bwstQq2wko {
    border-left: 1px solid lightgrey;
    height: 1.33em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/widgets/GlobalSearchBar/GlobalSearchBar.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB;;oCAEkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,SAAS;IACT,uBAAuB;IACvB,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,gCAAgC;IAChC,cAAc;EAChB;AACF","sourcesContent":[".container {\n  display: inline-flex;\n  align-items: center;\n  width: 100%;\n  background-color: white;\n  border: 1px solid grey;\n  border-radius: 16px;\n  box-shadow:\n    0 2px 5px 0 rgba(0, 0, 0, 0.16),\n    0 2px 10px 0 rgba(0, 0, 0, 0.12);\n}\n\n.suggest {\n  position: absolute;\n  background-color: yellow;\n}\n\n@media only screen and (max-width: 640px) {\n  .container {\n    flex-direction: column;\n  }\n\n  .container > * {\n    width: 100%;\n  }\n\n  .container > .divider {\n    height: 0;\n    width: calc(100% - 1em);\n    border-top: 1px solid lightgrey;\n  }\n}\n\n@media only screen and (min-width: 641px) {\n  .container > .divider {\n    border-left: 1px solid lightgrey;\n    height: 1.33em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MF099nbN25YFOYlUYGiz`,
	"suggest": `TuOTFKmUvm9CYmcnEONq`,
	"divider": `aHRJ4ijH17bwstQq2wko`
};
export default ___CSS_LOADER_EXPORT___;
