import { Location } from "./location.js";
import { Topic } from "./topic.js";

const encodeTopics = (topics) => {
  if (topics.length === 1) {
    return Topic.fromId(topics[0]).queryText;
  }
  return topics.join("_");
};

const decodeTopics = (topics) => {
  try {
    return topics.split("_").reduce((acc, t) => {
      let test;
      if ((test = Topic.fromIdStr(t)) !== null) {
        return acc.includes(test.id) ? acc : [...acc, test.id];
      } else if ((test = Topic.fromQueryIdStr(t)) !== null) {
        return acc.includes(test.id) ? acc : [...acc, test.id];
      } else {
        // ignore invalid topics
        return acc;
      }
    }, []);
  } catch {
    return [];
  }
};

const encodeLocation = (location) => {
  return location.queryText;
};

const decodeLocation = async (location) => {
  try {
    let test;
    if ((test = await Location.fromLonLatStr(location, "_")) !== null) {
      return test;
    } else if ((test = await Location.fromZipCodeStr(location)) !== null) {
      return test;
    } else if ((test = await Location.fromQueryIdStr(location)) !== null) {
      return test;
    }
    return null;
  } catch {
    return null;
  }
};

const encodeKeywords = (keywords) => {
  return encodeURIComponent(keywords);
};

const decodeKeywords = (keywords) => {
  try {
    if (keywords === undefined) {
      return "";
    }
    return decodeURIComponent(keywords);
  } catch {
    return "";
  }
};

export {
  encodeTopics,
  decodeTopics,
  encodeLocation,
  decodeLocation,
  encodeKeywords,
  decodeKeywords,
};
