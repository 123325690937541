import React, { Suspense, useEffect, useRef } from "react";
import "../../layout/Variables.css";
import styles from "./MentionsGraphDialog.css";
import { useDialog } from "../../context/DialogContext.js";
import LoadingPage from "../../pages/LoadingPage/LoadingPage.js";
import { useRouteHandlerContext } from "../../models/RouteHandler.js";
import { useApiSearchKeepPreviousData } from "../../hooks/api-hooks.js";

const LazyUsageGraph = React.lazy(() => import("../UsageChart.js"));

const MentionsGraphDialog = () => {
  const { openDialogs, closeDialog } = useDialog();
  const { location, keywords, startDate, endDate } = useRouteHandlerContext();
  const dialogRef = useRef(null);
  const isOpen = openDialogs.includes("graphbox");

  const searchParams = {
    topics: [],
    location,
    keywords,
    startDate,
    endDate,
  };

  const searchQuery = isOpen ? searchParams : null;
  const {
    data: results,
    error,
    isLoading,
  } = useApiSearchKeepPreviousData(searchQuery);

  useEffect(() => {
    if (!isOpen) {
      dialogRef.current.close();
      return;
    }

    if (dialogRef.current.showModal) {
      dialogRef.current.showModal();
    }
  }, [isOpen]);

  const renderUsageGraph = () => {
    if (
      isLoading ||
      !results ||
      !results.matched_meeting_counts ||
      location != null ||
      Object.keys(results.matched_meeting_counts).length <= 1
    ) {
      return null;
    }
    return (
      <div className={styles.chartContainer}>
        <Suspense fallback={<div>Loading Chart...</div>}>
          <LazyUsageGraph
            keywords={keywords}
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            numData={results.matched_meeting_counts}
            denData={results.meeting_counts}
          />
        </Suspense>
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <h2> Loading Graph Data, Please Wait</h2>
          <LoadingPage />
        </>
      );
    }

    if (error) {
      return <h2>Error loading data. Please try again.</h2>;
    }

    return (
      <>
        <h2>Percentage of recent meetings that mention the term {keywords}</h2>
        {renderUsageGraph()}
        <h2>Click a bar to search that month</h2>
      </>
    );
  };

  return (
    <dialog id="mentionsGraphDialog" className={styles.modal} ref={dialogRef}>
      <div className={styles.modalContentWrapper}>
        <div className={styles.modalCloseBtnWrapper}>
          <button
            id="ClosementionsGraphDialog"
            className={`${styles.modalCloseBtn} fa-solid fa-xmark`}
            title="Close"
            aria-label="close"
            onClick={() => closeDialog("graphbox")}
          ></button>
        </div>
        <div className={styles.modalContent}>{renderContent()}</div>
      </div>
    </dialog>
  );
};

export default MentionsGraphDialog;
