// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YUswRDjFOqwIFkLkGeCY,
.RvdLZjpb2DVJzcUo6miO {
  border: none;
  background: none;
  background-color: transparent;
  font-size: var(--step-1);
  color: var(--link-color);
  padding: var(--space-2xs);
  transition: var(--global-nav-transition);
  cursor: pointer;
  text-decoration: none;
}

.emSpFILb8hksBhOnIrx_ {
  border: none;
  background: none;
  background-color: transparent;
  font-size: var(--step-1);
  color: var(--link-color);
  transition: var(--global-nav-transition);
  cursor: pointer;
  text-decoration: none;
}

.s9Njn0JXWPzpqk4JcdxW {
  --input-border-color: transparent;
  --input-max-width: 90%;
  --input-border-color-hover: transparent;
  --input-border-color-focus: transparent;
  --input-color: var(--accent-alt-color);
  --input-font-size: var(--step-0);
}

@media screen and (max-width: 460px) {
  .emSpFILb8hksBhOnIrx_ {
    position: absolute;
    border: none;
    background: none;
    background-color: transparent;
    font-size: var(--step-1);
    color: var(--link-color);
    transition: var(--global-nav-transition);
    cursor: pointer;
    text-decoration: none;
    top: 0.7rem;
    right: 32%;
  }
}

@media screen and (max-width: 400px) {
  .s9Njn0JXWPzpqk4JcdxW {
    width: 7.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/NewLocationSelect.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,6BAA6B;EAC7B,wBAAwB;EACxB,wBAAwB;EACxB,yBAAyB;EACzB,wCAAwC;EACxC,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,6BAA6B;EAC7B,wBAAwB;EACxB,wBAAwB;EACxB,wCAAwC;EACxC,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,iCAAiC;EACjC,sBAAsB;EACtB,uCAAuC;EACvC,uCAAuC;EACvC,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,6BAA6B;IAC7B,wBAAwB;IACxB,wBAAwB;IACxB,wCAAwC;IACxC,eAAe;IACf,qBAAqB;IACrB,WAAW;IACX,UAAU;EACZ;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".locateButton,\n.mapButton {\n  border: none;\n  background: none;\n  background-color: transparent;\n  font-size: var(--step-1);\n  color: var(--link-color);\n  padding: var(--space-2xs);\n  transition: var(--global-nav-transition);\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.inputClearButton {\n  border: none;\n  background: none;\n  background-color: transparent;\n  font-size: var(--step-1);\n  color: var(--link-color);\n  transition: var(--global-nav-transition);\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.locationInput {\n  --input-border-color: transparent;\n  --input-max-width: 90%;\n  --input-border-color-hover: transparent;\n  --input-border-color-focus: transparent;\n  --input-color: var(--accent-alt-color);\n  --input-font-size: var(--step-0);\n}\n\n@media screen and (max-width: 460px) {\n  .inputClearButton {\n    position: absolute;\n    border: none;\n    background: none;\n    background-color: transparent;\n    font-size: var(--step-1);\n    color: var(--link-color);\n    transition: var(--global-nav-transition);\n    cursor: pointer;\n    text-decoration: none;\n    top: 0.7rem;\n    right: 32%;\n  }\n}\n\n@media screen and (max-width: 400px) {\n  .locationInput {\n    width: 7.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"locateButton": `YUswRDjFOqwIFkLkGeCY`,
	"mapButton": `RvdLZjpb2DVJzcUo6miO`,
	"inputClearButton": `emSpFILb8hksBhOnIrx_`,
	"locationInput": `s9Njn0JXWPzpqk4JcdxW`
};
export default ___CSS_LOADER_EXPORT___;
