// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q6Q8L_FQfn8dwvKJ9dQT {
  height: 32px;
  min-height: 32px;
  position: relative;
  flex-basis: 0;
  display: flex;
  align-items: center;
}

.Q6Q8L_FQfn8dwvKJ9dQT > i {
  margin-left: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.Q6Q8L_FQfn8dwvKJ9dQT > input {
  border: none;
  outline: none;
  background: none;
  flex-grow: 1;
  width: 0;
  height: 100%;
  margin: 0 8px;
  padding: 0;
}

.olUhEHTlI_pXIA6j7qJq {
  position: absolute;
  top: 100%;
  z-index: 100;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid grey;
  border-radius: 16px;
  background-color: white;
  margin-top: 0.5em;
  overflow: hidden;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  transition: 0.15s;
}

.olUhEHTlI_pXIA6j7qJq.dqaI1LKwkeoei3W8JLQ_ {
  visibility: visible;
  opacity: 1;
}

.NxAn9DPxVIo2vhVGTvlP {
  background: none;
  border: none;
  padding: 0;
  margin-right: 8px;
}

.NxAn9DPxVIo2vhVGTvlP:not(:hover) {
  color: grey !important;
}

.NxAn9DPxVIo2vhVGTvlP:disabled {
  color: grey !important;
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/Input.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,QAAQ;EACR,YAAY;EACZ,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,WAAW;EACX,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB;;oCAEkC;EAClC,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".container {\n  height: 32px;\n  min-height: 32px;\n  position: relative;\n  flex-basis: 0;\n  display: flex;\n  align-items: center;\n}\n\n.container > i {\n  margin-left: 8px;\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n\n.container > input {\n  border: none;\n  outline: none;\n  background: none;\n  flex-grow: 1;\n  width: 0;\n  height: 100%;\n  margin: 0 8px;\n  padding: 0;\n}\n\n.popupContainer {\n  position: absolute;\n  top: 100%;\n  z-index: 100;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  border: 1px solid grey;\n  border-radius: 16px;\n  background-color: white;\n  margin-top: 0.5em;\n  overflow: hidden;\n  box-shadow:\n    0 2px 5px 0 rgba(0, 0, 0, 0.16),\n    0 2px 10px 0 rgba(0, 0, 0, 0.12);\n  overflow-y: auto;\n  visibility: hidden;\n  opacity: 0;\n  transition: 0.15s;\n}\n\n.popupContainer.show {\n  visibility: visible;\n  opacity: 1;\n}\n\n.clearButton {\n  background: none;\n  border: none;\n  padding: 0;\n  margin-right: 8px;\n}\n\n.clearButton:not(:hover) {\n  color: grey !important;\n}\n\n.clearButton:disabled {\n  color: grey !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Q6Q8L_FQfn8dwvKJ9dQT`,
	"popupContainer": `olUhEHTlI_pXIA6j7qJq`,
	"show": `dqaI1LKwkeoei3W8JLQ_`,
	"clearButton": `NxAn9DPxVIo2vhVGTvlP`
};
export default ___CSS_LOADER_EXPORT___;
