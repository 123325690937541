import React, { useState, useRef, useCallback, useEffect } from "react";
import { fetchResults } from "../utilities/resultsHelper.js";
import NewInput from "./widgets/NewInput.js";
import NewLocationSelect from "./widgets/NewLocationSelect.js";
import NewSuggestionBox from "./widgets/NewSuggestionBox.js";
import LocateMeNav from "./widgets/LocateMeNav.js";
import { useMapLocation } from "../context/LocationContext.js";

import "./Variables.css";
import styles from "./NewSearchBar.css";

const NewSearchBar = ({
  topics: [propTopics, setPropTopics],
  location: [propLocation, setPropLocation],
  keywords: [propKeywords, setPropKeywords],
}) => {
  const [locationModal, setLocationModal] = useState(propLocation);
  const [islocationModal, setIsLocationModal] = useState(false);
  const [keywords, setKeywords] = useState(propKeywords);
  const [suggestInput, setSuggestInput] = useState("");
  const [currentKeywordInput, setCurrentKeywordInput] = useState("");
  const [currentLocationInput, setCurrentLocationInput] = useState("");
  const [isLocateMeNavVisible, setLocateMeNavVisible] = useState(false);
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const { mapLocation, setMapLocation } = useMapLocation();

  const handleSetLocation = (val) => {
    if (typeof val === "function") {
      val = val(location);
    }

    setMapLocation(val);

    if (currentKeywordInput) {
      setPropKeywords(currentKeywordInput);
    }

    if (islocationModal && !currentKeywordInput) {
      setLocationModal(val);
      setIsLocationModal(false);
    } else {
      setPropLocation(val);
    }
  };

  const handleSetKeywords = (val) => {
    if (typeof val === "function") {
      val = val(keywords);
    }

    event.preventDefault(); // TODO: Delete this should not be needed!

    setKeywords(val);

    const location = mapLocation || currentLocationInput;
    if (location) {
      setPropLocation(location);
    }
    setPropKeywords(val);
  };

  const fetchAndSetResults = async (topics, location, keywords) => {
    const controller = new AbortController();
    setLoading(true);

    try {
      const res = await fetchResults({
        topics,
        location,
        keywords,
        startDate: null,
        endDate: null,
        controller,
      });
      setResults(res);
    } catch (err) {
      console.error("Failed to fetch results:", err);
    } finally {
      setLoading(false);
    }
  };

  const toggleLocateMeNav = () => {
    setLocateMeNavVisible(!isLocateMeNavVisible);
  };

  const handleSeeMoreClick = () => {
    setPropLocation(mapLocation);
  };

  useEffect(() => {
    fetchAndSetResults(propTopics, locationModal, propKeywords);
  }, [locationModal, propKeywords]);

  useEffect(() => {
    setMapLocation(propLocation);
    setKeywords(propKeywords);
  }, [propLocation, propKeywords]);

  return (
    <>
      <form
        className={`${styles.searchBarContainer} ${isLocateMeNavVisible ? styles.noMargin : ""}`}
      >
        <div className={styles.inputContainer}>
          <label
            htmlFor="search-word"
            title="Search by Keyword"
            aria-label="Search by Word or Phrase or City"
          >
            <i
              className={"fa-solid fa-magnifying-glass fa-fw"}
              aria-hidden="true"
            ></i>
          </label>
          <NewInput
            loading={loading}
            setValue={handleSetKeywords}
            placeholder="Word or phrase or city"
            startWithFocus={true}
            setSuggestInput={setSuggestInput}
            setCurrentKeywordInput={setCurrentKeywordInput}
          />
        </div>
        <div className={styles.inputContainer}>
          <label
            htmlFor="search-place"
            title="Search by Place"
            aria-label="Search by ZIP or Postal Code"
          >
            <i
              className="fa-solid fa-location-dot fa-fw"
              aria-hidden="true"
            ></i>
          </label>
          <NewLocationSelect
            setValue={handleSetLocation}
            placeholder="ZIP or postal code"
            toggleLocateMeNav={toggleLocateMeNav}
            setIsLocationModal={setIsLocationModal}
            setCurrentLocationInput={setCurrentLocationInput}
          />
        </div>
      </form>
      <div className={styles.subNavWrapper}>
        {isLocateMeNavVisible && (
          <LocateMeNav
            currentKeywordInput={currentKeywordInput}
            results={results}
            onSeeMoreClick={handleSeeMoreClick}
            loading={loading}
          />
        )}
      </div>
      <div className={styles.suggestionBoxWrapper}>
        <NewSuggestionBox
          inputLetters={suggestInput}
          currentLocation={mapLocation || currentLocationInput}
          currentKeywords={currentKeywordInput}
          onClose={() => setSuggestInput("")}
        />
      </div>
    </>
  );
};

export default NewSearchBar;
