import React, { createContext, useState, useContext } from "react";

const LocationContext = createContext(null);

export const LocationProvider = ({ children }) => {
  const [mapLocation, setMapLocation] = useState(null);

  return (
    <LocationContext.Provider value={{ mapLocation, setMapLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useMapLocation = () => {
  const context = useContext(LocationContext);
  if (context === null) {
    throw Error("useMapLocation can only be used within LocationProvider");
  }
  return context;
};
