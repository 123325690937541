import React from "react";
import styles from "./TranscriptsVideoSection.css";
import RESULT_TYPES from "../../../../models/ResultTypes.js";
import VideoEmbed from "./components/VideoEmbed.js";

const TranscriptsVideoSection = ({
  embedVideoId,
  embedTimestamp,
  embedVideoTitle,
  embedVideoUrl,
  autoplayEmbed,
  keywords,
  summaryResults,
  startDate,
  endDate,
  isLoading,
  results,
  searchRequestFailed,
  resultType,
}) => {
  return (
    <section
      className={`${styles.transcriptVideoSection} ${resultType === RESULT_TYPES.KEYWORD ? styles.keywordPadding : ""}`}
    >
      <div className={styles.videoWrapper}>
        <VideoEmbed
          embedVideoId={embedVideoId}
          embedTimestamp={embedTimestamp}
          embedVideoTitle={embedVideoTitle}
          embedVideoUrl={embedVideoUrl}
          autoplayEmbed={autoplayEmbed}
          keywords={keywords}
          summaryResults={summaryResults}
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoading}
          results={results}
          searchRequestFailed={searchRequestFailed}
        />
      </div>
    </section>
  );
};

export default TranscriptsVideoSection;
