import React, { useState, useEffect, useRef } from "react";
import { fetchWithStatus, classList as cl } from "../../utilities/funcs.js";

import LoadingPage from "./LoadingPage.js";
import ErrorPage from "./ErrorPage.js";

import styles from "./AllPlacesPage.css";
import { LocationLink } from "../widgets/NewPillLink.js";
import Map, { getCityMarkerPopupHTML } from "../widgets/Map.js";
import { MdFmdGood } from "react-icons/md";
import StateSelector from "../widgets/forms/stateSelector.js";

const AllPlacesPage = () => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const stateRefs = useRef({});

  useEffect(() => {
    setTimeout(async () => {
      try {
        const [res, status] = await fetchWithStatus("get_place_list");
        if (status !== 200) {
          throw res;
        }
        setPlaces(res);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }, 350);
  }, []);

  const scrollToState = (stateName) => {
    stateRefs.current[stateName]?.scrollIntoView({ behavior: "smooth" });
  };

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  places.sort((a, b) => {
    let k1 = a.state_name + " " + a.name;
    let k2 = b.state_name + " " + b.name;
    return k1.localeCompare(k2);
  });

  const placesByState = places.reduce((acc, place) => {
    if (!acc[place.state_name]) {
      acc[place.state_name] = [];
    }
    acc[place.state_name].push(place);
    return acc;
  }, {});

  const states = Object.keys(placesByState);

  return (
    <div className={styles.allPlacesPage}>
      <section className={styles.aboutSection}>
        <div className={styles.topAbout}>
          <h1 className={styles.aboutSectionTitle}>
            <MdFmdGood
              size={32}
              color="var(--white-color)"
              style={{ marginRight: "5px" }}
            />
            Places
          </h1>
            <p>
		The map, and the list below, have links to all of the
		cities and towns whose meetings are currently indexed by
		CivicSearch.  Click on a place to go to the "home page" for
		the place, which gives you insight specific to that location
		as well as a starting point for searching its meetings.
          </p>
        </div>
        <div className={styles.map}>
          <div className={styles.mapWrapper}>
            <Map
              places={places}
              getMarkerPopupHTML={getCityMarkerPopupHTML}
              initialLongitude={-96.5}
              initialLatitude={38.5}
            />
          </div>
        </div>
      </section>
      <section className={styles.pillLinkSection}>
        <div className={styles.pillSearchBar}>
          <StateSelector states={states} onStateSelect={scrollToState} />
        </div>
        <div className={styles.stateGroupWrapper}>
          {Object.entries(placesByState).map(([stateName, statePlaces]) => (
            <div
              key={stateName}
              className={styles.stateGroup}
              ref={(el) => (stateRefs.current[stateName] = el)}
            >
              <h2 className={styles.stateHeader}>{stateName}</h2>
              <div className={styles.citiesContainer}>
                {statePlaces.map((place) => (
                  <LocationLink
                    iSmaller
                    key={place.query_id}
                    url={`/${place.query_id}`}
                  >
                    {place.name.replaceAll(", " + place.state_name, "")}
                  </LocationLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AllPlacesPage;
