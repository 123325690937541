import React from "react";
import { classList as cl } from "../../../utilities/funcs.js";
import styles from "./Input.css";

const Input = ({
  className,
  type = "search",
  label = "",
  placeholder = "",
  isRequired = false,
  name = "",
  id = "",
  value = "",
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  disabled = false,
  inputRef,
}) => {
  const inputIdAndName = `${type}-input`;

  return (
    <div className={cl(styles.inputGroup, className)}>
      <label
        htmlFor={inputIdAndName}
        className={cl({ [styles.required]: isRequired })}
      >
        {label}
      </label>
      <input
        ref={inputRef}
        required={isRequired}
        name={name}
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
    </div>
  );
};

export default Input;
