// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../imgs/blob-warm-duo-2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Urv61g4RzFQl8QzdW7dO {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(90vh - var(--global-nav-height));
  padding-top: var(--space-l-2xl);
  background-color: var(--secondary-color);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position-x: calc(-1 * var(--space-3xl));
  background-position-y: 10%;
  background-size: auto 200%;
  background-repeat: no-repeat;
}

.KiCaPkZArkRtnKW61sn_ {
  width: 90%;
}
`, "",{"version":3,"sources":["webpack://./src/views/pages/AlertSubscribePage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,iDAAiD;EACjD,+BAA+B;EAC/B,wCAAwC;EACxC,yDAAsD;EACtD,kDAAkD;EAClD,0BAA0B;EAC1B,0BAA0B;EAC1B,4BAA4B;AAC9B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".container {\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n  min-height: calc(90vh - var(--global-nav-height));\n  padding-top: var(--space-l-2xl);\n  background-color: var(--secondary-color);\n  background-image: url(../../imgs/blob-warm-duo-2x.png);\n  background-position-x: calc(-1 * var(--space-3xl));\n  background-position-y: 10%;\n  background-size: auto 200%;\n  background-repeat: no-repeat;\n}\n\n.signupFormContainer {\n  width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Urv61g4RzFQl8QzdW7dO`,
	"signupFormContainer": `KiCaPkZArkRtnKW61sn_`
};
export default ___CSS_LOADER_EXPORT___;
