import React from "react";
import styles from "./AlertSubscribePage.css";
import MailingListSignupHTML from "../widgets/MailingListSignupForm.js";

const AlertSubscribePage = ({ keywords }) => {
  return (
    <div className={styles.container}>
      <div className={styles.signupFormContainer}>
        {MailingListSignupHTML(keywords)}
      </div>
    </div>
  );
};

export default AlertSubscribePage;
