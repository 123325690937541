import React from "react";
import NewSearchBar from "./NewSearchBar.js";
import "./Variables.css";
import styles from "./Hero.css";
import { Link } from "react-router-dom";
import PrimaryButton from "./widgets/buttons/PrimaryButton.js";
import SecondaryButton from "./widgets/buttons/SecondaryButton.js";
import { KeywordLink, TopicLink } from "./widgets/NewPillLink.js";
import civicSearchLogoRgbPng from "../imgs/civicsearch-logo-rgb.png";

const Hero = ({
  topics: [topics, setTopics],
  location: [location, setLocation],
  keywords: [keywords, setKeywords],
  startWithTopicSelectFocus,
}) => {
  return (
    <main id="hero-home" className={styles.heroHome}>
      <header className={styles.hero}>
        <div className={`${styles.uContainer} ${styles.heroContent}`}>
          <div className={styles.logoTag}>
            <img
              className={styles.homeLogo}
              src={civicSearchLogoRgbPng}
              alt="Civic Search"
            />
            <h1>Local government revealed</h1>
          </div>
          <NewSearchBar
            topics={[topics, setTopics]}
            location={[location, setLocation]}
            keywords={[keywords, setKeywords]}
            startWithTopicSelectFocus={startWithTopicSelectFocus}
          />
          <div className={styles.leadWrapper}>
            <p className={styles.lead}>
              See what&rsquo;s being discussed in your community! Search
              millions of comments from local government meetings.
            </p>
          </div>
          <div className={styles.btnGroup}>
              <Link to="/topics">
		  <PrimaryButton size="sm">Browse Topics</PrimaryButton>
              </Link>
              <Link to="/cities">
		  <PrimaryButton size="sm">Browse Places</PrimaryButton>
              </Link>
              <Link to="/keywords">
		  <PrimaryButton size="sm">Browse Keywords</PrimaryButton>
              </Link>
          </div>
        </div>
      </header>
      <div className={styles.pageDownBtnWrapper}>
        <a
          href="#featured-topics"
          className={styles.pageDownBtn}
          role="button"
          title="Scroll Down"
          aria-label="scroll down"
        >
          <i className="fa-solid fa-circle-chevron-down" aria-hidden="true"></i>
        </a>
      </div>
    </main>
  );
};

export default Hero;
