// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lSGxMmvqEEz6W7khyRW7 {
  justify-content: space-between;
  color: var(--white-color);
  background-color: var(--secondary-alt-color-hsl);
  height: 3rem;
  align-items: center;
}

.Ir5kacYb1znLOERiY7qP {
  width: 100%;
}

.lSGxMmvqEEz6W7khyRW7 h2 {
  color: var(--white-color);
  font-size: var(--step--0);
  text-align: center;
  padding-left: 0.5rem;
}

.ut_N0ucCud1YYCwAc7g8 {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: 0;
}

@media screen and (max-width: 1024px) {
  .lSGxMmvqEEz6W7khyRW7 {
    display: flex;
  }
}

@media screen and (min-width: 1025px) {
  .lSGxMmvqEEz6W7khyRW7 {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/ResultsRelatedSection/components/AccordionHeader.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,gDAAgD;EAChD,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".accordionMenuBar {\n  justify-content: space-between;\n  color: var(--white-color);\n  background-color: var(--secondary-alt-color-hsl);\n  height: 3rem;\n  align-items: center;\n}\n\n.accordionHeaderWrapper {\n  width: 100%;\n}\n\n.accordionMenuBar h2 {\n  color: var(--white-color);\n  font-size: var(--step--0);\n  text-align: center;\n  padding-left: 0.5rem;\n}\n\n.accordionButton {\n  margin-right: 1rem;\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n  border: 0;\n  outline: 0;\n}\n\n@media screen and (max-width: 1024px) {\n  .accordionMenuBar {\n    display: flex;\n  }\n}\n\n@media screen and (min-width: 1025px) {\n  .accordionMenuBar {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordionMenuBar": `lSGxMmvqEEz6W7khyRW7`,
	"accordionHeaderWrapper": `Ir5kacYb1znLOERiY7qP`,
	"accordionButton": `ut_N0ucCud1YYCwAc7g8`
};
export default ___CSS_LOADER_EXPORT___;
