// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fAn4KEfrK6RpfOIKpqDP {
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid black;
  font-family: "Arial", sans-serif;
  font-size: 14px;
  color: black;
  line-height: 40px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  /* Adding drop shadow */
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6); /* Horizontal offset, Vertical offset, Blur radius, Color */
}

.sgrqcRTeJvHHkCsKfa6w:visited .fAn4KEfrK6RpfOIKpqDP {
  color: #666;
}

.sgrqcRTeJvHHkCsKfa6w {
  text-decoration: none;
}

.fuiXdGNrTdG1NLGeqRf6 {
  background-color: hsl(0, 100%, 97%);
}

.fuiXdGNrTdG1NLGeqRf6:hover {
  background-color: hsl(0, 100%, 85%);
}

.Pe3pbevg7g7Cq8dMrwjP {
  background-color: hsl(130, 100%, 97%);
}

.Pe3pbevg7g7Cq8dMrwjP:hover {
  background-color: hsl(130, 100%, 85%);
}

.mvMdNtklxsbI5XLZFhg5 {
  background-color: hsl(240, 100%, 97%);
}

.mvMdNtklxsbI5XLZFhg5:hover {
  background-color: hsl(240, 100%, 85%);
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/PillLink.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,uBAAuB;EACvB,gCAAgC;EAChC,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf;;wBAEsB;;EAEtB,uBAAuB;EACvB,0CAA0C,EAAE,2DAA2D;AACzG;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,qCAAqC;AACvC","sourcesContent":[".pillLinkText {\n  padding: 5px 10px;\n  margin: 5px;\n  border: 1px solid black;\n  font-family: \"Arial\", sans-serif;\n  font-size: 14px;\n  color: black;\n  line-height: 40px;\n  cursor: pointer;\n  transition:\n    background-color 0.3s ease,\n    box-shadow 0.3s ease;\n\n  /* Adding drop shadow */\n  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6); /* Horizontal offset, Vertical offset, Blur radius, Color */\n}\n\n.pillLink:visited .pillLinkText {\n  color: #666;\n}\n\n.pillLink {\n  text-decoration: none;\n}\n\n.location {\n  background-color: hsl(0, 100%, 97%);\n}\n\n.location:hover {\n  background-color: hsl(0, 100%, 85%);\n}\n\n.keywords {\n  background-color: hsl(130, 100%, 97%);\n}\n\n.keywords:hover {\n  background-color: hsl(130, 100%, 85%);\n}\n\n.topic {\n  background-color: hsl(240, 100%, 97%);\n}\n\n.topic:hover {\n  background-color: hsl(240, 100%, 85%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pillLinkText": `fAn4KEfrK6RpfOIKpqDP`,
	"pillLink": `sgrqcRTeJvHHkCsKfa6w`,
	"location": `fuiXdGNrTdG1NLGeqRf6`,
	"keywords": `Pe3pbevg7g7Cq8dMrwjP`,
	"topic": `mvMdNtklxsbI5XLZFhg5`
};
export default ___CSS_LOADER_EXPORT___;
