export const topicData = [
    {
        "id": -1,
        "keyword_stats": [
            "discipline or dismissal",
            "aquatic vegetation",
            "fixed wireless",
            "Municipal Equality Index",
            "below Pg",
            "scholarship winners",
            "fiber networks",
            "auxiliary dwelling unit",
            "luxury hotels",
            "unsuitable soils"
        ],
        "name": "Local governance",
        "query_id": "local-governance"
    },
    {
        "id": 70,
        "keyword_stats": [
            "comp plan",
            "master plans",
            "master planning",
            "strategic plans",
            "principal planner",
            "professional planner",
            "strategic planning session",
            "Associate planner",
            "registered professional planner",
            "video series"
        ],
        "name": "Planning process",
        "query_id": "planning-process"
    },
    {
        "id": 64,
        "keyword_stats": [
            "music n",
            "music Adjourned",
            "Summer Concerts",
            "free concert",
            "Tribute Band",
            "music Pap",
            "Saxophone",
            "free concerts",
            "bands playing",
            "band playing"
        ],
        "name": "Events and celebrations",
        "query_id": "events-and-celebrations"
    },
    {
        "id": 5,
        "keyword_stats": [
            "Mural",
            "local artists",
            "sculptures",
            "local artist",
            "muralist",
            "beautiful mural",
            "art scene",
            "emerging artists",
            "sculptor",
            "muralists"
        ],
        "name": "Public art",
        "query_id": "public-art"
    },
    {
        "id": 45,
        "keyword_stats": [
            "librarians",
            "library card",
            "library cards",
            "library branches",
            "audio books",
            "library patrons",
            "banning books",
            "ban books",
            "Audiobooks",
            "Whereas libraries"
        ],
        "name": "Public libraries",
        "query_id": "public-libraries"
    },
    {
        "id": 33,
        "keyword_stats": [
            "feral cats",
            "spayed or neutered",
            "spayed and neutered",
            "cat population",
            "spay or neuter",
            "aggressive dogs",
            "feral cat population",
            "breeding dogs",
            "spayed neutered",
            "injured animals"
        ],
        "name": "Animal issues",
        "query_id": "animal-issues"
    },
    {
        "id": 31,
        "keyword_stats": [
            "salads",
            "address food insecurity",
            "gluten free",
            "experiencing food insecurity",
            "lbs of food",
            "chain restaurants",
            "addressing food insecurity",
            "healthier food",
            "junk food",
            "nutritious meal"
        ],
        "name": "Food and dining",
        "query_id": "food-and-dining"
    },
    {
        "id": 28,
        "keyword_stats": [
            "cameras",
            "body cameras",
            "red light cameras",
            "camera systems",
            "body cams",
            "surveillance cameras",
            "video cameras",
            "body worn cameras",
            "flock cameras",
            "body cam footage"
        ],
        "name": "Surveillance cameras",
        "query_id": "surveillance-cameras"
    },
    {
        "id": 57,
        "keyword_stats": [
            "electric bikes",
            "E bikes",
            "protected bike",
            "avid cyclist",
            "cargo bike",
            "bike rider",
            "cargo bikes",
            "ride bicycles",
            "bike safely",
            "bike commuter"
        ],
        "name": "Bicycle transportation",
        "query_id": "bicycle-transportation"
    },
    {
        "id": 83,
        "keyword_stats": [
            "housing crisis",
            "housing shortage",
            "subsidized housing",
            "affordable housing crisis",
            "Attainable Housing",
            "affordable housing stock",
            "affordable housing developers",
            "naturally occurring affordable housing",
            "solve the housing crisis",
            "housing shortages"
        ],
        "name": "Affordable housing",
        "query_id": "affordable-housing"
    },
    {
        "id": 9,
        "keyword_stats": [
            "licenses",
            "business license",
            "business licenses",
            "Licensees",
            "license holder",
            "license holders",
            "entertainment license",
            "license premise",
            "license premises",
            "lior license"
        ],
        "name": "Business licensing",
        "query_id": "business-licensing"
    },
    {
        "id": 49,
        "keyword_stats": [
            "bidder",
            "biders",
            "low bid",
            "low bidder",
            "lowest bid",
            "lowest bidder",
            "bidders",
            "pre bid",
            "lowest responsible bidder",
            "bid specs"
        ],
        "name": "Bidding process",
        "query_id": "bidding-process"
    },
    {
        "id": 35,
        "keyword_stats": [
            "artificial turf",
            "natural grass",
            "natural turf",
            "grass seed",
            "artificial turf fields",
            "grass fields",
            "native grasses",
            "artificial turfs",
            "native grass",
            "artificial grass"
        ],
        "name": "Turf and grass",
        "query_id": "turf-and-grass"
    },
    {
        "id": 39,
        "keyword_stats": [
            "repaired",
            "piece of equipment",
            "deferred maintenance",
            "ongoing maintenance",
            "preventative maintenance",
            "preventive maintenance",
            "routine maintenance",
            "repairable",
            "minor repairs",
            "spare parts"
        ],
        "name": "Equipment maintenance/replacement",
        "query_id": "equipment-maintenance-replacement"
    },
    {
        "id": 27,
        "keyword_stats": [
            "experiencing homelessness",
            "homeless population",
            "homeless individuals",
            "shelter beds",
            "addressing homelessness",
            "chronically homeless",
            "unsheltered homelessness",
            "experienced homelessness",
            "chronic homelessness",
            "ending homelessness"
        ],
        "name": "Homelessness issues",
        "query_id": "homelessness-issues"
    },
    {
        "id": 107,
        "keyword_stats": [
            "floor is yours",
            "yield the floor",
            "licensed architect",
            "brick veneer",
            "brick facade",
            "registered architect",
            "brick masonry",
            "yielding the floor",
            "practicing architect",
            "pilasters"
        ],
        "name": "Building construction",
        "query_id": "building-construction"
    },
    {
        "id": 29,
        "keyword_stats": [
            "parking spaces",
            "parking spots",
            "parking stalls",
            "overnight parking",
            "parking minimums",
            "parking stall",
            "visitor parking",
            "structured parking",
            "bicycle parking spaces",
            "parking ratios"
        ],
        "name": "Parking issues",
        "query_id": "parking-issues"
    },
    {
        "id": 69,
        "keyword_stats": [
            "project",
            "projects",
            "lingered",
            "Toll Brothers",
            "River Walk",
            "major milestone",
            "Land Waterer",
            "depends on the complexity",
            "shelv",
            "thorough thorough"
        ],
        "name": "Development projects",
        "query_id": "development-projects"
    },
    {
        "id": 37,
        "keyword_stats": [
            "federal grants",
            "grant writer",
            "grant writing",
            "matching grant",
            "applying for grants",
            "grant writers",
            "competitive grants",
            "matching grants",
            "grant recipients",
            "grants totaling"
        ],
        "name": "Grant funding",
        "query_id": "grant-funding"
    },
    {
        "id": 60,
        "keyword_stats": [
            "left turn lane",
            "posted speed limit",
            "85th percentile",
            "posted speed",
            "85th percentile speed",
            "pedestrian crosswalk",
            "lowering the speed limit",
            "pedestrian crosswalks",
            "average speeds",
            "painted crosswalk"
        ],
        "name": "Traffic management",
        "query_id": "traffic-management"
    },
    {
        "id": 47,
        "keyword_stats": [
            "lumens",
            "dark sky compliant",
            "Led fixtures",
            "incandescent",
            "Led bulbs",
            "street lamps",
            "dimmer",
            "ambient light",
            "overhead lights",
            "decorative lights"
        ],
        "name": "Street lighting",
        "query_id": "street-lighting"
    },
    {
        "id": 74,
        "keyword_stats": [
            "property taxes",
            "sales taxes",
            "taxable value",
            "sales tax revenues",
            "levy limit",
            "levy rate",
            "sales tax collections",
            "grocery tax",
            "taxable values",
            "cent tax"
        ],
        "name": "Local taxation",
        "query_id": "local-taxation"
    },
    {
        "id": 78,
        "keyword_stats": [
            "units per acre",
            "per acre",
            "dwelling units per acre",
            "acre lots",
            "quarter acre",
            "80 acres",
            "total acreage",
            "acre minimum",
            "acre parcels",
            "quarter of an acre"
        ],
        "name": "Land use",
        "query_id": "land-use"
    },
    {
        "id": 79,
        "keyword_stats": [
            "single audit",
            "audit findings",
            "auditing standards",
            "external audit",
            "independent audit",
            "audit firm",
            "government auditing",
            "external auditors",
            "audit procedures",
            "external auditor"
        ],
        "name": "Financial audit",
        "query_id": "financial-audit"
    },
    {
        "id": 82,
        "keyword_stats": [
            "property owner",
            "private property",
            "private property owners",
            "title search",
            "adjoining property owners",
            "abandoned properties",
            "adverse possession",
            "privately owned properties",
            "joining property owners",
            "devalues"
        ],
        "name": "Property ownership",
        "query_id": "property-ownership"
    },
    {
        "id": 20,
        "keyword_stats": [
            "diagnosed with breast cancer",
            "women in the United States",
            "hospital admissions",
            "leading cause of cancer",
            "cardiologists",
            "oncologist",
            "nursing shortage",
            "diagnosed with prostate cancer",
            "diagnosed cancer",
            "clinic visits"
        ],
        "name": "Healthcare issues",
        "query_id": "healthcare-issues"
    },
    {
        "id": 105,
        "keyword_stats": [
            "maximum height",
            "front yard setback",
            "35 ft",
            "rear yard setback",
            "height limit",
            "ft tall",
            "45 ft",
            "yard setback",
            "sidey yard setback",
            "height restriction"
        ],
        "name": "Building setbacks",
        "query_id": "building-setbacks"
    },
    {
        "id": 46,
        "keyword_stats": [
            "appeal period",
            "courtrooms",
            "deny the appeal",
            "file an appeal",
            "defense attorney",
            "jury trial",
            "jury trials",
            "consult with an attorney",
            "juries",
            "appeal was filed"
        ],
        "name": "Legal matters",
        "query_id": "legal-matters"
    },
    {
        "id": 66,
        "keyword_stats": [
            "sworn officers",
            "oriented policing",
            "defunding the police",
            "Policing model",
            "proactive policing",
            "uniformed officers",
            "police recruits",
            "patrol sergeant",
            "lateral officers",
            "frontline officers"
        ],
        "name": "Police matters",
        "query_id": "police-matters"
    },
    {
        "id": 65,
        "keyword_stats": [
            "gun deaths",
            "ghost guns",
            "shooting incidents",
            "gun owners",
            "Americans are killed by gun violence",
            "gun homicides",
            "firearm related",
            "likely to die by gun homicide",
            "200 are shot and wounded",
            "gun ownership"
        ],
        "name": "Gun violence",
        "query_id": "gun-violence"
    },
    {
        "id": 52,
        "keyword_stats": [
            "daycare",
            "daycare center",
            "daycares",
            "child care providers",
            "daycare centers",
            "child abuse and neglect",
            "child care provider",
            "Adult Daycare",
            "safe nurturing",
            "daycare provider"
        ],
        "name": "Children, youth, childcare",
        "query_id": "children-youth-childcare"
    },
    {
        "id": 30,
        "keyword_stats": [
            "playground",
            "playgrounds",
            "playground equipment",
            "inclusive playground",
            "swing set",
            "pocket parks",
            "passive park",
            "accessible playground",
            "swing sets",
            "brand new playground"
        ],
        "name": "Parks and Recreation",
        "query_id": "parks-and-recreation"
    },
    {
        "id": 24,
        "keyword_stats": [
            "tennis players",
            "pickleball and tennis",
            "softball teams",
            "played tennis",
            "pickleball lines",
            "played pickleball",
            "pickle courts",
            "professional baseball",
            "played softball",
            "pickleball tournaments"
        ],
        "name": "Sports facilities",
        "query_id": "sports-facilities"
    },
    {
        "id": 14,
        "keyword_stats": [
            "serve alcohol",
            "sell alcohol",
            "serving alcohol",
            "Alcohol Licenses",
            "liquor licensing",
            "sell liquor",
            "sell beer",
            "alcoholic beverages license",
            "sell beer and wine",
            "selling liquor"
        ],
        "name": "Alcohol licensing",
        "query_id": "alcohol-licensing"
    },
    {
        "id": 121,
        "keyword_stats": [
            "million3",
            "500s",
            "170 North",
            "71 cents",
            "400s",
            "billion billion",
            "230 230",
            "250s",
            "900s",
            "650 650"
        ],
        "name": "Budget discussions",
        "query_id": "budget-discussions"
    },
    {
        "id": 25,
        "keyword_stats": [
            "dashboard",
            "dashboards",
            "data sets",
            "raw data",
            "data dashboard",
            "data portal",
            "facing dashboard",
            "datadriven decisions",
            "anecdotal data",
            "data dashboards"
        ],
        "name": "Data analysis",
        "query_id": "data-analysis"
    },
    {
        "id": 36,
        "keyword_stats": [
            "contract expires",
            "option to renew",
            "automatic renewal",
            "automatically renew",
            "terminate the contract",
            "threeyear contract",
            "automatically renews",
            "oneyear extension",
            "annual renewals",
            "automatic renewals"
        ],
        "name": "Contract negotiations",
        "query_id": "contract-negotiations"
    },
    {
        "id": 43,
        "keyword_stats": [
            "applicants",
            "behalf of the applicant",
            "interview process",
            "qualified applicants",
            "recruitment efforts",
            "recruitments",
            "interview panel",
            "appearing on behalf of the applicant",
            "round of interviews",
            "applicant or agent"
        ],
        "name": "Job applications",
        "query_id": "job-applications"
    },
    {
        "id": 59,
        "keyword_stats": [
            "sidewalks on both sides",
            "wider sidewalks",
            "layer of asphalt",
            "asphalt surface",
            "asphalts",
            "tons of asphalt",
            "linear feet of sidewalk",
            "sidewalk widths",
            "driveway accesses",
            "inches of asphalt"
        ],
        "name": "Sidewalk infrastructure",
        "query_id": "sidewalk-infrastructure"
    },
    {
        "id": 97,
        "keyword_stats": [
            "sewer line",
            "sewer system",
            "septic system",
            "septic tank",
            "sewer rates",
            "sewer pipe",
            "gravity sewer",
            "sewer collection",
            "sewer lining",
            "sanitary sewer mains"
        ],
        "name": "Sewer infrastructure",
        "query_id": "sewer-infrastructure"
    },
    {
        "id": 51,
        "keyword_stats": [
            "declining enrollment",
            "teacher salary",
            "teacher ratio",
            "kindergarten enrollment",
            "Advanced coursework",
            "literacy and math",
            "differentiated instruction",
            "dual enrollment courses",
            "enrollment decline",
            "chronically absent students"
        ],
        "name": "Education, schools, students",
        "query_id": "education-schools-students"
    },
    {
        "id": 54,
        "keyword_stats": [
            "signage",
            "temporary signs",
            "digital sign",
            "political signs",
            "digital signs",
            "finding signage",
            "directional signage",
            "electronic signs",
            "digital signage",
            "signages"
        ],
        "name": "Signage regulations",
        "query_id": "signage-regulations"
    },
    {
        "id": 77,
        "keyword_stats": [
            "rezoning",
            "zonings",
            "rezoning application",
            "zoning codes",
            "request to rezone",
            "conditional zoning",
            "zoning designations",
            "Current zoning",
            "zoning classifications",
            "requesting to rezone"
        ],
        "name": "Zoning regulations",
        "query_id": "zoning-regulations"
    },
    {
        "id": 56,
        "keyword_stats": [
            "downtown",
            "downtown core",
            "downtowns",
            "vibrant downtown",
            "downtown revitalization",
            "revitalize downtown",
            "downtown merchants",
            "revitalizing downtown",
            "downtown cores",
            "ghost town"
        ],
        "name": "Downtown development",
        "query_id": "downtown-development"
    },
    {
        "id": 23,
        "keyword_stats": [
            "Ambulance",
            "ambulances",
            "Ambulance Service",
            "ambulance transport",
            "ambulance billing",
            "sudden illness or injury",
            "emergency physicians",
            "ambulance provider",
            "ambulance transports",
            "ambulance crew"
        ],
        "name": "Emergency Medical Services",
        "query_id": "emergency-medical-services"
    },
    {
        "id": 95,
        "keyword_stats": [
            "invoices",
            "minimum wage",
            "salary range",
            "pay raises",
            "base salary",
            "salary ranges",
            "pay ranges",
            "average wage",
            "pay scales",
            "wage study"
        ],
        "name": "Salary and compensation",
        "query_id": "salary-and-compensation"
    },
    {
        "id": 18,
        "keyword_stats": [
            "opioids",
            "opioid settlement funds",
            "opioid funds",
            "opioid settlements",
            "opioid overdose",
            "opioid related",
            "opioid use disorder",
            "opioid overdoses",
            "methadone",
            "fatal overdoses"
        ],
        "name": "Drugs",
        "query_id": "drugs"
    },
    {
        "id": 73,
        "keyword_stats": [
            "eviction notice",
            "eviction notices",
            "5year lease",
            "regulate short term",
            "fiveyear lease",
            "landlord and the tenant",
            "eviction cases",
            "eviction filings",
            "10year lease",
            "evict a tenant"
        ],
        "name": "Tenant issues",
        "query_id": "tenant-issues"
    },
    {
        "id": 21,
        "keyword_stats": [
            "insurance company",
            "insurance companies",
            "self insured",
            "insurances",
            "insurance coverage",
            "homeowners insurance",
            "insurance claim",
            "insurance agent",
            "insurance carrier",
            "insurer"
        ],
        "name": "Insurance discussions",
        "query_id": "insurance-discussions"
    },
    {
        "id": 85,
        "keyword_stats": [
            "pray Father",
            "Pray Lord",
            "Jesus name we pray",
            "Lord I pray",
            "pray for peace",
            "pray for wisdom",
            "pray Dear",
            "us pray Heavenly",
            "pray in Jesus",
            "Father I Pray"
        ],
        "name": "Religion, prayer, church",
        "query_id": "religion-prayer-church"
    },
    {
        "id": 68,
        "keyword_stats": [
            "permits",
            "building permit",
            "conditional use permit",
            "special permit",
            "conditional use permits",
            "permits issued",
            "Use permit",
            "issuing permits",
            "permit holder",
            "permit holders"
        ],
        "name": "Permit processes",
        "query_id": "permit-processes"
    },
    {
        "id": 96,
        "keyword_stats": [
            "refinance",
            "outstanding debt",
            "debt limit",
            "debt servicing",
            "debt load",
            "debt ceiling",
            "debt repayment",
            "debt ratio",
            "debt issuances",
            "debt profile"
        ],
        "name": "Debt management",
        "query_id": "debt-management"
    },
    {
        "id": 16,
        "keyword_stats": [
            "cannabis products",
            "retail cannabis",
            "cannabis retailers",
            "cannabis sales",
            "cannabis licenses",
            "nicotine products",
            "smoke marijuana",
            "marijuana products",
            "medical marijuana dispensaries",
            "selling cannabis"
        ],
        "name": "Cannabis regulation",
        "query_id": "cannabis-regulation"
    },
    {
        "id": 48,
        "keyword_stats": [
            "color scheme",
            "color coding",
            "yellows",
            "paint color",
            "color blind",
            "wearing orange",
            "symbolizes the value of human",
            "Hunters wear orange",
            "darker purple",
            "classmates to commemorate"
        ],
        "name": "Color references",
        "query_id": "color-references"
    },
    {
        "id": 12,
        "keyword_stats": [
            "Bond issuance",
            "bond sale",
            "bond payments",
            "bond payment",
            "bond holders",
            "issuing bonds",
            "sell bonds",
            "bond package",
            "bond issuances",
            "issuance of bonds"
        ],
        "name": "Municipal bonds",
        "query_id": "municipal-bonds"
    },
    {
        "id": 34,
        "keyword_stats": [
            "paper wood",
            "enhance the economic vitality",
            "observed with the planting",
            "fires and countless",
            "renewable resource giving",
            "reduce the erosion",
            "moderate the temperature clean",
            "precious top soil",
            "air produce oxygen",
            "replant trees"
        ],
        "name": "Tree management",
        "query_id": "tree-management"
    },
    {
        "id": 89,
        "keyword_stats": [
            "recycling bin",
            "recycle bin",
            "waste is handled",
            "recycling cans",
            "bag of trash",
            "household trash",
            "composters",
            "recycling stream",
            "organic recycling",
            "diverted from the landfill"
        ],
        "name": "Waste management",
        "query_id": "waste-management"
    },
    {
        "id": 15,
        "keyword_stats": [
            "swim lessons",
            "swimming lessons",
            "Swim Team",
            "Outdoor Pool",
            "swimmers",
            "indoor pool",
            "learn to swim",
            "swim teams",
            "swim lesson",
            "swims"
        ],
        "name": "Swimming pools",
        "query_id": "swimming-pools"
    },
    {
        "id": 3,
        "keyword_stats": [
            "chain link fence",
            "foot fence",
            "privacy fence",
            "wood fence",
            "wooden fence",
            "solid fence",
            "vinyl fence",
            "8ft fence",
            "metal fence",
            "temporary fencing"
        ],
        "name": "Fencing issues",
        "query_id": "fencing-issues"
    },
    {
        "id": 50,
        "keyword_stats": [
            "certifications",
            "apprenticeship",
            "apprenticeship program",
            "apprenticeship programs",
            "apprenticeships",
            "lead certification",
            "train the trainer",
            "pre apprenticeship",
            "Deescalation Training",
            "registered apprenticeship"
        ],
        "name": "Training and certification",
        "query_id": "training-and-certification"
    },
    {
        "id": 2,
        "keyword_stats": [
            "airports",
            "airplanes",
            "Airlines",
            "general aviation",
            "larger aircraft",
            "busiest airport",
            "airline industry",
            "direct flights",
            "pilot shortage",
            "non aeronautical"
        ],
        "name": "Airport operations",
        "query_id": "airport-operations"
    },
    {
        "id": 17,
        "keyword_stats": [
            "Cyber security",
            "Stay Safe",
            "ransomware",
            "cyber attacks",
            "safety is paramount",
            "hackers",
            "cyber threats",
            "inherent risk",
            "cyber security threats",
            "Cyber Security Training"
        ],
        "name": "Safety and security",
        "query_id": "safety-and-security"
    },
    {
        "id": 7,
        "keyword_stats": [
            "languages",
            "bilingual",
            "interpreter",
            "different languages",
            "translation services",
            "translator",
            "multiple languages",
            "translators",
            "translations",
            "Spanish translation"
        ],
        "name": "Language matters",
        "query_id": "language-matters"
    },
    {
        "id": 40,
        "keyword_stats": [
            "nonprofits",
            "501c3",
            "profit organization",
            "501c3 nonprofit",
            "non for profit",
            "501 C3s",
            "501c",
            "nonprofit status",
            "nonprofit groups",
            "501c3 organization"
        ],
        "name": "Non-profit fundraising",
        "query_id": "non-profit-fundraising"
    },
    {
        "id": 22,
        "keyword_stats": [
            "illegal fireworks",
            "volunteer firefighters",
            "firefighter positions",
            "consumer fireworks",
            "career firefighters",
            "career firefighter",
            "volunteer fireman",
            "ban fireworks",
            "aerial fireworks",
            "female firefighters"
        ],
        "name": "Fire department",
        "query_id": "fire-department"
    },
    {
        "id": 58,
        "keyword_stats": [
            "pickup trucks",
            "box truck",
            "box trucks",
            "truck loads",
            "Amazon trucks",
            "lad truck",
            "trucking industry",
            "cab and chassis",
            "trailer trailer",
            "K9 vehicle"
        ],
        "name": "Vehicles and transportation",
        "query_id": "vehicles-and-transportation"
    },
    {
        "id": 110,
        "keyword_stats": [
            "gallons",
            "million gallons",
            "gallons a day",
            "gallons per day",
            "gallons per",
            "billion gallons",
            "th000 gallons",
            "per thousand gallons",
            "gallons a minute",
            "gallon per"
        ],
        "name": "Water management",
        "query_id": "water-management"
    },
    {
        "id": 8,
        "keyword_stats": [
            "anti black racism",
            "black folk",
            "Racists",
            "racist policies",
            "race theory",
            "black entrepreneurs",
            "racial inequity",
            "institutionalized racism",
            "rights of suffrage",
            "anti blackness"
        ],
        "name": "Racial issues",
        "query_id": "racial-issues"
    },
    {
        "id": 55,
        "keyword_stats": [
            "buses",
            "transit system",
            "bus drivers",
            "bus driver",
            "electric buses",
            "ride the bus",
            "riding the bus",
            "bus fleet",
            "bus riders",
            "bus operators"
        ],
        "name": "Public transit",
        "query_id": "public-transit"
    },
    {
        "id": 109,
        "keyword_stats": [
            "inches of rain",
            "storm water regulations",
            "Flood Maps",
            "100e storm",
            "flood risks",
            "25e storm",
            "flood proofing",
            "inch of rain",
            "100e storms",
            "storm water controls"
        ],
        "name": "Stormwater management",
        "query_id": "stormwater-management"
    },
    {
        "id": 91,
        "keyword_stats": [
            "Greenhouse Gas Inventory",
            "inches of snow",
            "heavy snow",
            "corporate emissions",
            "ines of snow",
            "Paris Agreement",
            "greenhouse gas inventories",
            "greenhouse gas emissions reduction",
            "climate hazards",
            "greenhouse gas emissions inventory"
        ],
        "name": "Climate sustainability",
        "query_id": "climate-sustainability"
    },
    {
        "id": 90,
        "keyword_stats": [
            "battery storage",
            "megawatts",
            "Battery Energy Storage",
            "fast chargers",
            "electric vehicle chargers",
            "Lithium batteries",
            "scale solar",
            "fast charger",
            "Solar Generation",
            "charging ports"
        ],
        "name": "Renewable energy",
        "query_id": "renewable-energy"
    },
    {
        "id": 94,
        "keyword_stats": [
            "kayaks",
            "kayak launch",
            "kayakers",
            "canoeing",
            "boat slips",
            "paddle board",
            "pound sand",
            "cubic yards of sand",
            "sand beach",
            "paddlers"
        ],
        "name": "Boating and beaches",
        "query_id": "boating-and-beaches"
    },
    {
        "id": 106,
        "keyword_stats": [
            "metal roof",
            "flat roofs",
            "roofer",
            "slate roof",
            "metal roofing",
            "roofing material",
            "roofing company",
            "shingle roof",
            "metal roofs",
            "pitch of the roof"
        ],
        "name": "Roof, stairs, siding",
        "query_id": "roof-stairs-siding"
    },
    {
        "id": 0,
        "keyword_stats": [
            "annexation",
            "annexed",
            "annexations",
            "annexing",
            "annexed into",
            "Annexation agreement",
            "voluntary annexation",
            "annexation petition",
            "annexation of approximately",
            "annexation agreements"
        ],
        "name": "Annexation process",
        "query_id": "annexation-process"
    }
];
export const issueKeywords = [
    "homelessness",
    "affordable housing",
    "public safety",
    "redistricting",
    "transparency",
    "climate change",
    "gun violence",
    "transportation",
    "sustainability",
    "mental health",
    "marijuana",
    "rent control",
    "diversity",
    "bargaining",
    "short term rentals",
    "conflict of interest",
    "negotiations",
    "education",
    "environmental justice",
    "contention",
    "development",
    "leaf blowers",
    "storm water",
    "infrastructure",
    "law enforcement",
    "sidewalks",
    "discrimination",
    "reparations",
    "workforce housing",
    "inclusion",
    "human trafficking",
    "racial equity",
    "water quality",
    "redevelopment",
    "code enforcement",
    "term limits",
    "fireworks",
    "health care",
    "environment",
    "litigation",
    "spot zoning",
    "short term rental",
    "food insecurity",
    "stormwater",
    "historic preservation",
    "annexation",
    "gun control",
    "election integrity",
    "ranked choice voting",
    "equity and inclusion",
    "housing affordability",
    "aesthetics",
    "multi family",
    "water conservation",
    "insurance",
    "recreation",
    "local control",
    "recycling",
    "public engagement",
    "hybrid meetings",
    "pickleball",
    "heritage trees",
    "climate action",
    "landscaping",
    "partisanship",
    "special use permits",
    "censorship",
    "traffic congestion",
    "plastic bags",
    "preservation",
    "engagement",
    "representation",
    "accountability",
    "septic systems",
    "inflation",
    "accessibility",
    "human rights",
    "fire safety",
    "preemption",
    "drop boxes",
    "land use",
    "cash flow",
    "addiction",
    "virtual meetings",
    "regulation",
    "hazard pay",
    "vaccination",
    "corruption",
    "impact fees",
    "nuisance animals",
    "special assessments",
    "child abuse",
    "panhandling",
    "equal opportunity",
    "cross contamination",
    "tenant protections",
    "stormwater management",
    "pedestrian safety",
    "broadband access",
    "bicycle and pedestrian safety",
    "overcrowding",
    "hazardous waste",
    "sea level rise",
    "mitigation"
];
export const numCities = 594;
export const featuredTopics = [
    {
        "date": "2024-02-09_2",
        "query_id": "black-history-month",
        "title": "black history month"
    },
    {
        "date": "2024-02-26",
        "query_id": "opioid-settlements",
        "title": "opioid settlements"
    },
    {
        "date": "2024-03-11",
        "query_id": "sidewalks",
        "title": "sidewalks"
    },
    {
        "date": "2024-03-25_2",
        "query_id": "absenteeism",
        "title": "absenteeism"
    },
    {
        "date": "all",
        "query_id": "all",
        "title": "all"
    },
    {
        "date": "yiu_on_test",
        "query_id": "chronic-absenteeism-districtview",
        "title": "chronic absenteeism districtview"
    },
    {
        "date": "2024-04-08",
        "query_id": "bus-rapid-transit",
        "title": "bus rapid transit"
    },
    {
        "date": "nsba",
        "query_id": "nsba",
        "title": "nsba"
    },
    {
        "date": "rezoning_test",
        "query_id": "rezoning",
        "title": "rezoning"
    },
    {
        "date": "2024-04-22",
        "query_id": "harm-reduction",
        "title": "harm reduction"
    },
    {
        "date": "2024-05-06",
        "query_id": "pickleball",
        "title": "pickleball"
    },
    {
        "date": "2024-05-20",
        "query_id": "canada",
        "title": "canada"
    },
    {
        "date": "2024-06-03",
        "query_id": "cycling",
        "title": "cycling"
    },
    {
        "date": "2024-06-17",
        "query_id": "climate-change",
        "title": "climate change"
    },
    {
        "date": "2024-07-15",
        "query_id": "immigration",
        "title": "immigration"
    },
    {
        "date": "ic2s2",
        "query_id": "fentanyl-test-strips",
        "title": "fentanyl test strips"
    },
    {
        "date": "2024-07-29",
        "query_id": "short-term-rentals",
        "title": "short-term-rentals"
    },
    {
        "date": "2024-08-12",
        "query_id": "homelessness",
        "title": "homelessness"
    },
    {
        "date": "2024-08-26",
        "query_id": "accessory-dwelling-units",
        "title": "accessory dwelling units"
    },
    {
        "date": "2024-09-09",
        "query_id": "rent-control",
        "title": "rent control"
    },
    {
        "date": "2024-09-23",
        "query_id": "participatory-budgeting",
        "title": "participatory budgeting"
    },
    {
        "date": "2024-09-22",
        "query_id": "participatory-budget",
        "title": "participatory budget"
    },
    {
        "date": "2024-10-07",
        "query_id": "ranked-choice-voting",
        "title": "ranked choice voting"
    },
    {
        "date": "2024-10-17",
        "query_id": "homelessness",
        "title": "homelessness"
    },
    {
        "date": "2024-10-21",
        "query_id": "voter-turnout",
        "title": "voter turnout"
    },
    {
        "date": "research",
        "query_id": "research",
        "title": "research"
    },
    {
        "date": "2025-01-13",
        "query_id": "fluoride",
        "title": "fluoride"
    },
    {
        "date": "2025-02-05",
        "query_id": "black-history-month-2025",
        "title": "black history month 2025"
    },
    {
        "date": "2025-02-10",
        "query_id": "civicsearch-website-v2",
        "title": "civicsearch-website-v2"
    }
];