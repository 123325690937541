import React from "react";
import styles from "./RelatedItem.css";
import Accordion from "../../../../../widgets/Accordion/Accordion.js";

const RelatedItem = ({
  icon,
  iconColor,
  title,
  content,
  entityName,
  highlightColor,
  maxHeight = 250,
  resultType,
}) => {
  const renderTitle = () => {
    if (title.includes("{entityName}")) {
      const [firstPart, secondPart] = title.split("{entityName}");
      return (
        <>
          {firstPart}
          <span style={{ color: `var(${highlightColor})` }}>{entityName}</span>
          {secondPart}
        </>
      );
    }
    return (
      <>
        {title}{" "}
        <span style={{ color: `var(${highlightColor})` }}>{entityName}</span>
      </>
    );
  };

  return (
    <div className={styles.relatedInfoBox}>
      <h2>
        <div>
          {React.cloneElement(icon, { size: 24, color: `var(${iconColor})` })}
        </div>
        {renderTitle()}
      </h2>
      <Accordion
        maxHeight={maxHeight}
        className={styles.customAccordion}
        expandButtonClassName={styles.customAccordionExpand}
        resultType={resultType}
      >
        <div className={styles.pillLinkList}>{content}</div>
      </Accordion>
    </div>
  );
};

export default RelatedItem;
