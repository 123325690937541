import React, { useState, useEffect, useRef } from "react";
import { Location } from "../../models/location.js";
import styles from "./NewLocationSelect.css";
import { useDialog } from "../../context/DialogContext.js";
import { handleLocation } from "../../utilities/locationUtils.js";
import Input from "./forms/Input.js";

const NewLocationSelect = ({
  setValue,
  placeholder,
  toggleLocateMeNav,
  setIsLocationModal,
  setCurrentLocationInput,
}) => {
  const rollbackInputValue = useRef();
  const inputRef = useRef();
  const { openDialog } = useDialog();

  const [inputValue, setInputValue] = useState("");
  const [fetchingUserLonLat, setFetchingUserLonLat] = useState(false);
  const [locationModalPending, setLocationModalPending] = useState(false);

  const loading = () => fetchingUserLonLat;

  const handleMapboxClick = () => openDialog("mapbox");

  const handleSetValue = (newValue) => {
    const text = newValue === null ? "" : newValue.displayText;
    setValue(newValue);
    setInputValue(text);
    rollbackInputValue.current = text;
  };

  const handleOnLocateButtonClick = async () => {
    setIsLocationModal(true);
    setLocationModalPending(true);
  };

  useEffect(() => {
    if (locationModalPending) {
      (async () => {
        await handleLocation(
          setFetchingUserLonLat,
          handleSetValue,
          toggleLocateMeNav,
        );
        setLocationModalPending(false);
      })();
    }
  }, [locationModalPending]);

  const getLocationValue = async (val) => {
    let locationValue = await Location.fromLonLatStr(val);
    if (locationValue) return locationValue;

    locationValue = await Location.fromZipCodeStr(val);
    if (locationValue) return locationValue;

    locationValue = await Location.fromDisplayNameStr(val);
    return locationValue;
  };

  const handleOnChange = async (event) => {
    const val = event.target.value;
    setInputValue(val);

    if (val.length === 5 || val.length === 9) {
      const locationValue = await getLocationValue(val);
      setCurrentLocationInput(locationValue || val);
    } else {
      setCurrentLocationInput(null);
    }
  };

  const handleOnKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue === "") {
        handleSetValue(null);
      } else {
        const value = await getLocationValue(inputValue);
        handleSetValue(value);
      }
    }
  };

  const handleClearInput = () => {
    handleSetValue(null);
    setCurrentLocationInput("");
  };

  return (
    <>
      <Input
        className={styles.locationInput}
        inputRef={inputRef}
        type="text"
        value={loading() ? "" : inputValue}
        disabled={loading()}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        placeholder={loading() ? "" : placeholder}
      />
      {inputValue !== "" && (
        <button
          className={`${styles.inputClearButton} fa-solid fa-xmark`}
          title="Clear Search"
          aria-label="Clear Search"
          onClick={handleClearInput}
        ></button>
      )}
      <a
        href="#/"
        id="locate-btn"
        className={`${styles.locateButton} fa-solid fa-location-crosshairs`}
        title="Locate Me"
        aria-label="Locate Me"
        role="button"
        onMouseDown={(e) => e.preventDefault()}
        onClick={handleOnLocateButtonClick}
      ></a>
      <a
        href="#/"
        id="openMapboxDialogButton"
        className={`${styles.mapButton} fa-solid fa-map-location-dot`}
        title="View Map"
        aria-label="View map"
        role="button"
        onClick={handleMapboxClick}
      ></a>
    </>
  );
};

export default NewLocationSelect;
