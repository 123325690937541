import React, { useEffect, useState, useRef } from "react";
import { classList as cl } from "../../utilities/funcs.js";
import styles from "./Accordion.css";
import RESULT_TYPES from "../../models/ResultTypes.js";

const Accordion = ({
  children,
  maxHeight = 200,
  className: myClassName = null,
  expandButtonClassName,
  expanded = false,
  onExpandChange,
  resultType,
  requireMeasurement = false,
}) => {
  const childrenRef = useRef();
  const [expandedSelfManaged, setExpanded] = useState(false);
  const [childrenHeight, setChildrenHeight] = useState(0);
  const [isHeightMeasured, setIsHeightMeasured] = useState(!requireMeasurement);

  // true if the parent tells us to be expanded (when onexpandchange!=null),
  // or if our internal state tracking
  // tells us to be expanded.
  const anyExpanded = expandedSelfManaged || expanded;

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (childrenRef?.current) {
        const currentHeight = childrenRef.current.scrollHeight;
        setChildrenHeight(currentHeight);
        setIsHeightMeasured(true);
      }
    });

    if (childrenRef.current) {
      observer.observe(childrenRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const expandable = childrenHeight > maxHeight;

  let containerHeight;
  if (anyExpanded) {
    containerHeight = `${childrenHeight}px`;
  } else if (expandable) {
    containerHeight = `${maxHeight}px`;
  } else {
    containerHeight = "auto";
  }

  let maxChildPreviewHeight = 0;
  if (resultType) {
    if (resultType === RESULT_TYPES.KEYWORD) {
      maxChildPreviewHeight = "14rem";
    } else if (resultType === RESULT_TYPES.PLACE) {
      maxChildPreviewHeight = "19.5rem";
    } else if (resultType === RESULT_TYPES.TOPIC) {
      maxChildPreviewHeight = "14rem";
    }
  }

  const toggleExpanded = () => {
    const newExpanded = !anyExpanded;
    if (onExpandChange) {
      onExpandChange(newExpanded);
    } else {
      setExpanded(newExpanded);
    }
  };

  if (!isHeightMeasured) {
    return (
      <div
        className={cl(styles.container, myClassName)}
        style={{ visibility: "hidden" }}
      >
        <div ref={childrenRef}>{children}</div>
      </div>
    );
  }

  return (
    <div
      className={cl(
        styles.container,
        styles.expandable,
        anyExpanded ? styles.expanded : null,
        myClassName,
      )}
      style={{
        height: containerHeight,
        overflow: "hidden",
        transition: "height 0.3s ease-out",
      }}
    >
      <div
        className={`${styles.childWrapper}  ${!anyExpanded ? styles.childWrapperCollasped : ""}`}
        ref={childrenRef}
        style={{
          maxHeight:
            !anyExpanded && maxChildPreviewHeight !== 0
              ? maxChildPreviewHeight
              : "none",
          transition: "max-height 0.3s ease-out",
        }}
      >
        {children}
      </div>
      {expandable && (
        <button
          className={cl(styles.expandButton, expandButtonClassName)}
          onClick={toggleExpanded}
          aria-expanded={anyExpanded}
          aria-label={anyExpanded ? "Collapse" : "Expand"}
        >
          <i className={`fa-solid fa-chevron-${"down"}`} />
        </button>
      )}
    </div>
  );
};

export default Accordion;
