import React from "react";

import styles from "./LoadingPage.css";

const LoadingPage = () => {
  return (
    <div className={styles.container}>
      <i className="fa-solid fa-spinner fa-spin" />
    </div>
  );
};

export default LoadingPage;
