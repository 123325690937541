import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { Link } from "react-router-dom";
import { parse } from "../../models/parser.js";
import { fetchWithStatus, toQueryString } from "../../utilities/funcs.js";
import { Location } from "../../models/location.js";
import { Topic } from "../../models/topic.js";
import {
  fetchResults,
  getResultToEmbed,
} from "../../utilities/resultsHelper.js";

import Carousel from "./../widgets/Carousel.js";
import LoadingPage from "./../pages/LoadingPage.js";
import { PointsOfInterestMap } from "./../widgets/Map.js";
import ErrorPage from "./../pages/ErrorPage.js";
import GranicusEmbed from "./../widgets/GranicusEmbed.js";
import YoutubeEmbed from "./../widgets/YoutubeEmbed.js";

import locationSearchImg from "../../imgs/undraw_location_search.svg";
import starsImg from "../../imgs/undraw_stars.svg";

import styles from "./ResultsKeywordPage.css";
import Accordion from "./../widgets/Accordion.js";

import ResultsHeader from "../ResultsHeader.js";
import ResultsRelatedSection from "../ResultsRelatedSection.js";
import ResultsSummarySection from "../ResultsSummarySection.js";
import TranscriptsVideoSection from "../TranscriptsVideoSection.js";
import {
  LocationLink,
  TopicLink,
  KeywordLink,
} from "./../widgets/NewPillLink.js";

import { useMediaQuery } from "usehooks-ts";
import RESULT_TYPES from "../../models/ResultTypes.js";

const ResultsKeywordPage = ({
  topics,
  location: [location, setLocation],
  keywords,
  startDate,
  endDate,
}) => {
  const searchController = useRef();

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [searchRequestFailed, setSearchRequestFailed] = useState(false);

  const [videoCaption, setVideoCaption] = useState(null);
  const [topicTitle, setTopicTitle] = useState(null);
  const [embedVideoId, setEmbedVideoId] = useState(null);
  const [embedVideoUrl, setEmbedVideoUrl] = useState(null);
  const [embedTimestamp, setEmbedTimestamp] = useState(null);
  const [autoplayEmbed, setAutoplayEmbed] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");

  const usingTopics = () => topics.length > 0;
  const usingLocation = () => location !== null;
  const usingKeywords = () => keywords !== "";

  const smallScreen = useMediaQuery("(max-width: 1024px)");

  const updateResults = (res) => {
    setResults(res);
    setSearchRequestFailed(false);
    const resultToEmbed = getResultToEmbed(res);
    if (resultToEmbed) {
      let timestamp = resultToEmbed["snippets"][0]["timestamp"];
      if (resultToEmbed["vid_url"]) setEmbedVideoUrl(resultToEmbed["vid_url"]);
      setEmbedVideoId(resultToEmbed["vid_id"]);
      setVideoTitle(resultToEmbed["title"]);
      setEmbedTimestamp(timestamp);
    } else {
      setEmbedVideoId(null);
      setEmbedTimestamp(null);
    }
    setAutoplayEmbed(false);
  };

  const leftSectionRef = useRef(null);
  const videoSectionRef = useRef(null);

  const handleMoreClick = (timestamp) => {
    if (window.innerWidth > 1024) {
      scrollToVideoSection();
    }
  };

  const scrollToVideoSection = () => {
    if (leftSectionRef.current && videoSectionRef.current) {
      const leftSectionTop = leftSectionRef.current.offsetTop;
      const videoSectionTop = videoSectionRef.current.offsetTop;
      leftSectionRef.current.scrollTo({
        top: videoSectionTop - leftSectionTop,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (topics.length > 0) {
        const topic_query_id = Topic.fromId(
          topics[topics.length - 1],
        ).queryText;
        const location_query_id = location ? location.queryText : "";
        const params = { topic_query_id, location_query_id };
        const [data, status] = await fetchWithStatus(
          `get_summary?${toQueryString(params)}`,
        );
        setSummaryData(data);
        setVideoCaption(data.topic_name ? data.topic_name.toLowerCase() : "");
        if (data.topic_name) {
          setTopicTitle(data.topic_name); // Set topicTitle state in parent
        }
        setLoading(false);
        if (status !== 200) {
          throw summaryData;
        }
      }
    })();
  }, [topics, location]);

  useLayoutEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const params = {
          topics: usingTopics() ? topics.join(",") : undefined,
          lonlat: usingLocation()
            ? location.lon + "," + location.lat
            : undefined,
          keywords: usingKeywords() ? keywords : undefined,
          search_radius:
            location?.type === "named" ? 0 : process.env.SEARCH_RADIUS,
          start_date: startDate ? startDate : undefined,
          end_date: endDate ? endDate : undefined,
        };
        searchController?.current?.abort();
        searchController.current = new AbortController();
        const res = await fetchResults({
          topics,
          location,
          keywords,
          startDate,
          endDate,
          controller: searchController.current,
        });

        updateResults(res);
      } catch (err) {
        if (err?.name === "AbortError") {
          return;
        }
        setSearchRequestFailed(true);
        setEmbedVideoId(null);
        setEmbedTimestamp(null);
        setAutoplayEmbed(false);
      } finally {
        setLoading(false);
      }
    }, 200);
  }, [topics, location, keywords]);

  const renderResultsTitle = () => {
    if (loading) {
      return "";
    } else if (results) {
      let ss = results.location_counts.length == 1 ? "" : "s";
      let when = startDate || endDate ? "" : "recent";
      return `${results.num_snippets} matching remarks from ${results.num_results} ${when} meeting transcripts in ${results.location_counts.length} town${ss}:`;
    }
  };

  const renderVideoEmbed = () => {
    const showVideo =
      !loading && results && !searchRequestFailed && embedVideoId;
    const useKeywords = keywords != "";
    let formattedDate = "";
    if (startDate) {
      const date = new Date(startDate);
      formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "long",
        year: "numeric",
      }).format(date);
    } else if (endDate) {
      const date = new Date(endDate);
      formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "long",
        year: "numeric",
      }).format(date);
    }
    const showDate = formattedDate != "";
    return showVideo ? (
	<center className={styles.pictureInPicture}>
	  <>
          <p className={styles.vCaption}>
            {videoTitle ? videoTitle : "Loading..."}
          </p>
          {embedVideoUrl ? (
            <GranicusEmbed
              baseUrl={embedVideoUrl}
              start={embedTimestamp ?? 0}
              autoplay={autoplayEmbed}
            />
          ) : (
            <YoutubeEmbed
              embedId={embedVideoId}
              start={embedTimestamp ?? 0}
              autoplay={autoplayEmbed}
            />
          )}
        </>
      </center>
    ) : null;
  };

  const renderResults = () => {
    if (loading) {
      return <LoadingPage />;
    } else if (!results || searchRequestFailed) {
      return <ErrorPage />;
    } else if (results.num_places === 0) {
      return (
        <ErrorPage
          image={locationSearchImg}
          header={"Sorry, the search failed\u2026"}
          message="Our dataset doesn't cover any nearby places."
        />
      );
    } else if (results.num_results === 0) {
      return (
        <ErrorPage
          image={starsImg}
          header={null}
          message={`No results found in ${results.num_places} nearby ${results.num_places === 1 ? "place" : "places"}.`}
          searchKw={location != null ? keywords : null}
        />
      );
    }
    return (
      <section className={styles.transciptSection}>
        {results.results.map(
          (
            {
              title,
              vid_id,
              date,
              location: place,
              distance,
              snippets,
              vid_url,
            },
            i,
          ) => {
            return (
              <SearchResultItem
                key={i}
                title={title}
                date={date}
                place={place}
                distance={distance}
                onVideoLinkClick={(timestamp) => {
                  setEmbedVideoId(vid_id);
                  setEmbedTimestamp(timestamp);
                  setAutoplayEmbed(true);
                  setVideoTitle(title);
                  handleMoreClick(timestamp);
                  if (vid_url) setEmbedVideoUrl(vid_url);
                }}
                snippets={snippets}
                showDistance={usingLocation() && location?.type !== "named"}
                setLocation={setLocation}
              />
            );
          },
        )}
      </section>
    );
  };

  const renderLocationsNearInput = () => {
    if (loading) {
      return <LoadingPage />;
    }
    if (!results || !results.places || results.places.length == 0) {
      return (
        <div className={styles.errorPage}>
          <ErrorPage
            image={locationSearchImg}
            header={"Sorry, the search failed\u2026"}
            message="Our dataset doesn't cover any nearby places."
          />
        </div>
      );
    }
    return (
      <>
        <div className={styles.relatedInfoBox}>
          <div className={styles.relatedInfoBoxTop}>
            <h2>Closest Places in the Database</h2>
            <br />
            <br />{" "}
            <div className={styles.pillLinkBox}>
              {results.places.slice(0, 10).map((place, i) => {
                let location_query_id = place.query_id;
                let location_display = place.display_name;

                return (
                  <LocationLink key={i} url={`/${location_query_id}`}>
                    {location_display}
                  </LocationLink>
                );
              })}
            </div>
          </div>
          <div className={styles.relatedInfoBoxBottom}>
            <h2>Nearby Places Mentioned in Recent Meetings</h2>
            <br />
            <br />
            <div className={styles.relatedInfoMap}>
              <PointsOfInterestMap
                cityQueryId={location.queryText}
                pointsOfInterest={results.points_of_interest}
                centroidLongitude={location.lon}
                centroidLatitude={location.lat}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  const [summaryData, setSummaryData] = useState({ summary: "" });

  const getSummaryData = () => {
    if (results) {
      if (results.summary !== "") {
        return results.summary.replaceAll("\n", "<br/>");
      }
    }
    if (summaryData.summary === "") {
      return "";
    } else {
      return summaryData.summary.replaceAll("\n", "<br/>");
    }
  };

  if (topics.length > 0 && location == null && !keywords) {
    if (!smallScreen) {
      return (
        // main topic section here

        <div className={styles.resultsPage}>
          <section className={styles.leftSection}>
            <ResultsHeader
              headerTitle={topicTitle}
              topics={topics}
              keywords={keywords}
              place={location}
              headerType={RESULT_TYPES.TOPIC}
            />
            <ResultsRelatedSection
              entityName={topicTitle}
              location={location}
              topics={topics}
              summaryData={summaryData}
              resultType={RESULT_TYPES.TOPIC}
            />
          </section>
          <section className={styles.resultsSection}>
            <ResultsSummarySection
              getSummaryData={getSummaryData}
              headerTitle={topicTitle}
              resultType={RESULT_TYPES.TOPIC}
            />
            <div className={styles.stickyVideoWrapper}>
              <TranscriptsVideoSection renderVideoEmbed={renderVideoEmbed} />
            </div>
            <div className={styles.scrollableContent}>
              <div className={styles.transcriptsHeader}>
                Relevant remarks from meeting transcripts
              </div>
              <ul className={styles.container}>{renderResults()}</ul>
            </div>
          </section>
        </div>
      );
    } else if (smallScreen) {
      return (
        <div className={styles.resultsPage}>
          <section className={styles.resultsSection}>
            <ResultsHeader
              headerTitle={topicTitle}
              topics={topics}
              keywords={keywords}
              place={location}
              headerType={RESULT_TYPES.TOPIC}
            />
            <ResultsRelatedSection
              entityName={topicTitle}
              location={location}
              topics={topics}
              summaryData={summaryData}
              resultType={RESULT_TYPES.TOPIC}
            />
            <ResultsSummarySection
              getSummaryData={getSummaryData}
              headerTitle={topicTitle}
              resultType={RESULT_TYPES.TOPIC}
            />
            <div className={styles.stickyVideoWrapper}>
              <TranscriptsVideoSection renderVideoEmbed={renderVideoEmbed} />
            </div>
            <div className={styles.scrollableContent}>
              <div className={styles.transcriptsHeader}>
                Relevant remarks from meeting transcripts
              </div>
              <ul className={styles.container}>{renderResults()}</ul>
            </div>
          </section>
        </div>
      );
    }
  } else if (topics.length == 0 && !keywords && location != null) {
    /* Location only */
    return <div>{renderLocationsNearInput()}</div>;
  } else {
    let header_location = "";
    let subscribeUrl = "";

    if (location && location.type === "named") {
      header_location = (
        <>
          {"mentions in "}
          <LocationLink url={`/${location.queryText}`}>
            {location.displayText}
          </LocationLink>
        </>
      );
    } else if (location) {
      header_location = " mentions near " + location.displayText;
    } else {
      header_location = " mentions in all local government meetings";
      subscribeUrl = "/subscribe" + (keywords ? "?k=" + keywords : "");
    }
    if (startDate || endDate) {
      header_location = " mentions";
      if (startDate) {
        header_location += " from " + startDate;
      }
      if (endDate) {
        header_location += " up to " + endDate;
      }
    }

    let header_location_reset_link = "";
    if (location && keywords) {
      header_location_reset_link = (
        <>
          <span className={styles.searchAllLocations}>
            {"("}
            <Link reloadDocument={true} to={`/search?k=${keywords}`}>
              {"Search all locations"}
            </Link>
            {")"}
          </span>
        </>
      );
    }
    // Note:  You currently can't do both a location restrict and a date restrict,
    // so this header_location_reset_link logic is separable
    if (startDate || endDate) {
      header_location_reset_link = (
        <>
          <span className={styles.searchAllLocations}>
            {"("}
            <Link reloadDocument={true} to={`/search?k=${keywords}`}>
              {"Search all dates"}
            </Link>
            {")"}
          </span>
        </>
      );
    }

    const capitalizeTitle = (title) => {
      return title.replace(/\b\w/g, (char) => char.toUpperCase());
    };
    // main keyword section here

    const determineHeaderDetails = (keywords, topics) => {
      if (keywords && keywords.id) {
        let this_topic = Topic.fromId(keywords.id);
        return {
          headerTitle: capitalizeTitle(this_topic.displayText),
          headerType: RESULT_TYPES.TOPIC,
        };
      }

      if (topics.length > 0) {
        let this_topic = Topic.fromId(topics[topics.length - 1]);
        return {
          headerTitle: capitalizeTitle(this_topic.displayText),
          headerType: RESULT_TYPES.TOPIC,
        };
      }

      if (keywords) {
        return {
          headerTitle: capitalizeTitle(keywords),
          headerType: RESULT_TYPES.KEYWORD,
        };
      }
    };

    const { headerTitle, headerType } = determineHeaderDetails(
      keywords,
      topics,
    );

    return (
      <div className={styles.resultsPage}>
        <section ref={leftSectionRef} className={styles.leftSection}>
          <ResultsHeader
            headerTitle={headerTitle}
            keywords={keywords}
            place={location}
            headerType={headerType}
          />
          <ResultsRelatedSection
            entityName={headerTitle}
            loading={loading}
            results={results}
            keywords={keywords}
            location={location}
            resultType={RESULT_TYPES.KEYWORD}
          />

          <div ref={videoSectionRef} className={styles.videoSection}>
            <TranscriptsVideoSection
              renderVideoEmbed={renderVideoEmbed}
              resultType={RESULT_TYPES.KEYWORD}
            />
          </div>
        </section>
        <section className={styles.resultsSection}>
          {results && results.summary && (
            <ResultsSummarySection
              getSummaryData={getSummaryData}
              headerTitle={headerTitle}
              resultType={RESULT_TYPES.KEYWORD}
            />
          )}
          <div className={styles.transcriptsHeader}>{renderResultsTitle()}</div>
          <ul className={styles.container}>{renderResults()}</ul>
        </section>
      </div>
    );
  }
};

//TODO: make result items close when you open a diffrent item
const SearchResultItem = ({
  title,
  onVideoLinkClick,
  date,
  place,
  distance,
  snippets,
  showDistance,
  setLocation,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(null);

  //TODO: lifting up to a higher level and using a single listener that passes media query results down as props
  const mq480 = useMediaQuery({ query: "(max-width: 480px)" });
  const mq720 = useMediaQuery({ query: "(max-width: 1024px)" });
  let baseHeight = 220;

  if (mq480) {
    baseHeight = 270;
  } else if (mq720) {
    baseHeight = 250;
  }

  // add argument i to this callback
  const handleExpandChange = (i, expanded) => {
    if (!expanded) {
      setIsExpanded(null);
    } else {
      setIsExpanded(i);
    }
  };

  const handleClickLeftRight = (idx) => {
    setIsExpanded(null);
    setSelectedIndex(idx);
  };

  const getDisplayDate = () => {
    // note: date must be YYYY-mm-dd
    const [year, month, day] = date.split("-").map((num) => parseInt(num, 10));
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(new Date(year, month - 1, day));
  };

  const getDisplayDistance = () => {
    return (Math.round(distance * 10) / 10).toFixed(1);
  };

  const getDisplayTimestamp = (seconds) =>
    new Date(seconds * 1000).toISOString().substring(11, 19);

  return (
    <li className={styles.transcriptList}>
      <Carousel className={styles.snippets} onChange={handleClickLeftRight}>
        {snippets.map(({ text, timestamp }, i) => {
          timestamp = Math.floor(timestamp);
          return (
            <div
              key={i}
              className={styles.snippetSlide}
              style={{
                height: isExpanded !== null ? "auto" : baseHeight,
                paddingBottom: isExpanded !== null ? "10px" : "0",
                transition:
                  "height 0.3s ease-out, padding-bottom 0.3s ease-out",
              }}
            >
              <div className={styles.customAccordionWrapper}>
                <Accordion
                  onExpandChange={(expanded) => handleExpandChange(i, expanded)}
                  expanded={isExpanded === i}
                  maxHeight={baseHeight - 50}
                  className={styles.customAccordion}
                  expandButtonClassName={styles.customAccordionExpand}
                >
                  <div className={styles.accordionTopContent}>
                    <div className={styles.more}>
                      <a
                        onClick={() =>
                          onVideoLinkClick(
                            Math.floor(snippets[selectedIndex].timestamp),
                          )
                        }
                      >
                        {title}
                      </a>
                    </div>

                    <div className={styles.transcriptInfoRow}>
                      <div className={styles.date}>{getDisplayDate()}</div>
                      <div className={styles.location}>
                        <span
                          tabIndex={0}
                          onClick={async () => {
                            setLocation(
                              await Location.fromDisplayNameStr(place),
                            );
                          }}
                        >
                          {place}
                        </span>
                      </div>
                      {showDistance && (
                        <div className={styles.distance}>
                          {getDisplayDistance()} mi away
                        </div>
                      )}

                      <span className={styles.timestamp}>
                        <a onClick={() => onVideoLinkClick(timestamp)}>
                          {getDisplayTimestamp(timestamp)}
                        </a>
                      </span>
                    </div>
                  </div>

                  <div className={styles.textWidth}>
                    <blockquote>{parse(text)}</blockquote>
                  </div>
                </Accordion>
              </div>
            </div>
          );
        })}
      </Carousel>
    </li>
  );
};

export default ResultsKeywordPage;
