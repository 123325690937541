import React, { useEffect, useRef } from "react";
import "../../layout/Variables.css";
import styles from "./MapBoxDialog.css";
import { useDialog } from "../../context/DialogContext.js";
import { PointsOfInterestMap } from "../Map/Map.js";
import { useMapLocation } from "../../context/LocationContext.js";
import { getUserLocation } from "../../utilities/locationUtils.js";
import { Location } from "../../models/location.js";
import { useApiSearchKeepPreviousData } from "../../hooks/api-hooks.js";

const MapBoxDialog = () => {
  const { openDialogs, closeDialog } = useDialog();
  const dialogRef = useRef(null);
  const isOpen = openDialogs.includes("mapbox");

  const { mapLocation, setMapLocation } = useMapLocation();

  const searchParams = {
    topics: [],
    location: mapLocation,
    keywords: "",
    startDate: null,
    endDate: null,
  };

  const searchQuery = isOpen ? searchParams : null;
  const {
    data: results,
    error,
    isLoading,
  } = useApiSearchKeepPreviousData(searchQuery);

  useEffect(() => {
    if (!isOpen) {
      dialogRef.current.close();
      return;
    }

    if (dialogRef.current.showModal) {
      dialogRef.current.showModal();
    }

    const initializeLocation = async () => {
      if (!mapLocation || !mapLocation.queryText) {
        // Fetch the user's mapLocation if not already set
        const [lon, lat] = await getUserLocation();
        const newLocation = Location.fromLonLat(lon, lat);
        setMapLocation(newLocation);
      }
    };

    initializeLocation();
  }, [isOpen, mapLocation, setMapLocation]);

  return (
    <dialog id="subscribeDialog" className={styles.modal} ref={dialogRef}>
      <div className={styles.modalContentWrapper}>
        <div className={styles.modalCloseBtnWrapper}>
          <button
            id="closeSubscribeDialog"
            className={`${styles.modalCloseBtn} fa-solid fa-xmark`}
            title="Close"
            aria-label="close"
            onClick={() => closeDialog("mapbox")}
          ></button>
        </div>
        <div className={styles.modalContent}>
          {results && mapLocation ? (
            <PointsOfInterestMap
              cityQueryId={mapLocation.queryText}
              pointsOfInterest={results.points_of_interest}
              centroidLongitude={mapLocation.lon}
              centroidLatitude={mapLocation.lat}
            />
          ) : (
            <div className={styles.container}>
              {isLoading ? (
                <i className="fa-solid fa-spinner fa-spin" />
              ) : error ? (
                <p>Error Getting Locations: {results.error}</p>
              ) : (
                <p>No results found</p>
              )}
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
};

export default MapBoxDialog;
