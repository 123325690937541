import React from "react";
import styles from "./PillLink.css";
import { Link } from "react-router-dom";

const getPillLinkClassName = (size, customClass) => {
  let sizeClass = styles.pillLink; // Default size
  if (size === "smaller") {
    sizeClass = styles.pillLinkSmaller;
  } else if (size === "extraSmall") {
    sizeClass = styles.pillLinkExtraSmall; // New class for extra small
  }
  return `${styles.pillLinkBase} ${sizeClass} ${customClass || ""}`;
};

export const KeywordLink = ({ children, url, size = "default", className }) => (
  <Link to={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.keywords}`}>
      {children}
    </span>
  </Link>
);

export const LocationLink = ({
  children,
  url,
  size = "default",
  className,
}) => (
  <Link to={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.location}`}>
      {children}
    </span>
  </Link>
);

export const TopicLink = ({ children, url, size = "default", className }) => (
  <Link to={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.topic}`}>{children}</span>
  </Link>
);

export const SubscribeLink = ({
  children,
  url,
  size = "default",
  className,
}) => (
  <Link to={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.topic}`}>{children}</span>
  </Link>
);

const getRandomWidth = (min = 15, max = 40) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const SkeletonPillLink = () => (
  <span
    className={`${styles.pillLinkBase} ${styles.pillLinkSmaller} ${styles.skeletonPulse}`}
  >
    <span className={styles.pillLinkText}>
      {"\u00A0".repeat(getRandomWidth())}
    </span>
  </span>
);
