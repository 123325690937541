import React from "react";
import styles from "../layout/App.css";
import HomePage from "../pages/HomePage/HomePage.js";

const Home = () => (
  <div className={styles.background}>
    <div className={styles.container}>
      <HomePage />
    </div>
  </div>
);

export default Home;
