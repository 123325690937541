import React from "react";
import styles from "./YoutubeEmbed.css";

const YoutubeEmbed = ({ embedId, start, autoplay }) => {
  const url = `https://www.youtube.com/embed/${embedId}?start=${Math.round(start)}${autoplay ? "&autoplay=1" : ""};`;
  return (
    <div className={styles.video_responsive}>
      <iframe
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        seamless
      />
    </div>
  );
};

export default YoutubeEmbed;
