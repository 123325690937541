import React from "react";
import Hero from "./components/Hero.js";
import GlobalNav from "../../layout/GlobalNavContent.js";
import FeaturedTopicsSection from "./components/FeaturedTopicsSection.js";
import TrendingSection from "./components/TrendingSection.js";
import SubscribeSection from "./components/SubscribeSection.js";
import GlobalFooter from "../../layout/GlobalFooterContent.js";

const HomePage = () => (
  <>
    <Hero startWithTopicSelectFocus={false} />
    <GlobalNav />
    <FeaturedTopicsSection />
    <TrendingSection />
    <SubscribeSection />
    <GlobalFooter />
  </>
);

export default HomePage;
