// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jFmDYeyKxPuUoAVP1gPZ,
.HiTv0Tlue5QrOXBtZTQm {
  border: none;
  background: none;
  background-color: transparent;
  font-size: var(--step-1);
  color: var(--link-color);
  padding: var(--space-2xs);
  transition: var(--global-nav-transition);
  cursor: pointer;
  text-decoration: none;
}

.wyL_YkHbtg2SWvDBrSD_ {
  border: none;
  background: none;
  background-color: transparent;
  font-size: var(--step-1);
  color: var(--link-color);
  transition: var(--global-nav-transition);
  cursor: pointer;
  text-decoration: none;
}

.JzNJgfBOIPeAnGYoycRz {
  width: 100%;
  display: flex;
}

.LGpz9fQ1aJ9cwNleCAc2 {
  --input-border-color: transparent;
  width: 90%;
  --input-border-color-hover: transparent;
  --input-border-color-focus: transparent;
  --input-color: var(--accent-alt-color);
  --input-font-size: var(--step-0);
}

.jFmDYeyKxPuUoAVP1gPZ:hover,
.HiTv0Tlue5QrOXBtZTQm:hover,
.wyL_YkHbtg2SWvDBrSD_:hover {
  color: var(--link-color-hover);
}

.jFmDYeyKxPuUoAVP1gPZ:focus,
.HiTv0Tlue5QrOXBtZTQm:focus,
.wyL_YkHbtg2SWvDBrSD_:focus {
  color: var(--link-color-focus);
}

.jFmDYeyKxPuUoAVP1gPZ:active,
.HiTv0Tlue5QrOXBtZTQm:active,
.wyL_YkHbtg2SWvDBrSD_:active {
  color: var(--link-color-active);
}

@media screen and (max-width: 460px) {
  .wyL_YkHbtg2SWvDBrSD_ {
    margin-right: 2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/NewInput.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,gBAAgB;EAChB,6BAA6B;EAC7B,wBAAwB;EACxB,wBAAwB;EACxB,yBAAyB;EACzB,wCAAwC;EACxC,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,6BAA6B;EAC7B,wBAAwB;EACxB,wBAAwB;EACxB,wCAAwC;EACxC,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,UAAU;EACV,uCAAuC;EACvC,uCAAuC;EACvC,sCAAsC;EACtC,gCAAgC;AAClC;;AAEA;;;EAGE,8BAA8B;AAChC;;AAEA;;;EAGE,8BAA8B;AAChC;;AAEA;;;EAGE,+BAA+B;AACjC;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".locateButton,\n.mapButton {\n  border: none;\n  background: none;\n  background-color: transparent;\n  font-size: var(--step-1);\n  color: var(--link-color);\n  padding: var(--space-2xs);\n  transition: var(--global-nav-transition);\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.inputClearButton {\n  border: none;\n  background: none;\n  background-color: transparent;\n  font-size: var(--step-1);\n  color: var(--link-color);\n  transition: var(--global-nav-transition);\n  cursor: pointer;\n  text-decoration: none;\n}\n\n.keywordInputWrapper {\n  width: 100%;\n  display: flex;\n}\n\n.keywordInput {\n  --input-border-color: transparent;\n  width: 90%;\n  --input-border-color-hover: transparent;\n  --input-border-color-focus: transparent;\n  --input-color: var(--accent-alt-color);\n  --input-font-size: var(--step-0);\n}\n\n.locateButton:hover,\n.mapButton:hover,\n.inputClearButton:hover {\n  color: var(--link-color-hover);\n}\n\n.locateButton:focus,\n.mapButton:focus,\n.inputClearButton:focus {\n  color: var(--link-color-focus);\n}\n\n.locateButton:active,\n.mapButton:active,\n.inputClearButton:active {\n  color: var(--link-color-active);\n}\n\n@media screen and (max-width: 460px) {\n  .inputClearButton {\n    margin-right: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"locateButton": `jFmDYeyKxPuUoAVP1gPZ`,
	"mapButton": `HiTv0Tlue5QrOXBtZTQm`,
	"inputClearButton": `wyL_YkHbtg2SWvDBrSD_`,
	"keywordInputWrapper": `JzNJgfBOIPeAnGYoycRz`,
	"keywordInput": `LGpz9fQ1aJ9cwNleCAc2`
};
export default ___CSS_LOADER_EXPORT___;
