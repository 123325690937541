import useSWR from "swr";
import { Topic } from "../models/topic.js";
import { toQueryString } from "../utilities/funcs.js";

/**
 * Convention:
 *
 * The arguments to useSWR are subtle: the first argument (key) is a cache key,
 * but also is usually the API endpoint and parameters.
 *
 * The first argument to useSWR is `key`. This is used as an internal cache key,
 * which SWR optionally can use to avoid redundant calls. Our convention is that
 * `key` should always be one of these three types:
 *
 * * null: instructs useSWR to not make the backend request.
 * * static string: when endpoints do not accept parameters, use a descriptive
 *   string as the key. The API endpoint is a good choice
 *   (e.g. "get_place_list") because it is descriptive. Use this when the
 *   endpoint always returns the same data on each request.
 * * [endpoint, params]: endpoint is usually the API endpoint (e.g. get_places).
 *   The key argument will be passed to the fetcher.
 *
 * The second argument to useSWR is the fetcher. This generally accepts the key
 * as the first parameter. However, you can customize this with an inline
 * fetcher.
 *
 * Introducing new API methods:
 *
 * Suppose you're adding a new API to get representative voting history served
 * under get_rep_votes. Your new hook could resemble this:
 *
 * function useApiGetVotingHistory(representative_id) {
 *   if (typeof(representative_id) != "number") {
 *     throw Error(
 *       "useApiGetVotingHistory requires representative_id to be number.");
 *   }
 *   return useSWR(
 *     ["get_rep_votes", representative_id],
 *     ([endpoint, representative_id]) =>
 *       fetchJson(`${endpoint}?query_id=${key[1]}`));
 * }
 *
 * useSWR is flexible about the return type of the fetcher; if it is a promise,
 * it will be waited upon. If it is not a promise, it will be returned as an
 * immediate value.
 */

/**
 * Fetcher for use with SWR.
 *
 * @param {string} endpoint The API endpoint (e.g. "get_keywords").
 * @param {RequestInit} options optional options to pass to fetch.
 * @returns {Promise<any>} The response parsed as JSON.
 * @throws {Error} annotated with .info and .status fields.
 *   Use .message for human-readable error message.
 */
const fetchJson = async (endpoint, options = {}) => {
  const url = new URL(endpoint, process.env.BE_URL).href;
  let res = await fetch(url, options);
  let parsed = await res.json();
  if (!res.ok) {
    const error = new Error(`${endpoint} API returned ${res.status}`);
    error.info = parsed;
    error.status = res.status;
    throw error;
  }
  return parsed;
};

export function useApiGetPlaceList() {
  return useSWR("get_place_list", fetchJson);
}

export function useApiGetKeywordsDistinctive() {
  return useSWR("get_keywords", (url) =>
    fetchJson(url).then((parsed) => parsed["distinctive_keywords"]),
  );
}

export function useApiGetFeaturedTopic(featuredTopic) {
  return useSWR(["featured", featuredTopic], ([_endpoint, featuredTopic]) =>
    fetchJson(`featured?query_id=${featuredTopic}`),
  );
}

const fetchSearch = ({ topics, location, keywords, startDate, endDate }) => {
  const queryParams = {
    topics: topics.length > 0 ? topics.join(",") : undefined,
    lonlat: location ? `${location.lon},${location.lat}` : undefined,
    keywords: keywords || undefined,
    search_radius: location?.type === "named" ? 0 : process.env.SEARCH_RADIUS,
    start_date: startDate || undefined,
    end_date: endDate || undefined,
  };

  return fetchJson(`search?${toQueryString(queryParams)}`);
};

export function useApiSearch(query, options = undefined) {
  return useSWR(
    query ? ["search", query] : null,
    ([_endpoint, params]) => fetchSearch(params),
    options,
  );
}

export function useApiSearchKeepPreviousData(query) {
  return useApiSearch(query, {
    keepPreviousData: true,
  });
}

const fetchGetSummary = ({ topics, location }) => {
  let topic_query_id = null;
  if (topics && topics.length > 0) {
    topic_query_id = Topic.fromId(topics[topics.length - 1]).queryText;
  }

  const location_query_id = location ? location.queryText : "";
  const summaryParams = { topic_query_id, location_query_id };

  return fetchJson(`get_summary?${toQueryString(summaryParams)}`);
};

export function useApiFetchSummaryResults(topics, location) {
  const key = topics.length ? ["get_summary", { topics, location }] : null;
  return useSWR(key, ([_endpoint, params]) => fetchGetSummary(params));
}

const fetchGetTopicsByCity = ({ place }) => {
  if (!place) {
    return null;
  }
  return fetchJson(`get_topics_by_city?query_id=${place.queryText}`);
};

export function useApiGetTopicsByCity(place) {
  const key = place ? ["topics", { place }] : null;
  return useSWR(key, ([_endpoint, params]) => fetchGetTopicsByCity(params));
}

export function useApiFetchSuggestionsSuggestionBox(query) {
  const key = query.length >= 3 ? `suggest?s=${query}` : null;
  return useSWR(
    key,
    async (url) => {
      const response = await fetchJson(url);
      return response["suggestions"].slice(0, 10);
    },
    {
      revalidateOnFocus: false,
      dedupingInterval: 100,
    },
  );
}
