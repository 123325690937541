import React from "react";
import DebugConsole from "./DebugConsole.js";
import Hero from "./Hero.js";
import GlobalNav from "./GlobalNavContent.js";
import FeaturedTopicsSection from "./FeaturedTopicsSection.js";
import TrendingSection from "./TrendingSection.js";
import SubscribeSection from "./SubscribeSection.js";
import Body from "./Body.js";
import GlobalFooter from "./GlobalFooterContent.js";
import SubscribeDialog from "./widgets/dialogs/subscribeDialog.js";
import MapBoxDialog from "./widgets/dialogs/mapboxDialog.js";
import AboutPage from "./pages/AboutPage.js";
import MentionsGraphDialog from "./widgets/dialogs/mentionsGraphDialog.js";

import styles from "./App.css";

const App = ({
  mode,
  topics: [topics, setTopics],
  location: [location, setLocation],
  keywords: [keywords, setKeywords],
  startDate: [startDate, setStartDate],
  endDate: [endDate, setEndDate],
  featuredTopic: [featuredTopic, setFeaturedTopic],
}) => {
  return (
    <>
      {mode === "home" && (
        <div className={styles.background}>
          <div className={styles.container}>
            <Hero
              startWithTopicSelectFocus={false}
              topics={[topics, setTopics]}
              location={[location, setLocation]}
              keywords={[keywords, setKeywords]}
            />
            <FeaturedTopicsSection />
            <TrendingSection />
            <SubscribeSection />
            <GlobalFooter />
          </div>
        </div>
      )}
      {/* Temporary until finished updating pages */}
      {mode === "about" && (
        <div className={styles.pageContainer}>
          <GlobalNav
            isStickyByDefault
            topics={[topics, setTopics]}
            location={[location, setLocation]}
            keywords={[keywords, setKeywords]}
          />
          <AboutPage />
          <GlobalFooter />
        </div>
      )}
      {mode !== "home" && mode !== "about" && (
        <div className={styles.pageContainer}>
          {/* <Header
            compressed={mode !== "about"}
            startWithTopicSelectFocus={false}
            topics={[topics, setTopics]}
            location={[location, setLocation]}
            keywords={[keywords, setKeywords]}
          /> */}

          <GlobalNav
            isSearchOpenByDefault={
              mode === "list_topics" || mode === "search" ? true : false
            }
            isStickyByDefault
            topics={[topics, setTopics]}
            location={[location, setLocation]}
            keywords={[keywords, setKeywords]}
            startWithTopicSelectFocus={false}
          />

          <Body
            mode={mode}
            topics={topics}
            location={[location, setLocation]}
            keywords={keywords}
            startDate={startDate}
            endDate={endDate}
            featuredTopic={featuredTopic}
          />
          <GlobalFooter />
          {/* <Footer mode={mode} keywords={keywords} /> */}
        </div>
      )}
      <DebugConsole
        hide
        data={{
          mode,
          topics,
          keywords,
          location: location ? location.queryText : null,
        }}
      />
      <SubscribeDialog />
      <MapBoxDialog />
      <MentionsGraphDialog
        location={location}
        keywords={keywords}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
};

export default App;
