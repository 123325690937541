import React, { useEffect, useRef, useCallback, useMemo } from "react";
import mapboxgl from "mapbox-gl";

import styles from "./Map.css";
import inputStyles from "../GlobalSearchBar/components/SearchBarInput.css";
import { classList as cl } from "../../utilities/funcs.js";

// GEO coordinates of the center of North America
const NA_CENTER_LON = -96.5;
const NA_CENTER_LAT = 38.5;

export const getCityMarkerPopupHTML = (place) => {
  return `
        <div class="${cl(styles.cityMarkerPopup)}">
            <a href="/${place.query_id}"><strong>${place.name}</strong></a>
            <p>${place.num_meetings} meetings</p>
            <a href="${place.last_meeting_link}" target="_blank">${place.last_meeting_title}</a>
        </div>
    `;
};

const getKeywordRegex = (keyword) => {
  // Escape special characters in the keyword to use in a regular expression
  const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  // Create a RegExp object, setting the 'gi' flag for global and case-insensitive search
  return new RegExp(escapedKeyword, "gi");
};

// Replace occurrences of the keyword with "<mark>keyword</mark>"
const highlightKeyword = (text, keywordRegex) => {
  return text.replace(keywordRegex, (match) => `<mark>${match}</mark>`);
};

// Highlight the poi name and shorten the mention if it's too long
const getInlinedMention = (poi) => {
  let inlinedMention = poi.last_mention.sentence;
  const maxMentionLength = 150;
  const poiRegex = getKeywordRegex(poi.name);
  const poiNameIndex = inlinedMention.search(poiRegex);
  if (inlinedMention.length > maxMentionLength && poiNameIndex !== -1) {
    const center = poiNameIndex + poi.name.length / 2;
    const maxHalfLength = maxMentionLength / 2;
    if (
      center >= maxHalfLength &&
      inlinedMention.length - center > maxHalfLength
    ) {
      inlinedMention =
        inlinedMention.slice(center - maxHalfLength, center + maxHalfLength) +
        "…";
      inlinedMention =
        "…" + inlinedMention.slice(inlinedMention.indexOf(" ") + 1); // start with a full word
    } else if (center < maxHalfLength) {
      inlinedMention = inlinedMention.slice(0, maxMentionLength) + "…";
    } else {
      inlinedMention = inlinedMention.slice(
        inlinedMention.length - maxMentionLength,
      );
      inlinedMention =
        "…" + inlinedMention.slice(inlinedMention.indexOf(" ") + 1); // start with a full word
    }
  }
  inlinedMention = highlightKeyword(inlinedMention, poiRegex);
  return inlinedMention;
};

export const getPointOfInterestMarkerPopupHTML = (poi, cityQueryId) => {
  const mentionsText = `${poi.num_mentions} ${poi.num_mentions === 1 ? "mention" : "mentions"}`;
  const inlinedMention = getInlinedMention(poi);
  const mentionHasDeeplink =
    poi.last_mention.link.search(/.*&t=[0-9]+s?/) !== -1;
  return `
        <div class=${cl(styles.poiMarkerPopup)}>
            <strong>${poi.name}</strong>: <a href="/search?l=${cityQueryId}&k=${poi.name}">${mentionsText}</a><br />
            <div class=${cl(styles.lastMention)}>
                Last mention (<a href="${poi.last_mention.link}">${poi.last_mention.date}${mentionHasDeeplink ? " &#9658;" : ""}</a>):<br />
                <em>${inlinedMention}</em>
            </div>
        </div>
    `;
};

const getMinimapMarkerPopupHTML = (place) => {
  return `<a href="/${place.query_id}"><strong>${place.name}</strong></a>`;
};

export const PointsOfInterestMap = ({
  cityQueryId,
  pointsOfInterest,
  centroidLongitude,
  centroidLatitude,
}) => {
  const markerPopupHTML = useCallback(
    (place) => getPointOfInterestMarkerPopupHTML(place, cityQueryId),
    [cityQueryId],
  );

  return (
    <Map
      places={pointsOfInterest}
      initialLongitude={centroidLongitude}
      initialLatitude={centroidLatitude}
      getMarkerPopupHTML={markerPopupHTML}
      minZoom={9}
      maxZoom={16}
      focusZoom={12}
      markerPopupWrapperClassName={cl(styles.poiMarkerPopupWrapper)}
      minLatitude={centroidLatitude - 1}
      maxLatitude={centroidLatitude + 1}
      minLongitude={centroidLongitude - 1}
      maxLongitude={centroidLongitude + 1}
      showPlaceLabels={true}
    />
  );
};

export const MiniMap = ({ show, places, onPlaceClicked }) => {
  return (
    <Map
      places={places}
      onMarkerClicked={onPlaceClicked}
      getMarkerPopupHTML={getMinimapMarkerPopupHTML}
      mapContainerClassName={cl(
        styles.miniMapContainer,
        inputStyles.popupContainer,
        show ? inputStyles.show : null,
      )}
      showPlaceLabels={false}
      minZoom={1.75}
      startZoom={1.75}
    />
  );
};

const Map = ({
  places,
  onMarkerClicked,
  getMarkerPopupHTML,
  initialLongitude = NA_CENTER_LON,
  initialLatitude = NA_CENTER_LAT,
  markerPopupWrapperClassName = "",
  mapContainerClassName = cl(styles.mapContainer),
  minZoom = 2,
  startZoom = 3,
  maxZoom = 9,
  focusZoom = 8,
  minLatitude = 15,
  maxLatitude = 84,
  minLongitude = -170,
  maxLongitude = -49,
  showPlaceLabels = false,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const geojson = useMemo(
    () => ({
      type: "FeatureCollection",
      features: places.map((place) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [place.longitude, place.latitude],
        },
        properties: {
          title: place.name,
        },
      })),
    }),
    [places],
  );

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: process.env.MAPBOX_STYLE,
      minZoom: minZoom,
      maxZoom: maxZoom,
      center: [initialLongitude, initialLatitude],
      zoom: startZoom,
      projection: "mercator",
      maxBounds: new mapboxgl.LngLatBounds(
        [minLongitude, minLatitude],
        [maxLongitude, maxLatitude],
      ),
    });
    map.current.addControl(new mapboxgl.NavigationControl());
    map.current.on("load", () => {
      map.current.resize();
    });
    map.current.on("style.load", () => {
      map.current.addSource("places", {
        type: "geojson",
        data: geojson,
      });
      map.current.addLayer({
        id: "places-label",
        type: "symbol",
        source: "places",
        layout: {
          "text-field": ["get", "title"], // Display the title from the GeoJSON properties
          "text-variable-anchor": ["top", "bottom", "left", "right"],
          "text-radial-offset": 0.5,
          "text-justify": "auto",
          "text-size": 14,
        },
      });
    });

    places.forEach((place) => {
      const popup = new mapboxgl.Popup({ className: styles.popup })
        .setHTML(getMarkerPopupHTML(place))
        .addClassName(markerPopupWrapperClassName);
      const marker = new mapboxgl.Marker({ color: "#003da5", scale: 0.5 })
        .setLngLat([place.longitude, place.latitude])
        .setPopup(popup);
      marker.addTo(map.current);
      marker.getElement().addEventListener("click", () => {
        map.current.flyTo({
          center: marker.getLngLat(),
          zoom: focusZoom,
          animate: true,
          speed: 1.5,
        });
        if (onMarkerClicked) {
          onMarkerClicked(place.name);
        }
      });
    });
    return () => map.current.remove();
  }, [
    initialLatitude,
    initialLongitude,
    maxLatitude,
    maxLongitude,
    maxZoom,
    minLatitude,
    minLongitude,
    minZoom,
    startZoom,
    places,
    getMarkerPopupHTML,
    markerPopupWrapperClassName,
    focusZoom,
    onMarkerClicked,
    geojson,
    showPlaceLabels,
  ]);

  return (
    <div
      className={mapContainerClassName}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <div ref={mapContainer} />
    </div>
  );
};

export default Map;
