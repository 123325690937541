import React from "react";
import { Link } from "react-router-dom";
import * as DOMPurify from "dompurify";
import styles from "./parser.css";
import parse, { attributesToProps, domToReact } from "html-react-parser";

const _parse = (text) => {
  const options = {
    replace: ({ attribs, children }) => {
      if (attribs && attribs.class === "use-link-tag") {
        let props = attributesToProps(attribs);
        props.to = props.href;
        delete props.href;
        delete props.className;

        return <Link {...props}>{domToReact(children)}</Link>;
      }

      if (attribs && attribs.href) {
        let className = styles.link;

        if (attribs.href.includes(".youtube.com/")) {
          className = styles.videoLink;
        }

        if (attribs.href.includes("?l=")) {
          className = styles.placeLink;
        }

        return (
          <a
            href={attribs.href}
            className={className}
            target="_blank"
            rel="noopener noreferrer"
          >
            {domToReact(children)}
          </a>
        );
      }
    },
  };

  return parse(DOMPurify.sanitize(text), options);
};

export { _parse as parse };
