import React from "react";
import styles from "./RelatedContent.css";
import { SkeletonPillLink } from "../../../../../widgets/buttons/PillLink.js";

const SkeletonContent = ({ count = 8 }) => (
  <div className={styles.relatedInfo}>
    {[...Array(count)].map((_, i) => (
      <SkeletonPillLink key={i} />
    ))}
  </div>
);

export default SkeletonContent;
