import React from "react";
import { fetchJson } from "../../utilities/funcs.js";

import Accordion from "../widgets/Accordion.js";
import LoadingPage from "./LoadingPage.js";
import ErrorPage from "./ErrorPage.js";

import { issueKeywords } from "../../compiledFromBe.mjs";

import styles from "./AllKeywordsPage.css";
import { KeywordLink } from "../widgets/NewPillLink.js";
import { MdOutlineAutoStories } from "react-icons/md";
import useSWR from "swr";

const fetchDistinctiveKeywords = (url) =>
  fetchJson(url).then((parsed) => parsed["distinctive_keywords"]);

const AllKeywordsPage = () => {
  const {
    data: keywords,
    error,
    isLoading,
  } = useSWR("get_keywords", fetchDistinctiveKeywords);
  if (error) {
    return <ErrorPage message={error.message} />;
  } else if (isLoading) {
    return <LoadingPage />;
  }

  let issueKeywordObj = {
    title: "Common local policy issues",
    keywords: issueKeywords,
  };
  let allKeywordSets = [issueKeywordObj].concat(keywords);
  return (
    <div className={styles.keywordsPage}>
      <section className={styles.aboutSection}>
        <div className={styles.topAbout}>
          <h1 className={styles.aboutSectionTitle}>
            <div className={styles.iconWrapper}>
              <MdOutlineAutoStories
                size={38}
                color="var(--white-color)"
                style={{ marginRight: "5px" }}
              />
            </div>
            Keywords
          </h1>
          <p>
            Here you'll find frequently mentioned words and phrases from local
            government meetings during the past several months.  Also see the
            topics page (linked above) for the terms characteristic of different subject areas,
            or type any keyword in the search bar at the top of this page.
          </p>
        </div>
        <div className={styles.bottomAbout}>
          <h3>{issueKeywordObj.title}</h3>
          <Accordion
            maxHeight={225}
            recalculateOnMount={true}
            className={styles.customAccordion}
          >
            {issueKeywordObj.keywords.map((kw, j) => (
              <KeywordLink size="smaller" key={j} url={`/search?k=${kw}`}>
                {kw}
              </KeywordLink>
            ))}
          </Accordion>
        </div>
      </section>
      <section className={styles.pillLinkSection}>
        <div className={styles.pillSearchBar}>
          {/* <StateSelector states={states} onStateSelect={scrollToState} /> */}
        </div>
        {allKeywordSets.slice(1).map((kw_obj, i) => (
          <div key={i} className={styles.monthGroup}>
            <h3>{kw_obj.title}</h3>

            <Accordion
              maxHeight={170}
              recalculateOnMount={true}
              className={styles.customAccordion}
            >
              <div className={styles.relatedInfoBox}>
                {kw_obj.keywords.map((kw, j) => (
                  <KeywordLink size="smaller" key={j} url={`/search?k=${kw}`}>
                    {kw}
                  </KeywordLink>
                ))}
              </div>
            </Accordion>
          </div>
        ))}
      </section>
    </div>
  );
};

export default AllKeywordsPage;
