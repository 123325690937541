// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xuJIRJareTnJ7RQeMwhK {
  box-sizing: border-box;
  padding: 8px;
  font-family: monospace;
  background-color: darkred;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/layout/DebugConsole.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".container {\n  box-sizing: border-box;\n  padding: 8px;\n  font-family: monospace;\n  background-color: darkred;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `xuJIRJareTnJ7RQeMwhK`
};
export default ___CSS_LOADER_EXPORT___;
