import React from "react";
import DebugConsole from "./DebugConsole.js";
import Home from "../modes/Home.js";
import About from "../modes/About.js";
import Default from "../modes/Default.js";
import SubscribeDialog from "../widgets/dialogs/SubscribeDialog.js";
import MapBoxDialog from "../widgets/dialogs/MapBoxDialog.js";
import MentionsGraphDialog from "../widgets/dialogs/MentionsGraphDialog.js";

const App = ({ mode }) => {
  let ModeComponent =
    {
      home: Home,
      about: About,
    }[mode] || Default;

  return (
    <>
      <ModeComponent mode={mode} />
      <DebugConsole hide mode={mode} />
      {/* Global dialogs */}
      <SubscribeDialog />
      <MapBoxDialog />
      <MentionsGraphDialog />
    </>
  );
};

export default App;
