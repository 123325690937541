import React from "react";
import styles from "./TrendingSection.css";
import SecondaryButton from "./widgets/buttons/SecondaryButton.js";
import { Link } from "react-router-dom";
import { issueKeywords } from "../compiledFromBe.mjs";
import { Topic } from "../models/topic.js";
import { TopicLink, KeywordLink } from "./widgets/NewPillLink.js";

const TrendingSection = () => {
  const featured = {
    keywords: issueKeywords.slice(0, 18),
    topics: [
      "affordable-housing",
      "drugs",
      "homelessness-issues",
      "racial-issues",
      "renewable-energy",
      "surveillance-cameras",
    ],
  };

  return (
    <section id="trending" className={styles.trendingTopics}>
      <div className={` ${styles.sectionContent}`}>
        <h2>Trending Topics &amp; Keywords</h2>
        <p>Browse popular keywords and topics mentioned in local meetings.</p>
        <div className={styles.pillLinkList}>
          {featured.topics.map((topic_query_id, i) => {
            const topic = Topic.fromQueryIdStr(topic_query_id);
            return (
              <TopicLink key={i} url={`/search?t=${topic.queryText}`}>
                {topic.displayText}
              </TopicLink>
            );
          })}
          {featured.keywords.map((kw, i) => (
            <KeywordLink key={i} url={`/search?k=${kw}`}>
              {kw}
            </KeywordLink>
          ))}
        </div>
        <div className={styles.btnGroup}>
          <Link to="/topics">
            <SecondaryButton size="md">Browse Topics</SecondaryButton>
          </Link>
          <Link to="/keywords">
            <SecondaryButton size="md">Browse Keywords</SecondaryButton>
          </Link>
        </div>
      </div>
      <div className={styles.pageDownBtnWrapper}>
        <a
          href="#subscribe"
          className={styles.pageDownBtn}
          role="button"
          title="Scroll Down"
          aria-label="scroll down"
        >
          <i className="fa-solid fa-circle-chevron-down" aria-hidden="true"></i>
        </a>
      </div>
    </section>
  );
};

export default TrendingSection;
