import React, { useEffect, useRef, useState } from "react";

import ErrorPage from "../ErrorPage/ErrorPage.js";
import ResultsHeader from "./components/ResultsHeader.js";
import ResultsRelatedSection from "./components/ResultsRelatedSection/ResultsRelatedSection.js";
import ResultsSummarySection from "./components/ResultsSummarySection.js";
import SearchResultsList from "./components/SearchResultsList/SearchResultsList.js";
import TranscriptsVideoSection from "./components/TranscriptVideoSection/TranscriptsVideoSection.js";

import RESULT_TYPES from "../../models/ResultTypes.js";
import {
  determineHeaderDetails,
  getSummaryData,
  handleScrollToVideoSection,
  processEmbedResults,
  renderResultsTitle,
} from "../../utilities/resultsHelper.js";

import styles from "./ResultsPage.css";
import {
  useApiSearch,
  useApiFetchSummaryResults,
} from "../../hooks/api-hooks.js";

const ResultsKeywordPage = ({
  topics,
  location,
  setLocation,
  keywords,
  startDate,
  endDate,
}) => {
  const [searchRequestFailed, setSearchRequestFailed] = useState(false);
  const [embedVideoId, setEmbedVideoId] = useState(null);
  const [embedTimestamp, setEmbedTimestamp] = useState(null);
  const [embedVideoTitle, setEmbedVideoTitle] = useState(null);
  const [embedVideoUrl, setEmbedVideoUrl] = useState(null);
  const [autoplayEmbed, setAutoplayEmbed] = useState(false);

  const leftSectionRef = useRef(null);
  const videoSectionRef = useRef(null);

  const searchParams = {
    topics,
    location,
    keywords,
    startDate,
    endDate,
  };

  const {
    data: results,
    error: resultsError,
    isLoading,
  } = useApiSearch(searchParams);

  const {
    data: summaryResults,
    error: summaryError,
    isLoading: isSummaryLoading,
  } = useApiFetchSummaryResults(topics, location);

  useEffect(() => {
    if (results) {
      const { videoId, timestamp, videoTitle, videoUrl } =
        processEmbedResults(results);
      setEmbedVideoId(videoId);
      setEmbedTimestamp(timestamp);
      setEmbedVideoTitle(videoTitle);
      setSearchRequestFailed(false);
      if (videoUrl) setEmbedVideoUrl(videoUrl);
    }
  }, [results]);

  const handleVideoSelect = (videoId, timestamp, videoTitle, videoUrl) => {
    setEmbedVideoId(videoId);
    setEmbedTimestamp(timestamp);
    setAutoplayEmbed(true);
    setEmbedVideoTitle(videoTitle);

    handleMoreClick(timestamp);
    if (videoUrl) setEmbedVideoUrl(videoUrl);
  };

  const handleMoreClick = () => {
    if (window.innerWidth > 1024) {
      handleScrollToVideoSection(leftSectionRef, videoSectionRef);
    }
  };

  if (resultsError) {
    return (
      <ErrorPage
        header="An error occurred"
        message={`${resultsError?.message || "Failed to load content"}`}
      />
    );
  }
  const { headerTitle, headerType } = determineHeaderDetails(keywords, topics);

  return (
    <div className={styles.resultsPage}>
      <section ref={leftSectionRef} className={styles.leftSection}>
        <ResultsHeader
          headerTitle={headerTitle}
          keywords={keywords}
          place={location}
          headerType={headerType}
          startDate={startDate}
          endDate={endDate}
        />
        <ResultsRelatedSection
          entityName={headerTitle}
          loading={isLoading}
          results={results}
          keywords={keywords}
          location={location}
          resultType={RESULT_TYPES.KEYWORD}
        />

        <div ref={videoSectionRef} className={styles.videoSection}>
          <TranscriptsVideoSection
            embedVideoId={embedVideoId}
            embedTimestamp={embedTimestamp}
            embedVideoTitle={embedVideoTitle}
            embedVideoUrl={embedVideoUrl}
            autoplayEmbed={autoplayEmbed}
            keywords={keywords}
            summaryResults={summaryResults}
            startDate={startDate}
            endDate={endDate}
            isLoading={isLoading}
            results={results}
            searchRequestFailed={searchRequestFailed}
            resultType={RESULT_TYPES.KEYWORD}
          />
        </div>
      </section>
      <section className={styles.resultsSection}>
        {results && results.summary && (
          <ResultsSummarySection
            getSummaryData={() =>
              getSummaryData(isSummaryLoading, results, summaryError)
            }
            headerTitle={keywords}
            resultType={RESULT_TYPES.KEYWORD}
            isLoading={isSummaryLoading}
          />
        )}

        <div className={styles.transcriptsHeader}>
          {renderResultsTitle(isLoading, results, startDate, endDate)}
        </div>
        <ul className={styles.container}>
          <SearchResultsList
            results={results}
            isLoading={isLoading}
            searchRequestFailed={searchRequestFailed}
            location={location}
            setLocation={setLocation}
            onVideoSelect={handleVideoSelect}
          />
        </ul>
      </section>
    </div>
  );
};

export default ResultsKeywordPage;
