import React, { useEffect, useRef } from "react";
import { classList as cl } from "../../utilities/funcs.js";
import { Topic } from "../../models/topic.js";
import styles from "./SuggestionBox.css";
import { KeywordLink, LocationLink, TopicLink } from "../buttons/PillLink.js";
import { useApiFetchSuggestionsSuggestionBox } from "../../hooks/api-hooks.js";

const SuggestionBox = ({ inputLetters, currentLocation, onClose }) => {
  let queryLetters = inputLetters || "";
  let showOnlyLocations = false;
  if (queryLetters.startsWith("loc_")) {
    showOnlyLocations = true;
    queryLetters = queryLetters.slice(4);
  }

  const {
    data: suggestions,
    error,
    isLoading,
  } = useApiFetchSuggestionsSuggestionBox(queryLetters);

  const suggestionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <div ref={suggestionsRef}>Error loading suggestions.</div>;
  }

  if (!suggestions || suggestions.length === 0) {
    return <div ref={suggestionsRef}></div>;
  }
  return (
    <div ref={suggestionsRef} className={cl(styles.container)}>
      <h2>TOP MATCHES</h2>
      {suggestions.map((sug, i) => {
        let kw = sug[0];
        if (sug[2] === "topic" && !showOnlyLocations) {
          let topic = Topic.fromQueryIdStr(kw);
          return (
            <TopicLink
              url={`/search?t=${kw}${currentLocation ? "&l=" + currentLocation.queryText : ""}`}
              key={i}
            >
              {topic.displayText}
            </TopicLink>
          );
        } else if (sug[2] === "place") {
          return (
            <LocationLink url={`/${sug[3]}`} key={i}>
              {kw}
            </LocationLink>
          );
        } else if (!showOnlyLocations) {
          return (
            <KeywordLink
              url={`/search?k=${kw}${currentLocation ? "&l=" + currentLocation.queryText : ""}`}
              key={i}
            >
              {kw}
            </KeywordLink>
          );
        }
      })}
    </div>
  );
};

export default SuggestionBox;
