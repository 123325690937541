import React, { useState, useRef, useLayoutEffect } from "react";
import { masterTopicList } from "../models/topic.js";

import Input from "./widgets/Input.js";
import MultiSelect from "./widgets/MultiSelect.js";
import LocationSelect from "./widgets/LocationSelect.js";
import SuggestionBox from "./widgets/SuggestionBox.js";
import NewSuggestionBox from "./widgets/NewSuggestionBox.js";

import styles from "./SearchBar.css";

const SearchBar = ({
  topics: [propTopics, setPropTopics],
  location: [propLocation, setPropLocation],
  keywords: [propKeywords, setPropKeywords],
  startWithTopicSelectFocus,
  onSearchComplete,
}) => {
  const searchTimer = useRef();
  const searchBarDiv = useRef();

  const [topics, setTopics] = useState([]);
  const [location, setLocation] = useState(null);
  const [keywords, setKeywords] = useState(propKeywords);
  const [suggestInput, setSuggestInput] = useState("");
  const [currentKeywordInput, setCurrentKeywordInput] = useState("");
  const [currentLocationInput, setCurrentLocationInput] = useState("");

  const isValidLocation = (loc) => {
    return (
      loc &&
      typeof loc === "object" &&
      "queryText" in loc &&
      typeof loc.queryText === "string"
    );
  };

  // const handleSetLocation = (val) => {
  //   if (typeof val === "function") {
  //     // ensure that setLocation and setPropLocation are called on the same value
  //     val = val(location);
  //   }
  //   setLocation(val);
  //   clearTimeout(searchTimer.current);
  //   searchTimer.current = setTimeout(() => {
  //     if (currentKeywordInput) {
  //       setPropKeywords(currentKeywordInput);
  //     }
  //     setPropLocation(val);
  //     onSearchComplete?.();
  //   }, 250);
  // };

  const handleSetLocation = (val) => {
    if (val && !isValidLocation(val)) {
      console.warn("Invalid location format:", val);
      return;
    }

    if (typeof val === "function") {
      val = val(location);
    }

    setLocation(val);
    setCurrentLocationInput(val);
    if (currentKeywordInput) {
      setPropKeywords(currentKeywordInput);
    }

    if (val === null || isValidLocation(val)) {
      setPropLocation(val);
    }
  };

  // const handleSetKeywords = (val) => {
  //   if (typeof val === "function") {
  //     val = val(keywords);
  //   }
  //   setKeywords(val);

  //   const locationToUse = location || currentLocationInput;
  //   if (locationToUse) {
  //     setPropLocation(locationToUse);
  //   }
  //   setPropKeywords(val);
  // };

  const handleSetKeywords = (val) => {
    if (typeof val === "function") {
      // ensure that setKeywords and setPropKeywords are called on the same value
      val = val(keywords);
    }
    setKeywords(val);
    clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setPropTopics("");
      if (isValidLocation(currentLocationInput)) {
        setPropLocation(currentLocationInput);
      }
      setPropKeywords(val);
      setSuggestInput("");
      onSearchComplete?.();
    }, 250);
  };

  useLayoutEffect(() => {
    setTopics(propTopics);
    setLocation(propLocation);
    setKeywords(propKeywords);
    setSuggestInput("");
  }, [propTopics, propLocation, propKeywords]);

  return (
    <div ref={searchBarDiv}>
      <nav className={styles.container}>
        <Input
          parent={searchBarDiv}
          value={[keywords, handleSetKeywords]}
          fa="fa-solid fa-magnifying-glass fa-fw"
          color="cornflowerblue"
          placeholder="Word or phrase or city..."
          startWithFocus={true}
          flexGrow={4}
          setSuggestInput={setSuggestInput}
          setCurrentKeywordInput={setCurrentKeywordInput}
          onSearchComplete={onSearchComplete}
        />
        <div className={styles.divider} />
        <LocationSelect
          parent={searchBarDiv}
          value={[location, handleSetLocation]}
          fa="fa-solid fa-location-dot fa-fw"
          color="coral"
          placeholder="ZIP, postal code, or city"
          flexGrow={4}
          showMap={false}
          setSuggestInput={setSuggestInput}
          onSearchComplete={onSearchComplete}
          setCurrentLocationInput={setCurrentLocationInput}
        />
      </nav>
      <div className={styles.suggestionBoxWrapper}>
        <NewSuggestionBox
          inputLetters={suggestInput}
          currentLocation={location}
          currentKeywords={currentKeywordInput}
          onClose={() => setSuggestInput("")}
        />
      </div>
    </div>
  );
};

export default SearchBar;
