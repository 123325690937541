import React from "react";
import { masterTopicList } from "../../models/topic.js";

import styles from "./AllTopicsPage.css";
import { classList as cl } from "../../utilities/funcs.js";
import { KeywordLink, TopicLink } from "../widgets/NewPillLink.js";
import { BiBookBookmark } from "react-icons/bi";

const AllTopicsPage = () => {
  let topics = masterTopicList;

  return (
    <div className={styles.allTopicsPage}>
      <section className={styles.aboutSection}>
        <div className={styles.topAbout}>
          <h1 className={styles.aboutSectionTitle}>
            <BiBookBookmark
              size={32}
              color="var(--white-color)"
              style={{ marginRight: "5px" }}
            />
            Topics
          </h1>
          <p>
              CivicSearch organizes local government discussion into 75 high-level
	      topics such as{" "}
            <TopicLink size="extraSmall" url={`/search?t=affordable-housing`}>
              affordable housing
            </TopicLink>{" "}
	      and
            <TopicLink size="extraSmall" url={`/search?t=bicycle-transportation`}>
	      bicycle transportation
            </TopicLink>{" "}.   The complete list of topics is below, and beneath each
	      one are keywords strongly associated with the topics.  Click on
	      a topic link to get detailed information about the topic, including more
	      keywords.
          </p>
        </div>
      </section>

      <section className={styles.pillLinkSection}>
        <div className={styles.pillSearchBar}>
          {/* <StateSelector states={states} onStateSelect={scrollToState} /> */}
        </div>
        <ol>
          {topics.map((topic, i) => {
            if (topic != null) {
              return (
                <li key={i}>
                  <div className={styles.topicLink}>
                    <TopicLink iSmaller url={`/search?t=${topic.queryText}`}>
                      {topic.displayText}
                    </TopicLink>
                  </div>

                  <div className={styles.keywordGroup}>
                    {topic.keyword_stats.map((kw, i) => (
                      <KeywordLink key={i} iSmaller url={`/search?k=${kw}`}>
                        <span className={cl(styles.small)}>{kw}</span>
                      </KeywordLink>
                    ))}
                  </div>
                </li>
              );
            }
          })}
        </ol>
      </section>
    </div>
  );
};

export default AllTopicsPage;
