export const topicData = [
    {
        "id": -1,
        "keyword_stats": [
            "Constitution and the Laws of the United States",
            "eminent domain action",
            "solly swear or affirm",
            "decibel readings",
            "Green Burial",
            "streaming platforms",
            "Section 549",
            "solemly swear or affirm",
            "penetrating radar",
            "covid vaccination"
        ],
        "name": "Local governance",
        "query_id": "local-governance"
    },
    {
        "id": 70,
        "keyword_stats": [
            "master plan",
            "planner",
            "strategic plan",
            "planners",
            "comp plan",
            "master plans",
            "professional planner",
            "principal planner",
            "associate planner",
            "professional planners"
        ],
        "name": "Planning process",
        "query_id": "planning-process"
    },
    {
        "id": 64,
        "keyword_stats": [
            "music Foreign",
            "concert series",
            "tribute band",
            "band concert",
            "Saxophone",
            "Music Laughs",
            "His birthday",
            "Mariachi band",
            "classic rock",
            "Her birthday"
        ],
        "name": "Events and celebrations",
        "query_id": "events-and-celebrations"
    },
    {
        "id": 5,
        "keyword_stats": [
            "Artists",
            "mural",
            "artwork",
            "murals",
            "local artists",
            "Sculpture",
            "sculptures",
            "Artworks",
            "muralist",
            "sculptor"
        ],
        "name": "Public art",
        "query_id": "public-art"
    },
    {
        "id": 45,
        "keyword_stats": [
            "Librarian",
            "Librarians",
            "library card",
            "ebooks",
            "audio books",
            "branch libraries",
            "ebook",
            "banning books",
            "audiobooks",
            "libraryies"
        ],
        "name": "Public libraries",
        "query_id": "public-libraries"
    },
    {
        "id": 33,
        "keyword_stats": [
            "feral cats",
            "spayed or neutered",
            "spayed and neutered",
            "feral cat population",
            "animal rescues",
            "breeding dogs",
            "spayed neutered",
            "female cat",
            "feeding the cats",
            "unwanted animals"
        ],
        "name": "Animal issues",
        "query_id": "animal-issues"
    },
    {
        "id": 31,
        "keyword_stats": [
            "food insecure",
            "salads",
            "experiencing food insecurity",
            "shelf stable",
            "nutritious meal",
            "Chicken salad",
            "processed foods",
            "lbs of food",
            "addressing food insecurity",
            "fried foods"
        ],
        "name": "Food and dining",
        "query_id": "food-and-dining"
    },
    {
        "id": 28,
        "keyword_stats": [
            "cameras",
            "body cameras",
            "body worn",
            "body cams",
            "body cam",
            "red light cameras",
            "camera footage",
            "surveillance cameras",
            "speed cameras",
            "body cam footage"
        ],
        "name": "Surveillance cameras",
        "query_id": "surveillance-cameras"
    },
    {
        "id": 57,
        "keyword_stats": [
            "E bikes",
            "electric bikes",
            "protected bike",
            "ride bicycles",
            "electric scooter",
            "bike rider",
            "avid cyclist",
            "cargo bikes",
            "bicycle riders",
            "Pedal Assist"
        ],
        "name": "Bicycle transportation",
        "query_id": "bicycle-transportation"
    },
    {
        "id": 83,
        "keyword_stats": [
            "affordable housing",
            "housing crisis",
            "housing stock",
            "subsidized housing",
            "attainable housing",
            "affordable housing stock",
            "starter homes",
            "affable housing",
            "affordable housings",
            "affordable homeownership"
        ],
        "name": "Affordable housing",
        "query_id": "affordable-housing"
    },
    {
        "id": 9,
        "keyword_stats": [
            "licenses",
            "licensee",
            "licensees",
            "license holder",
            "license holders",
            "license premise",
            "Entertainment license",
            "license premises",
            "Cdl license",
            "Abc License"
        ],
        "name": "Business licensing",
        "query_id": "business-licensing"
    },
    {
        "id": 49,
        "keyword_stats": [
            "bids",
            "bidder",
            "bidders",
            "biders",
            "low bidder",
            "lowest bid",
            "lowest bidder",
            "competitive bids",
            "lowest responsible bidder",
            "sealed bids"
        ],
        "name": "Bidding process",
        "query_id": "bidding-process"
    },
    {
        "id": 35,
        "keyword_stats": [
            "artificial Turf",
            "natural grass",
            "grass seed",
            "grass fields",
            "natural turf",
            "turf grass",
            "native grasses",
            "artificial turf fields",
            "mowing grass",
            "artificial grass"
        ],
        "name": "Turf and grass",
        "query_id": "turf-and-grass"
    },
    {
        "id": 39,
        "keyword_stats": [
            "repair",
            "repairs",
            "repaired",
            "deferred maintenance",
            "preventative maintenance",
            "preventive maintenance",
            "routine maintenance",
            "equipments",
            "repairable",
            "shelf life"
        ],
        "name": "Equipment maintenance/replacement",
        "query_id": "equipment-maintenance-replacement"
    },
    {
        "id": 27,
        "keyword_stats": [
            "experiencing homelessness",
            "homeless population",
            "homeless shelter",
            "shelter beds",
            "addressing homelessness",
            "homeless encampments",
            "becoming homeless",
            "chronically homeless",
            "chronic homelessness",
            "unsheltered homelessness"
        ],
        "name": "Homelessness issues",
        "query_id": "homelessness-issues"
    },
    {
        "id": 107,
        "keyword_stats": [
            "Floor is yours",
            "yield the floor",
            "licensed architect",
            "brick facade",
            "brick veneer",
            "brick masonry",
            "yields the floor",
            "brick and stone",
            "thin brick",
            "yielding the floor"
        ],
        "name": "Building construction",
        "query_id": "building-construction"
    },
    {
        "id": 29,
        "keyword_stats": [
            "parking spaces",
            "parking spots",
            "parking stalls",
            "overflow parking",
            "parking stall",
            "handicap parking",
            "parking minimums",
            "parkings",
            "angled parking",
            "diagonal parking"
        ],
        "name": "Parking issues",
        "query_id": "parking-issues"
    },
    {
        "id": 69,
        "keyword_stats": [
            "project",
            "projects",
            "developer",
            "developers",
            "mitigated negative declaration",
            "major milestone",
            "fully baked",
            "multiple iterations",
            "Line Replacement",
            "lingered"
        ],
        "name": "Development projects",
        "query_id": "development-projects"
    },
    {
        "id": 37,
        "keyword_stats": [
            "grants",
            "federal grants",
            "grant writer",
            "grant writers",
            "Afg grant",
            "Mvp grant",
            "Elder Affairs",
            "Assistance to Firefighters",
            "Homeland Security grants",
            "Community Compact"
        ],
        "name": "Grant funding",
        "query_id": "grant-funding"
    },
    {
        "id": 60,
        "keyword_stats": [
            "speed limit",
            "speed limits",
            "85th percentile",
            "85th percentile speed",
            "pedestrian crosswalk",
            "lowering the speed limit",
            "pedestrian crosswalks",
            "lane configuration",
            "marked crosswalk",
            "painted crosswalk"
        ],
        "name": "Traffic management",
        "query_id": "traffic-management"
    },
    {
        "id": 47,
        "keyword_stats": [
            "Led lights",
            "foot candles",
            "lumens",
            "Led fixtures",
            "high pressure sodium",
            "dark sky compliant",
            "streetlights",
            "Led bulbs",
            "incandescent",
            "ambient light"
        ],
        "name": "Street lighting",
        "query_id": "street-lighting"
    },
    {
        "id": 74,
        "keyword_stats": [
            "sales tax",
            "tax rate",
            "taxed",
            "sales taxes",
            "taxable value",
            "cent sales",
            "sales tax collections",
            "levy limit",
            "taxable values",
            "sales tax receipts"
        ],
        "name": "Local taxation",
        "query_id": "local-taxation"
    },
    {
        "id": 78,
        "keyword_stats": [
            "acres",
            "acre",
            "acreage",
            "units per acre",
            "per acre",
            "acre parcel",
            "dwelling units per acre",
            "quarter acre",
            "hectares",
            "acreages"
        ],
        "name": "Land use",
        "query_id": "land-use"
    },
    {
        "id": 79,
        "keyword_stats": [
            "auditing standards",
            "external audit",
            "accepted accounting principles",
            "Independent Auditor",
            "External auditors",
            "external auditor",
            "auditing firm",
            "accounting principles",
            "generally accepted auditing",
            "independent auditors"
        ],
        "name": "Financial audit",
        "query_id": "financial-audit"
    },
    {
        "id": 82,
        "keyword_stats": [
            "title search",
            "devalued",
            "adverse possession",
            "sight unseen",
            "devalues",
            "designated redeveloper",
            "bundle of rights",
            "lean holders",
            "licensed broker",
            "delisting"
        ],
        "name": "Property ownership",
        "query_id": "property-ownership"
    },
    {
        "id": 20,
        "keyword_stats": [
            "diagnosed with breast cancer",
            "nursing shortage",
            "oncologist",
            "cardiologists",
            "cancer deaths",
            "diagnosed with prostate cancer",
            "melanoma",
            "oncologists",
            "outpatient clinic",
            "clinic visits"
        ],
        "name": "Healthcare issues",
        "query_id": "healthcare-issues"
    },
    {
        "id": 105,
        "keyword_stats": [
            "foot setback",
            "maximum height",
            "yard setback",
            "rear yard setback",
            "height limit",
            "yard setbacks",
            "ft tall",
            "rear elevation",
            "height restriction",
            "sidey yard setback"
        ],
        "name": "Building setbacks",
        "query_id": "building-setbacks"
    },
    {
        "id": 46,
        "keyword_stats": [
            "courtrooms",
            "deny the appeal",
            "jury trial",
            "defense attorney",
            "jury trials",
            "juries",
            "specialty courts",
            "denying the appeal",
            "judge and jury",
            "circuit courts"
        ],
        "name": "Legal matters",
        "query_id": "legal-matters"
    },
    {
        "id": 66,
        "keyword_stats": [
            "sergeants",
            "sworn officers",
            "patrol officer",
            "oriented policing",
            "uniformed officers",
            "proactive policing",
            "patrol sergeant",
            "Officers Memorial",
            "sergeants lieutenants",
            "patrol deputies"
        ],
        "name": "Police matters",
        "query_id": "police-matters"
    },
    {
        "id": 65,
        "keyword_stats": [
            "mass shootings",
            "gun laws",
            "gun deaths",
            "ghost guns",
            "gun ownership",
            "shooting incidents",
            "gun homicides",
            "Americans are killed",
            "gun buyback",
            "gun homicide"
        ],
        "name": "Gun violence",
        "query_id": "gun-violence"
    },
    {
        "id": 52,
        "keyword_stats": [
            "daycare",
            "daycares",
            "daycare center",
            "childcare",
            "child care providers",
            "child care centers",
            "daycare centers",
            "child abuse and neglect",
            "adult daycare",
            "parental involvement"
        ],
        "name": "Children, youth, childcare",
        "query_id": "children-youth-childcare"
    },
    {
        "id": 30,
        "keyword_stats": [
            "playground",
            "playgrounds",
            "playground equipment",
            "inclusive playground",
            "park rangers",
            "Park Ranger",
            "swing sets",
            "playground replacements",
            "ecological beauty",
            "essential and adaptable"
        ],
        "name": "Parks and Recreation",
        "query_id": "parks-and-recreation"
    },
    {
        "id": 24,
        "keyword_stats": [
            "Pickleball players",
            "playing pickleball",
            "tennis players",
            "baseball teams",
            "fastest growing sport",
            "adult softball",
            "pickleball player",
            "played pickleball",
            "softball teams",
            "played tennis"
        ],
        "name": "Sports facilities",
        "query_id": "sports-facilities"
    },
    {
        "id": 14,
        "keyword_stats": [
            "liquor licenses",
            "sell alcohol",
            "selling alcohol",
            "alcohol licenses",
            "liquor sales",
            "liquor license renewal",
            "malt beverages",
            "liquor establishments",
            "selling liquor",
            "sale of alcoholic beverages"
        ],
        "name": "Alcohol licensing",
        "query_id": "alcohol-licensing"
    },
    {
        "id": 121,
        "keyword_stats": [
            "million3",
            "500s",
            "400s",
            "250s",
            "113 113",
            "230 230",
            "900s",
            "170 170",
            "130 130",
            "190 190"
        ],
        "name": "Budget discussions",
        "query_id": "budget-discussions"
    },
    {
        "id": 25,
        "keyword_stats": [
            "dashboard",
            "dashboards",
            "raw data",
            "key performance indicators",
            "data analytics",
            "qualitative data",
            "quantitative data",
            "disaggregated data",
            "performance metric",
            "datadriven decisions"
        ],
        "name": "Data analysis",
        "query_id": "data-analysis"
    },
    {
        "id": 36,
        "keyword_stats": [
            "renewals",
            "option to renew",
            "mutual agreement",
            "automatic renewal",
            "renegotiation",
            "tentative agreement",
            "automatically renew",
            "automatically renews",
            "tenative agreement",
            "bargaining agreements"
        ],
        "name": "Contract negotiations",
        "query_id": "contract-negotiations"
    },
    {
        "id": 43,
        "keyword_stats": [
            "applicants",
            "applicant",
            "accepting applications",
            "recruitments",
            "qualified applicants",
            "recruitment efforts",
            "recruiter",
            "recruiters",
            "minimum qualifications",
            "appearing on behalf"
        ],
        "name": "Job applications",
        "query_id": "job-applications"
    },
    {
        "id": 59,
        "keyword_stats": [
            "sidewalks on both sides",
            "asphalt plant",
            "asphalt overlay",
            "asphalt plants",
            "inches of asphalt",
            "layer of asphalt",
            "circular driveway",
            "sidewalker",
            "asphalts",
            "asphalt resurfacing"
        ],
        "name": "Sidewalk infrastructure",
        "query_id": "sidewalk-infrastructure"
    },
    {
        "id": 97,
        "keyword_stats": [
            "septic",
            "septic system",
            "septic systems",
            "septic tank",
            "septic tanks",
            "septics",
            "gravity sewer",
            "sewer mains",
            "sewer lateral",
            "septic field"
        ],
        "name": "Sewer infrastructure",
        "query_id": "sewer-infrastructure"
    },
    {
        "id": 51,
        "keyword_stats": [
            "declining enrollment",
            "enrollment decline",
            "teacher ratios",
            "differentiated instruction",
            "advanced coursework",
            "dual enrollment courses",
            "kindergarten enrollment",
            "chronically absent students",
            "district tuitions",
            "highquality instructional materials"
        ],
        "name": "Education, schools, students",
        "query_id": "education-schools-students"
    },
    {
        "id": 54,
        "keyword_stats": [
            "signage",
            "freestanding sign",
            "directional signage",
            "directional signs",
            "wayfinding signage",
            "digital signage",
            "signages",
            "freestanding signs",
            "Wayfinding signs",
            "pylon sign"
        ],
        "name": "Signage regulations",
        "query_id": "signage-regulations"
    },
    {
        "id": 77,
        "keyword_stats": [
            "rezoning",
            "rezonings",
            "zonings",
            "zoning classification",
            "conditional zoning",
            "zoning designations",
            "conditional rezoning",
            "exclusionary zoning",
            "substantial impairment",
            "proposal conforms"
        ],
        "name": "Zoning regulations",
        "query_id": "zoning-regulations"
    },
    {
        "id": 56,
        "keyword_stats": [
            "downtown",
            "downtown core",
            "downtowns",
            "revitalize",
            "Downtown Revitalization",
            "downtown merchants",
            "revitalizing downtown",
            "downtown East Side",
            "downtown cores",
            "downtowners"
        ],
        "name": "Downtown development",
        "query_id": "downtown-development"
    },
    {
        "id": 23,
        "keyword_stats": [
            "ambulance",
            "ambulances",
            "paramedics",
            "ambulance transport",
            "Emergency Medical Technicians",
            "dramatically improves",
            "ambulance billing",
            "survival and recovery",
            "sudden illness or injury",
            "Emts and paramedics"
        ],
        "name": "Emergency Medical Services",
        "query_id": "emergency-medical-services"
    },
    {
        "id": 95,
        "keyword_stats": [
            "invoices",
            "reimbursed",
            "invoice",
            "minimum wage",
            "salary increases",
            "salary range",
            "wage increases",
            "hourly wage",
            "salary ranges",
            "invoiced"
        ],
        "name": "Salary and compensation",
        "query_id": "salary-and-compensation"
    },
    {
        "id": 18,
        "keyword_stats": [
            "opioid settlement funds",
            "opioid overdose",
            "opioid overdoses",
            "opioid settlements",
            "methadone",
            "fatal overdoses",
            "fentanyl overdose",
            "fentanyl overdoses",
            "opioid treatment",
            "opioid opioid"
        ],
        "name": "Drugs",
        "query_id": "drugs"
    },
    {
        "id": 73,
        "keyword_stats": [
            "eviction notice",
            "lease expires",
            "cause eviction",
            "eviction notices",
            "terminate the lease",
            "eviction court",
            "Eviction Protections",
            "term rentals",
            "eviction filings",
            "5year lease"
        ],
        "name": "Tenant issues",
        "query_id": "tenant-issues"
    },
    {
        "id": 21,
        "keyword_stats": [
            "Health Insurance",
            "insurance company",
            "insurance companies",
            "liability insurance",
            "insured",
            "self insured",
            "insurances",
            "insurance coverage",
            "insurance premiums",
            "uninsured"
        ],
        "name": "Insurance discussions",
        "query_id": "insurance-discussions"
    },
    {
        "id": 85,
        "keyword_stats": [
            "pray",
            "pray Father",
            "Pray Lord",
            "pray God",
            "Jesus name we pray",
            "pray Heavenly Father",
            "Lord I pray",
            "pray for peace",
            "pray dear",
            "pray for wisdom"
        ],
        "name": "Religion, prayer, church",
        "query_id": "religion-prayer-church"
    },
    {
        "id": 68,
        "keyword_stats": [
            "permit",
            "permits",
            "conditional use permit",
            "conditional use permits",
            "permit holder",
            "issuing permits",
            "permit holders",
            "encroachment permit",
            "Ms4 permit",
            "revocable permit"
        ],
        "name": "Permit processes",
        "query_id": "permit-processes"
    },
    {
        "id": 96,
        "keyword_stats": [
            "debts",
            "refinance",
            "credit rating",
            "debt payments",
            "debt issuance",
            "debt servicing",
            "refinanced",
            "issuing debt",
            "debt repayment",
            "debt issuances"
        ],
        "name": "Debt management",
        "query_id": "debt-management"
    },
    {
        "id": 16,
        "keyword_stats": [
            "cannabis products",
            "cannabis retailers",
            "smoke marijuana",
            "cannabis shops",
            "cannabis operators",
            "legalization of cannabis",
            "flavored tobacco products",
            "legalized cannabis",
            "consume cannabis",
            "tobacco smoke"
        ],
        "name": "Cannabis regulation",
        "query_id": "cannabis-regulation"
    },
    {
        "id": 48,
        "keyword_stats": [
            "color scheme",
            "color coding",
            "yellows",
            "paint colors",
            "wearing orange",
            "color blind",
            "navy blue",
            "symbolizes the value of human",
            "hunters wear orange",
            "classmates to commemorate"
        ],
        "name": "Color references",
        "query_id": "color-references"
    },
    {
        "id": 12,
        "keyword_stats": [
            "Bond issuance",
            "bond proceeds",
            "obligation bonds",
            "issuing bonds",
            "bonding capacity",
            "issuance of bonds",
            "bond holders",
            "bond anticipation",
            "bond ratings",
            "bond issuances"
        ],
        "name": "Municipal bonds",
        "query_id": "municipal-bonds"
    },
    {
        "id": 34,
        "keyword_stats": [
            "observed with the planting",
            "enhance the economic vitality",
            "fires and countless",
            "source of joy and spiritual",
            "moderate the temperature",
            "precious topsoil by wind",
            "air produce oxygen",
            "elm trees",
            "plant trees to gladden",
            "moderating the temperature cleaning"
        ],
        "name": "Tree management",
        "query_id": "tree-management"
    },
    {
        "id": 89,
        "keyword_stats": [
            "recycling bin",
            "recycle bin",
            "recycle bins",
            "recycling carts",
            "aluminum cans",
            "recycling cans",
            "tons of garbage",
            "manually sorted",
            "curbside Waste",
            "compostables"
        ],
        "name": "Waste management",
        "query_id": "waste-management"
    },
    {
        "id": 15,
        "keyword_stats": [
            "swim lessons",
            "swimming lessons",
            "outdoor pool",
            "Swim Team",
            "swimmers",
            "indoor pool",
            "swim teams",
            "swim lesson",
            "swims",
            "swim meets"
        ],
        "name": "Swimming pools",
        "query_id": "swimming-pools"
    },
    {
        "id": 3,
        "keyword_stats": [
            "chain link fence",
            "privacy fence",
            "wood fence",
            "wooden fence",
            "vinyl fence",
            "picket fence",
            "chain link fencing",
            "opaque fence",
            "privacy fences",
            "iron fence"
        ],
        "name": "Fencing issues",
        "query_id": "fencing-issues"
    },
    {
        "id": 50,
        "keyword_stats": [
            "certification",
            "certifications",
            "apprenticeship",
            "apprenticeship program",
            "Apprenticeship programs",
            "apprenticeships",
            "train the trainer",
            "pre apprenticeship",
            "registered apprenticeship",
            "self certification"
        ],
        "name": "Training and certification",
        "query_id": "training-and-certification"
    },
    {
        "id": 2,
        "keyword_stats": [
            "airports",
            "flights",
            "airlines",
            "airplanes",
            "airline",
            "general aviation",
            "aviation industry",
            "airline industry",
            "non aeronautical",
            "air carrier"
        ],
        "name": "Airport operations",
        "query_id": "airport-operations"
    },
    {
        "id": 17,
        "keyword_stats": [
            "cyber security",
            "Stay safe",
            "ransomware",
            "cyber attacks",
            "cyber attack",
            "safety precautions",
            "hackers",
            "cyber threats",
            "inherent risk",
            "cyber security insurance"
        ],
        "name": "Safety and security",
        "query_id": "safety-and-security"
    },
    {
        "id": 7,
        "keyword_stats": [
            "languages",
            "bilingual",
            "interpreter",
            "interpreters",
            "translation services",
            "translator",
            "multiple languages",
            "translators",
            "translations",
            "Spanish translation"
        ],
        "name": "Language matters",
        "query_id": "language-matters"
    },
    {
        "id": 40,
        "keyword_stats": [
            "nonprofit",
            "nonprofits",
            "fundraiser",
            "501c3",
            "fundraisers",
            "501c3 nonprofit",
            "profit organization",
            "501c",
            "501 C",
            "501 C3s"
        ],
        "name": "Non-profit fundraising",
        "query_id": "non-profit-fundraising"
    },
    {
        "id": 22,
        "keyword_stats": [
            "illegal fireworks",
            "volunteer firefighters",
            "volunteer firefighter",
            "consumer fireworks",
            "fireworks displays",
            "shoot fireworks",
            "aerial fireworks",
            "volunteer fireman",
            "dedicated to reducing the occurrence",
            "cooking fires"
        ],
        "name": "Fire department",
        "query_id": "fire-department"
    },
    {
        "id": 58,
        "keyword_stats": [
            "pickup trucks",
            "Brush truck",
            "truck loads",
            "single axle",
            "trucking industry",
            "car dealers",
            "truck chassis",
            "lad truck",
            "tandem axle",
            "cab and chassis"
        ],
        "name": "Vehicles and transportation",
        "query_id": "vehicles-and-transportation"
    },
    {
        "id": 110,
        "keyword_stats": [
            "Gallons",
            "million gallons",
            "gallons per",
            "thousand gallons",
            "per gallon",
            "gallons per minute",
            "billion gallons",
            "th000 gallons",
            "conserving water",
            "cents a gallon"
        ],
        "name": "Water management",
        "query_id": "water-management"
    },
    {
        "id": 8,
        "keyword_stats": [
            "anti black racism",
            "black folk",
            "anti black",
            "racists",
            "countless recorded and unrecorded",
            "own rights of suffrage",
            "anti blackness",
            "systematic racism",
            "institutionalized racism",
            "racial inequity"
        ],
        "name": "Racial issues",
        "query_id": "racial-issues"
    },
    {
        "id": 55,
        "keyword_stats": [
            "buses",
            "bus drivers",
            "bus routes",
            "bus driver",
            "bus route",
            "electric buses",
            "bus rapid",
            "riding the bus",
            "bus riders",
            "bus operators"
        ],
        "name": "Public transit",
        "query_id": "public-transit"
    },
    {
        "id": 109,
        "keyword_stats": [
            "inches of rain",
            "100e storm",
            "flood risks",
            "ines of rain",
            "25e storm",
            "flash flood",
            "Fema floodplain",
            "100e storms",
            "tropical storms",
            "floodplain maps"
        ],
        "name": "Stormwater management",
        "query_id": "stormwater-management"
    },
    {
        "id": 91,
        "keyword_stats": [
            "Greenhouse Gas inventory",
            "inches of snow",
            "Paris Agreement",
            "greenhouse gas inventories",
            "Co2 equivalent",
            "greenhouse emissions",
            "metric tons of carbon dioxide",
            "ines of snow",
            "carbon dioxide emissions",
            "greenhouse gas emissions reductions"
        ],
        "name": "Climate sustainability",
        "query_id": "climate-sustainability"
    },
    {
        "id": 90,
        "keyword_stats": [
            "charging stations",
            "battery storage",
            "solar farms",
            "fast chargers",
            "electric vehicle chargers",
            "battery energy",
            "Lithium batteries",
            "Fast Charger",
            "charging ports",
            "megawatts of solar"
        ],
        "name": "Renewable energy",
        "query_id": "renewable-energy"
    },
    {
        "id": 94,
        "keyword_stats": [
            "kayaks",
            "boaters",
            "kayakers",
            "beach renourishment",
            "pound sand",
            "sand dunes",
            "cubic yards of sand",
            "pontoon boat",
            "recreational boating",
            "naval forces"
        ],
        "name": "Boating and beaches",
        "query_id": "boating-and-beaches"
    },
    {
        "id": 106,
        "keyword_stats": [
            "flat roof",
            "metal roof",
            "flat roofs",
            "roofer",
            "roofing material",
            "hip roof",
            "metal roofing",
            "slate roof",
            "metal roofs",
            "shingle roof"
        ],
        "name": "Roof, stairs, siding",
        "query_id": "roof-stairs-siding"
    },
    {
        "id": 0,
        "keyword_stats": [
            "annexation",
            "annexed",
            "annex",
            "annexations",
            "annexing",
            "voluntary annexation",
            "Annexation petition",
            "annexes",
            "initiated annexation",
            "newly annexed"
        ],
        "name": "Annexation process",
        "query_id": "annexation-process"
    }
];
export const issueKeywords = [
    "homelessness",
    "affordable housing",
    "public safety",
    "redistricting",
    "transparency",
    "climate change",
    "gun violence",
    "transportation",
    "sustainability",
    "mental health",
    "marijuana",
    "rent control",
    "diversity",
    "bargaining",
    "short term rentals",
    "conflict of interest",
    "negotiations",
    "education",
    "environmental justice",
    "contention",
    "development",
    "leaf blowers",
    "storm water",
    "infrastructure",
    "law enforcement",
    "sidewalks",
    "discrimination",
    "reparations",
    "workforce housing",
    "inclusion",
    "human trafficking",
    "racial equity",
    "water quality",
    "redevelopment",
    "code enforcement",
    "term limits",
    "fireworks",
    "health care",
    "environment",
    "litigation",
    "spot zoning",
    "short term rental",
    "food insecurity",
    "stormwater",
    "historic preservation",
    "annexation",
    "gun control",
    "election integrity",
    "ranked choice voting",
    "equity and inclusion",
    "housing affordability",
    "aesthetics",
    "multi family",
    "water conservation",
    "insurance",
    "recreation",
    "local control",
    "recycling",
    "public engagement",
    "hybrid meetings",
    "pickleball",
    "heritage trees",
    "climate action",
    "landscaping",
    "partisanship",
    "special use permits",
    "censorship",
    "traffic congestion",
    "plastic bags",
    "preservation",
    "engagement",
    "representation",
    "accountability",
    "septic systems",
    "inflation",
    "accessibility",
    "human rights",
    "fire safety",
    "preemption",
    "drop boxes",
    "land use",
    "cash flow",
    "addiction",
    "virtual meetings",
    "regulation",
    "hazard pay",
    "vaccination",
    "corruption",
    "impact fees",
    "nuisance animals",
    "special assessments",
    "child abuse",
    "panhandling",
    "equal opportunity",
    "cross contamination",
    "tenant protections",
    "stormwater management",
    "pedestrian safety",
    "broadband access",
    "bicycle and pedestrian safety",
    "overcrowding",
    "hazardous waste",
    "sea level rise",
    "mitigation"
];
export const numCities = 626;
export const featuredTopics = [
    {
        "date": "2024-02-09_2",
        "query_id": "black-history-month",
        "title": "black history month"
    },
    {
        "date": "2024-02-26",
        "query_id": "opioid-settlements",
        "title": "opioid settlements"
    },
    {
        "date": "2024-03-11",
        "query_id": "sidewalks",
        "title": "sidewalks"
    },
    {
        "date": "2024-03-25_2",
        "query_id": "absenteeism",
        "title": "absenteeism"
    },
    {
        "date": "all",
        "query_id": "all",
        "title": "all"
    },
    {
        "date": "yiu_on_test",
        "query_id": "chronic-absenteeism-districtview",
        "title": "chronic absenteeism districtview"
    },
    {
        "date": "2024-04-08",
        "query_id": "bus-rapid-transit",
        "title": "bus rapid transit"
    },
    {
        "date": "nsba",
        "query_id": "nsba",
        "title": "nsba"
    },
    {
        "date": "rezoning_test",
        "query_id": "rezoning",
        "title": "rezoning"
    },
    {
        "date": "2024-04-22",
        "query_id": "harm-reduction",
        "title": "harm reduction"
    },
    {
        "date": "2024-05-06",
        "query_id": "pickleball",
        "title": "pickleball"
    },
    {
        "date": "2024-05-20",
        "query_id": "canada",
        "title": "canada"
    },
    {
        "date": "2024-06-03",
        "query_id": "cycling",
        "title": "cycling"
    },
    {
        "date": "2024-06-17",
        "query_id": "climate-change",
        "title": "climate change"
    },
    {
        "date": "2024-07-15",
        "query_id": "immigration",
        "title": "immigration"
    },
    {
        "date": "ic2s2",
        "query_id": "fentanyl-test-strips",
        "title": "fentanyl test strips"
    },
    {
        "date": "2024-07-29",
        "query_id": "short-term-rentals",
        "title": "short-term-rentals"
    },
    {
        "date": "2024-08-12",
        "query_id": "homelessness",
        "title": "homelessness"
    },
    {
        "date": "2024-08-26",
        "query_id": "accessory-dwelling-units",
        "title": "accessory dwelling units"
    },
    {
        "date": "2024-09-09",
        "query_id": "rent-control",
        "title": "rent control"
    },
    {
        "date": "2024-09-23",
        "query_id": "participatory-budgeting",
        "title": "participatory budgeting"
    },
    {
        "date": "2024-09-22",
        "query_id": "participatory-budget",
        "title": "participatory budget"
    },
    {
        "date": "2024-10-07",
        "query_id": "ranked-choice-voting",
        "title": "ranked choice voting"
    },
    {
        "date": "2024-10-17",
        "query_id": "homelessness",
        "title": "homelessness"
    },
    {
        "date": "2024-10-21",
        "query_id": "voter-turnout",
        "title": "voter turnout"
    },
    {
        "date": "research",
        "query_id": "research",
        "title": "research"
    },
    {
        "date": "2024-12-08",
        "query_id": "fluoride",
        "title": "fluoride"
    }
];