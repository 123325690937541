import React from "react";
import { Link } from "react-router-dom";
import { useRouteHandlerContext } from "../models/RouteHandler.js";

import AboutPage from "../pages/AboutPage/AboutPage.js";
import AllPlacesPage from "../pages/AllPlacesPage/AllPlacesPage.js";
import AllKeywordsPage from "../pages/AllKeywordsPage/AllKeywordsPage.js";
import AllTopicsPage from "../pages/AllTopicsPage/AllTopicsPage.js";
import AlertSubscribePage from "../pages/AlertSubscribePage/AlertSubscribePage.js";
import FeaturedTopicPage from "../pages/FeaturedTopicPage/FeaturedTopicPage.js";
import ErrorPage from "../pages/ErrorPage/ErrorPage.js";
import ResultsPageSelector from "../pages/ResultPage/ResultsPageSelector.js";

const Body = ({ mode }) => {
  const {
    topics,
    location,
    setLocation,
    keywords,
    startDate,
    endDate,
    featuredTopic,
  } = useRouteHandlerContext();

  const renderBody = () => {
    if (mode === "about") {
      return <AboutPage />;
    } else if (mode === "subscribe") {
      return <AlertSubscribePage keywords={keywords} />;
    } else if (mode === "featured" && featuredTopic) {
      return <FeaturedTopicPage featuredTopic={featuredTopic} />;
    } else if (mode === "list_places") {
      return <AllPlacesPage />;
    } else if (mode === "list_all_topics") {
      return <AllTopicsPage />;
    } else if (mode === "list_keywords") {
      return <AllKeywordsPage />;
    } else if (
      mode === "search" &&
      (!topics || topics.length == 0) &&
      !keywords &&
      !location
    ) {
      return <AboutPage />;
    } else if (
      mode === "search" ||
      mode === "show_results" ||
      mode === "list_topics"
    ) {
      return (
        <ResultsPageSelector
          topics={topics || []}
          keywords={keywords || ""}
          location={location}
          setLocation={setLocation}
          startDate={startDate}
          endDate={endDate}
        />
      );
    } else if (mode === "invalid_route") {
      return (
        <ErrorPage
          header={"Uh oh, this URL is not valid\u2026"}
          message={
            <>
              Make a search using the input fields above, or click{" "}
              <Link to="/">here</Link> to return to the home page.
            </>
          }
        />
      );
    }
    return <ErrorPage />;
  };

  return (
    <div>
      <div>{renderBody()}</div>
    </div>
  );
};

export default Body;
