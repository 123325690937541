import React from "react";
import styles from "./AboutPage.css";

const AboutPage = () => {
  return (
    <section id="about" className={styles.aboutSection}>
      <div className={styles.sectionContent}>
        <div className={styles.sectionOne}>
          <h1>About CivicSearch</h1>
          <p>
            <strong>“All politics are local,”</strong> the saying goes, and
            there’s literal truth to that phrase: state and local government
            expenditures in the USA total{" "}
            <a href="https://www.brookings.edu/articles/nine-facts-about-state-and-local-policy/">
              $2.9 trillion
            </a>
            , and two-thirds of federal dollars are transferred
            to local governments.
          </p>
          <p>
            This means that state and local governments
            have a bigger role than the federal in investment, infrastructure,
            education, and defining the long-term capacity of the entire
            economy. A similar breakdown of local versus federal control{" "}
            <a href="https://www.fraserinstitute.org/studies/government-spending-taxes">
              holds for Canada
            </a>
            .
          </p>
          <p>
            Given the primacy of local governments and the inordinate impact
            they have on the lives and livelihoods of citizens, local newspapers
            and media have served as guardians of democracy since the inception
            of mass printing. Beat reporters and newspaper editors informed and
            safeguarded their communities through attention to hyperlocal
            issues.
          </p>
          <p>
            But we, like many, have been dismayed by the{" "}
            <a href="https://www.axios.com/2023/11/16/newspapers-decline-hedge-funds-research">
              implosion of local media.
            </a>{" "}
            Without reporters following statehouses and local municipal boards,
            how will citizens, scholars, and local officials be able to know
            what’s going on?
          </p>
        </div>
        <div className={styles.sectionTwo}>
          <h2>A new window into the process</h2>
          <p>
            This is the crisis that inspired us to create{" "}
            <a href="https://civicsearch.org/">CivicSearch</a>, which aggregates
              transcripts of government meetings from towns and cities across the US and Canada.
	      You can think of CivicSearch as a searchable form of transparency for all
              local government.
          </p>
          <p>
              Enter a keyword into the search field to see what
              communities are saying and doing about it. Filter
              your results by location, city, or time period to dig deeper.
	      Explore our <a href="https://civicsearch.org/topics">topic pages</a> for
	      an up-to-date look at the trends within more than 70 significant
	      themes, or our <a href="https://civicsearch.org/cities">city pages</a> to
	      find a starting point for exploring a particular
	      city.  <a href="https://civicsearch.org/subscribe">Subscribe to our biweekly newsletters</a> or
	      to alerts on specific names and places that interest you.
          </p>
        </div>
        <div className={styles.sectionThree}>
          <h2>Who’s this for?</h2>
          <p>We see four audiences for CivicSearch:</p>
          <ul>
            <li>
              <strong>Citizens</strong> have a right to know the discussions and
              decisions local government is making in their name and on their
              dime.
            </li>
            <li>
              <strong>Policymakers</strong> can see how nearby towns and
              cities are tackling similar problems and crises.
            </li>
            <li>
              <strong>Social scientists</strong> benefit from seeing and
              searching the meetings of city and town councils to observe the
              approaches being taken to solve urgent problems.
            </li>
            <li>
              <strong>Reporters</strong> will want to access raw transcripts to
              understand the decisions being made by local governments.
            </li>
          </ul>
          <p>
	      CivicSearch is a non-commercial project of <a href="https://www.datamuse.com">Datamuse</a>.
	      We collaborate with researchers at the <a href="https://www.pluralconnections.org/">Plural
	      Connections Group</a> at Northeastern University in Boston, Massachusetts.   We are grateful
	      to the <a href="https://localview.net/">LocalView</a> team, which compiled the research corpus on
	      which CivicSearch was initially based. You can send us feedback <a href="https://onelook.com/?c=about&sel=civicsearch#feedback">here</a>.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;
