import React, { useRef, useEffect, useState, Suspense } from "react";
import "../../Variables.css";
import styles from "./mentionsGraphDialog.css";
import { useDialog } from "../../../context/DialogContext.js";
import { fetchResults } from "../../../utilities/resultsHelper.js";
const LazyUsageGraph = React.lazy(() => import("../../widgets/UsageChart.js"));

const mentionsGraphDialog = ({ location, keywords, startDate, endDate }) => {
  const { openDialogs, closeDialog } = useDialog();
  const searchController = useRef();
  const dialogRef = useRef(null);
  const isOpen = openDialogs.includes("graphbox");

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      dialogRef.current.close();
      return;
    }

    if (dialogRef.current.showModal) {
      dialogRef.current.showModal();
    } else {
      console.log("The <dialog> API is not supported by this browser");
    }

    const fetchAndSetResults = async () => {
      const controller = new AbortController();
      searchController.current = controller;
      setLoading(true);

      try {
        const res = await fetchResults({
          topics: [],
          location,
          keywords,
          startDate,
          endDate,
          controller,
        });
        setResults(res);
      } catch (error) {
        if (error.name === "AbortError") return; // Handle aborts separately
        console.error("Error fetching results:", error);
      } finally {
        setLoading(false);
      }

      return () => controller.abort(); // Cleanup aborts on unmount
    };

    fetchAndSetResults();
  }, [isOpen, location, keywords, startDate, endDate]);

  const renderUsageGraph = () => {
    if (
      loading ||
      !results ||
      !results.matched_meeting_counts ||
      location != null ||
      Object.keys(results.matched_meeting_counts).length <= 1
    ) {
      return null;
    }
    return (
      <div className={styles.chartContainer}>
        <Suspense fallback={<div>Loading Chart...</div>}>
          <LazyUsageGraph
            keywords={keywords}
            selectedStartDate={startDate}
            selectedEndDate={endDate}
            numData={results.matched_meeting_counts}
            denData={results.meeting_counts}
          />
        </Suspense>
      </div>
    );
  };

  return (
    <dialog id="mentionsGraphDialog" className={styles.modal} ref={dialogRef}>
      <div className={styles.modalContentWrapper}>
        <div className={styles.modalCloseBtnWrapper}>
          <button
            id="ClosementionsGraphDialog"
            className={`${styles.modalCloseBtn} fa-solid fa-xmark`}
            title="Close"
            aria-label="close"
            onClick={() => closeDialog("graphbox")}
          ></button>
        </div>
        <div className={styles.modalContent}>
          <h2>
            Percentage of recent meetings that mention the term {keywords}
          </h2>
          {renderUsageGraph()}
          <h2>Click a bar to search that month</h2>
        </div>
      </div>
    </dialog>
  );
};

export default mentionsGraphDialog;
