import React, { useState, useRef, useCallback } from "react";
import { Location } from "../../../../../models/location.js";
import styles from "./HomeSearchLocationSelect.css";
import { useDialog } from "../../../../../context/DialogContext.js";
import { handleLocation } from "../../../../../utilities/locationUtils.js";
import Input from "../../../../../widgets/forms/Input.js";

const getLocationValue = async (val) => {
  let locationValue = await Location.fromLonLatStr(val);
  if (locationValue) return locationValue;

  locationValue = await Location.fromZipCodeStr(val);
  if (locationValue) return locationValue;

  locationValue = await Location.fromDisplayNameStr(val);
  return locationValue;
};

const HomeSearchLocationSelect = ({
  setValue,
  placeholder,
  toggleLocateMeNav,
  setCurrentLocationInput,
}) => {
  const rollbackInputValue = useRef();
  const inputRef = useRef();
  const { openDialog } = useDialog();

  const [inputValue, setInputValue] = useState("");

  const handleSetValue = useCallback(
    (newValue, fromLocateMe = false) => {
      const text = newValue === null ? "" : newValue.displayText;
      setValue(
        fromLocateMe ? { value: newValue, fromLocateMe: true } : newValue,
      );
      setInputValue(text);
      rollbackInputValue.current = text;
    },
    [setValue],
  );

  const handleOnLocateButtonClick = () => {
    handleLocation((value) => handleSetValue(value, true), toggleLocateMeNav);
  };

  const handleOnChange = async (event) => {
    const val = event.target.value;
    setInputValue(val);

    if (val.length === 5 || val.length === 9) {
      const locationValue = await getLocationValue(val);
      setCurrentLocationInput(locationValue || val);
    } else {
      setCurrentLocationInput(null);
    }
  };

  const handleOnKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue === "") {
        handleSetValue(null);
      } else {
        const value = await getLocationValue(inputValue);
        handleSetValue(value);
      }
    }
  };

  const handleClearInput = () => {
    handleSetValue(null);
    setCurrentLocationInput("");
  };

  return (
    <>
      <Input
        className={styles.locationInput}
        inputRef={inputRef}
        type="text"
        value={inputValue}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        placeholder={placeholder}
      />
      {inputValue !== "" && (
        <button
          className={`${styles.inputClearButton} fa-solid fa-xmark`}
          title="Clear Search"
          aria-label="Clear Search"
          onClick={handleClearInput}
        ></button>
      )}
      <a
        href="#/"
        id="locate-btn"
        className={`${styles.locateButton} fa-solid fa-location-crosshairs`}
        title="Locate Me"
        aria-label="Locate Me"
        role="button"
        onMouseDown={(e) => e.preventDefault()}
        onClick={handleOnLocateButtonClick}
      ></a>
      <a
        href="#/"
        id="openMapboxDialogButton"
        className={`${styles.mapButton} fa-solid fa-map-location-dot`}
        title="View Map"
        aria-label="View map"
        role="button"
        onClick={() => openDialog("mapbox")}
      ></a>
    </>
  );
};

export default HomeSearchLocationSelect;
