import { topicData } from "../compiledFromBe.mjs";

const getFromTopicData = (key, val) => {
  const filtered = topicData.filter((item) => item[key] === val);
  if (filtered.length === 0) {
    return null;
  }
  return filtered[0];
};

class Topic {
  static fromId(id) {
    let topic;
    if ((topic = getFromTopicData("id", id)) !== null) {
      return new Topic(
        topic.id,
        topic.name,
        topic.query_id,
        topic.keyword_stats,
      );
    }
    return null;
  }

  static fromIdStr(str) {
    const id = Number(str);
    if (isNaN(str) || str === "") {
      // since Number("") !== NaN
      return null;
    }
    return this.fromId(id);
  }

  static fromQueryIdStr(str) {
    let topic;
    if ((topic = getFromTopicData("query_id", str)) !== null) {
      return new Topic(
        topic.id,
        topic.name,
        topic.query_id,
        topic.keyword_stats,
      );
    }
    return null;
  }

  constructor(id, displayText, queryText, keyword_stats) {
    this.id = id;
    this.displayText = displayText;
    this.queryText = queryText;
    this.keyword_stats = keyword_stats;
  }

  // used for MultiSelect
  get name() {
    return this.displayText;
  }
}

const getMasterTopicList = () => {
  let ret = topicData.map(
    (t) => new Topic(t.id, t.name, t.query_id, t.keyword_stats),
  );
  ret = ret.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    return (nameA > nameB) - (nameB > nameA);
  });
  return ret;
};

const masterTopicList = getMasterTopicList();

export { Topic, masterTopicList };
