// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../imgs/ic-arrow-drop-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iOB6yOEyJ7XefPjrumCs {
  background-color: var(--dk-grey-color);
  border-radius: 10px;
  margin: 1rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  flex-direction: column;
}

.iOB6yOEyJ7XefPjrumCs p {
  color: var(--white-color);
  font-size: var(--step-1);
}

.Q4T6vD9V_ZxxmwglnU9p {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.OpOWYk6fLqrfNUSrJd5f {
  font-weight: bolder;
}

.xe8QFXwyTvrc6WV61zJN {
  border-radius: 10px;
  width: 300px;
  height: 40px;
  border: none;
  text-align: left;
  padding-left: 0.5rem;
  padding-right: 1rem;
  max-width: 12rem;
  font-size: var(--step--2);
  appearance: none; /* Hide the default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: right center;
}

.eC1NR_2zvb8viKDUlJ5r {
  width: 95%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/CityMapSection.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,mBAAmB;EACnB,YAAY;EACZ,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gCAAgC;EAChC,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,yBAAyB;EACzB,gBAAgB,EAAE,2BAA2B;EAC7C,wBAAwB,EAAE,eAAe;EACzC,qBAAqB,EAAE,gBAAgB;EACvC,yDAA2D;EAC3D,4BAA4B;EAC5B,iCAAiC;AACnC;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".cityMapSection {\n  background-color: var(--dk-grey-color);\n  border-radius: 10px;\n  margin: 1rem;\n  padding-bottom: 2rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.3s ease-in-out;\n  flex-direction: column;\n}\n\n.cityMapSection p {\n  color: var(--white-color);\n  font-size: var(--step-1);\n}\n\n.mapTitleRow {\n  width: 94%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.locationText {\n  font-weight: bolder;\n}\n\n.dateSelect {\n  border-radius: 10px;\n  width: 300px;\n  height: 40px;\n  border: none;\n  text-align: left;\n  padding-left: 0.5rem;\n  padding-right: 1rem;\n  max-width: 12rem;\n  font-size: var(--step--2);\n  appearance: none; /* Hide the default arrow */\n  -webkit-appearance: none; /* For Safari */\n  -moz-appearance: none; /* For Firefox */\n  background-image: url(../../../imgs/ic-arrow-drop-down.svg);\n  background-repeat: no-repeat;\n  background-position: right center;\n}\n\n.cityMapWrapper {\n  width: 95%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cityMapSection": `iOB6yOEyJ7XefPjrumCs`,
	"mapTitleRow": `Q4T6vD9V_ZxxmwglnU9p`,
	"locationText": `OpOWYk6fLqrfNUSrJd5f`,
	"dateSelect": `xe8QFXwyTvrc6WV61zJN`,
	"cityMapWrapper": `eC1NR_2zvb8viKDUlJ5r`
};
export default ___CSS_LOADER_EXPORT___;
