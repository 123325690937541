// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Agf27WrlemB_uGSc7pbi {
  height: 180px;
}

.z8WVf3WWmn9_V_zlz7rg {
  height: 400px;
}

.z8WVf3WWmn9_V_zlz7rg > div,
.Agf27WrlemB_uGSc7pbi > div {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.K3jemi3tmJPz3POGY9Hj {
  border: 2px solid black;
  background: #e7e9e9;
  margin: 5px 5px 0px 5px;
  padding: 10px;
  border-radius: 5px;
}

.sN3UVipTousP1oJt27ja {
  border: 2px solid black;
  background: #e7e9e9;
  margin: 5px 5px 0px 5px;
  padding: 10px;
  border-radius: 5px;
  width: 320px;
  height: 100px;
}

.P2yeJu3CFqwOfO7RHKpP {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 330px;
  height: 110px;
}

.dI2exb5dbR0uU6J2dwGI {
  /* text-overflow: ellipsis; */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/Map.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,aAAa;EACb,4BAA4B;AAC9B","sourcesContent":[".miniMapContainer {\n  height: 180px;\n}\n\n.mapContainer {\n  height: 400px;\n}\n\n.mapContainer > div,\n.miniMapContainer > div {\n  width: 100%;\n  height: 100%;\n  border-radius: 10px;\n}\n\n.cityMarkerPopup {\n  border: 2px solid black;\n  background: #e7e9e9;\n  margin: 5px 5px 0px 5px;\n  padding: 10px;\n  border-radius: 5px;\n}\n\n.poiMarkerPopup {\n  border: 2px solid black;\n  background: #e7e9e9;\n  margin: 5px 5px 0px 5px;\n  padding: 10px;\n  border-radius: 5px;\n  width: 320px;\n  height: 100px;\n}\n\n.poiMarkerPopupWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 330px;\n  height: 110px;\n}\n\n.lastMention {\n  /* text-overflow: ellipsis; */\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-line-clamp: 4;\n  line-clamp: 4;\n  -webkit-box-orient: vertical;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"miniMapContainer": `Agf27WrlemB_uGSc7pbi`,
	"mapContainer": `z8WVf3WWmn9_V_zlz7rg`,
	"cityMarkerPopup": `K3jemi3tmJPz3POGY9Hj`,
	"poiMarkerPopup": `sN3UVipTousP1oJt27ja`,
	"poiMarkerPopupWrapper": `P2yeJu3CFqwOfO7RHKpP`,
	"lastMention": `dI2exb5dbR0uU6J2dwGI`
};
export default ___CSS_LOADER_EXPORT___;
