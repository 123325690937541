// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oqFUnfKEHKRaVrsu9gkC {
  flex-direction: column;
  min-height: calc(30vh - var(--results-nav-height));
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.oqFUnfKEHKRaVrsu9gkC h2 {
  font-size: var(--step-1);
}

.rDyZ2lLcb4bl0YKvoHxw {
  color: var(--topic-color);
}

.gq2XWgByChmu6Kg1kBKZ {
  color: var(--keyword-color);
}

.hGrGkqay1MARuMxaW8lC {
  color: white;
  background-color: hsla(222, 18%, 19%, 0.721);
}
`, "",{"version":3,"sources":["webpack://./src/views/ResultsSummarySection.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kDAAkD;EAClD,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;EAChC,qBAAqB,EAAE,gBAAgB;EACvC,wBAAwB,EAAE,mCAAmC;AAC/D;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,4CAA4C;AAC9C","sourcesContent":[".resultsSummary {\n  flex-direction: column;\n  min-height: calc(30vh - var(--results-nav-height));\n  padding: 1.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: all 0.3s ease-in-out;\n  scrollbar-width: none; /* For Firefox */\n  -ms-overflow-style: none; /* For Internet Explorer and Edge */\n}\n\n.resultsSummary h2 {\n  font-size: var(--step-1);\n}\n\n.displayTextTopic {\n  color: var(--topic-color);\n}\n\n.displayTextKeyword {\n  color: var(--keyword-color);\n}\n\n.customAccordionExpand {\n  color: white;\n  background-color: hsla(222, 18%, 19%, 0.721);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultsSummary": `oqFUnfKEHKRaVrsu9gkC`,
	"displayTextTopic": `rDyZ2lLcb4bl0YKvoHxw`,
	"displayTextKeyword": `gq2XWgByChmu6Kg1kBKZ`,
	"customAccordionExpand": `hGrGkqay1MARuMxaW8lC`
};
export default ___CSS_LOADER_EXPORT___;
