// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ylr92EJRjuBgZphPRAoP {
  position: absolute;
  margin-top: 10px;
  font-size: 0.8em;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  background: white;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 10px;
  min-width: 200px;
  background-color: hsl(229, 79%, 98%);
}

._uiN8giXTEzDfBW7saqA {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/SuggestionBox.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".container {\n  position: absolute;\n  margin-top: 10px;\n  font-size: 0.8em;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  white-space: nowrap;\n  background: white;\n  border: 1px solid grey;\n  border-radius: 8px;\n  padding: 10px;\n  min-width: 200px;\n  background-color: hsl(229, 79%, 98%);\n}\n\n.suggestion {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Ylr92EJRjuBgZphPRAoP`,
	"suggestion": `_uiN8giXTEzDfBW7saqA`
};
export default ___CSS_LOADER_EXPORT___;
