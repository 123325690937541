import React from "react";
import GlobalNav from "../layout/GlobalNavContent.js";
import Body from "../layout/Body.js";
import GlobalFooter from "../layout/GlobalFooterContent.js";
import styles from "../layout/App.css";

const Default = ({ mode }) => (
  <div className={styles.pageContainer}>
    <GlobalNav
      isSearchOpenByDefault={mode === "list_topics" || mode === "search"}
      isStickyByDefault
    />
    <Body mode={mode} />
    <GlobalFooter />
  </div>
);

export default Default;
