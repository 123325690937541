// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap\");"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Montserrat", "Arial", sans-serif;
  padding: 0;
  margin: 0;
  margin-left: 0px;
  height: 100vh;
  line-height: 1.4;
  color: #383838;
  overflow: auto;
}

button {
  cursor: pointer;
}

input,
button {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
}

button:disabled {
  color: lightgrey;
  cursor: default;
}

input::placeholder {
  color: darkgrey;
}

body.lI8otCX80ntfJu0U6w1h {
  overflow: hidden !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,8CAA8C;EAC9C,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,aAAa;EACb,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,kBAAkB;EAClB,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap\"');\n\nbody {\n  font-family: \"Montserrat\", \"Arial\", sans-serif;\n  padding: 0;\n  margin: 0;\n  margin-left: 0px;\n  height: 100vh;\n  line-height: 1.4;\n  color: #383838;\n  overflow: auto;\n}\n\nbutton {\n  cursor: pointer;\n}\n\ninput,\nbutton {\n  font-size: inherit;\n  font-family: inherit;\n  color: inherit;\n}\n\nbutton:disabled {\n  color: lightgrey;\n  cursor: default;\n}\n\ninput::placeholder {\n  color: darkgrey;\n}\n\nbody.no-scroll {\n  overflow: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-scroll": `lI8otCX80ntfJu0U6w1h`
};
export default ___CSS_LOADER_EXPORT___;
