import React, { useRef, useEffect, useState } from "react";
import "../../Variables.css";
import styles from "./mapboxDialog.css";
import { useDialog } from "../../../context/DialogContext.js";
import { PointsOfInterestMap } from "../../widgets/Map.js";
import { fetchResults } from "../../../utilities/resultsHelper.js";
import { useMapLocation } from "../../../context/LocationContext.js";
import { getUserLocation } from "../../../utilities/locationUtils.js";
import { Location } from "../../../models/location.js";

const MapBoxDialog = () => {
  const { openDialogs, closeDialog } = useDialog();
  const dialogRef = useRef(null);
  const isOpen = openDialogs.includes("mapbox");

  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const { mapLocation, setMapLocation } = useMapLocation();

  useEffect(() => {
    if (!isOpen) {
      dialogRef.current.close();
      return;
    }

    if (dialogRef.current.showModal) {
      dialogRef.current.showModal();
    } else {
      console.log("The <dialog> API is not supported by this browser");
    }

    const fetchAndSetResults = async () => {
      const controller = new AbortController();

      setLoading(true);
      try {
        if (!mapLocation || !mapLocation.queryText) {
          // Fetch the user's mapLocation if not already set
          const [lon, lat] = await getUserLocation();
          const newLocation = Location.fromLonLat(lon, lat);
          setMapLocation(newLocation);
        }

        const res = await fetchResults({
          topics: [],
          location: mapLocation,
          keywords: "",
          startDate: null,
          endDate: null,
          controller,
        });
        setResults(res);
      } catch (error) {
        console.error("Error fetching points of interest:", error);
      } finally {
        setLoading(false);
      }

      return () => controller.abort();
    };

    fetchAndSetResults();
  }, [isOpen, mapLocation, setMapLocation]);

  useEffect(() => {}, [results]);

  return (
    <dialog id="subscribeDialog" className={styles.modal} ref={dialogRef}>
      <div className={styles.modalContentWrapper}>
        <div className={styles.modalCloseBtnWrapper}>
          <button
            id="closeSubscribeDialog"
            className={`${styles.modalCloseBtn} fa-solid fa-xmark`}
            title="Close"
            aria-label="close"
            onClick={() => closeDialog("mapbox")}
          ></button>
        </div>
        <div className={styles.modalContent}>
          {results && mapLocation ? (
            <PointsOfInterestMap
              cityQueryId={mapLocation.queryText}
              pointsOfInterest={results.points_of_interest}
              centroidLongitude={mapLocation.lon}
              centroidLatitude={mapLocation.lat}
            />
          ) : (
            <div className={styles.container}>
              {loading ? (
                <i className="fa-solid fa-spinner fa-spin" />
              ) : results && results.error ? (
                <p>Error: {results.error}</p>
              ) : (
                <p>No results found</p>
              )}
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
};

export default MapBoxDialog;
