import React, { useState, useRef, useEffect } from "react";
import { useRouteHandlerContext } from "../../models/RouteHandler.js";
import SearchBarInput from "./components/SearchBarInput.js";
import LocationSelect from "./components/LocationSelect.js";
import SuggestionBox from "../SugesstionBox/SuggestionBox.js";
import { Topic } from "../../models/topic.js";

import styles from "./GlobalSearchBar.css";

const GlobalSearchBar = ({ onSearchComplete }) => {
  const { topics, setTopics, location, setLocation, keywords, setKeywords } =
    useRouteHandlerContext();

  const searchTimer = useRef();
  const searchBarDiv = useRef();

  const [localTopics, setLocalTopics] = useState(topics);
  const [localLocation, setLocalLocation] = useState(location);
  const [localKeywords, setLocalKeywords] = useState(keywords);
  const [suggestInput, setSuggestInput] = useState("");
  const [currentKeywordInput, setCurrentKeywordInput] = useState("");
  const [currentLocationInput, setCurrentLocationInput] = useState("");

  const isValidLocation = (loc) => {
    return (
      loc &&
      typeof loc === "object" &&
      "queryText" in loc &&
      typeof loc.queryText === "string"
    );
  };

  const handleSetLocation = (val) => {
    if (val && !isValidLocation(val)) {
      console.warn("Invalid location format:", val);
      return;
    }

    if (typeof val === "function") {
      val = val(localLocation);
    }

    setLocalLocation(val);
    setCurrentLocationInput(val);

    if (currentKeywordInput) {
      setKeywords(currentKeywordInput);
    }

    if (val === null || isValidLocation(val)) {
      setLocation(val);
    }
  };

  const handleSetKeywords = (val) => {
    if (typeof val === "function") {
      val = val(localKeywords);
    }

    setLocalKeywords(val);
    clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setTopics([]);
      if (isValidLocation(currentLocationInput)) {
        setLocation(currentLocationInput);
      }
      setKeywords(val);
      setSuggestInput("");
      onSearchComplete?.();
    }, 250);
  };

  useEffect(() => {
    setLocalTopics(topics);
    setLocalLocation(location);
    setLocalKeywords(keywords);
    setSuggestInput("");
  }, [topics, location, keywords]);

  const isLocationSelectEnabled = localTopics.length > 0 || localKeywords;

  return (
    <div ref={searchBarDiv}>
      <nav className={styles.container}>
        <SearchBarInput
          parent={searchBarDiv}
          currentInput={
            localKeywords
              ? localKeywords
              : Topic.fromId(localTopics[0])?.displayText
          }
          onKeywordChange={handleSetKeywords}
          fa="fa-solid fa-magnifying-glass fa-fw"
          color="cornflowerblue"
          placeholder="Word or phrase or city..."
          startWithFocus={true}
          flexGrow={4}
          setSuggestInput={setSuggestInput}
          setCurrentKeywordInput={setCurrentKeywordInput}
          onSearchComplete={onSearchComplete}
        />
        <div className={styles.divider} />
        <LocationSelect
          parent={searchBarDiv}
          onLocationChange={handleSetLocation}
          fa="fa-solid fa-location-dot fa-fw"
          color="coral"
          placeholder="ZIP, postal code, or city"
          flexGrow={4}
          showMap={false}
          setSuggestInput={setSuggestInput}
          onSearchComplete={onSearchComplete}
          setCurrentLocationInput={setCurrentLocationInput}
          isEnabled={isLocationSelectEnabled}
          currentLocation={!location ? "" : localLocation?.displayText}
        />
      </nav>
      <div className={styles.suggestionBoxWrapper}>
        <SuggestionBox
          inputLetters={suggestInput}
          currentLocation={localLocation}
          currentKeywords={currentKeywordInput}
          onClose={() => setSuggestInput("")}
        />
      </div>
    </div>
  );
};

export default GlobalSearchBar;
