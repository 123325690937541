import React, { useEffect, useState } from "react";
import { useRouteHandlerContext } from "../../../../models/RouteHandler.js";
import HomeSearchInput from "./components/HomeSearchInput.js";
import HomeSearchLocationSelect from "./components/HomeSearchLocationSelect.js";
import SuggestionBox from "../../../../widgets/SugesstionBox/SuggestionBox.js";
import HomeSearchLocateMeNav from "./components/HomeSearchLocateMeNav.js";
import { useMapLocation } from "../../../../context/LocationContext.js";
import styles from "./HomeSearchBar.css";
import { useApiSearch } from "../../../../hooks/api-hooks.js";

const HomeSearchBar = () => {
  const { topics, location, setLocation, keywords, setKeywords } =
    useRouteHandlerContext();

  const [locationModal, setLocationModal] = useState(location);
  const [localKeywords, setLocalKeywords] = useState(keywords);
  const [suggestInput, setSuggestInput] = useState("");
  const [currentKeywordInput, setCurrentKeywordInput] = useState("");
  const [currentLocationInput, setCurrentLocationInput] = useState("");
  const [isLocateMeNavVisible, setLocateMeNavVisible] = useState(false);
  const { mapLocation, setMapLocation } = useMapLocation();

  const searchParams = {
    topics,
    location: locationModal,
    keywords,
    startDate: null,
    endDate: null,
  };

  const {
    data: results,
    error: resultsError,
    isLoading,
  } = useApiSearch(searchParams);

  useEffect(() => {
    setMapLocation(location);
    setLocalKeywords(keywords);
  }, [location, keywords, setMapLocation]);

  const handleSetLocation = (location) => {
    const isFromLocateMe = location?.fromLocateMe;
    let val = isFromLocateMe ? location.value : location;

    if (typeof val === "function") {
      val = val(location);
    }

    setMapLocation(val);
    setLocationModal(val);

    if (currentKeywordInput) {
      setKeywords(currentKeywordInput);
    }

    if (!isFromLocateMe) {
      setLocation(val);
    }
  };

  const handleSetKeywords = (val) => {
    if (typeof val === "function") {
      val = val(localKeywords);
    }

    setLocalKeywords(val);

    const resolvedLocation = mapLocation || currentLocationInput;
    if (resolvedLocation) {
      setLocation(resolvedLocation);
    }
    setKeywords(val);
  };

  const toggleLocateMeNav = () => {
    setLocateMeNavVisible(!isLocateMeNavVisible);
  };

  const handleSeeMoreClick = () => {
    setLocation(mapLocation);
  };

  return (
    <>
      <form className={styles.searchBarContainer}>
        <div className={styles.inputContainer}>
          <label
            htmlFor="search-word"
            title="Search by Keyword"
            aria-label="Search by Word or Phrase or City"
          >
            <i
              className={"fa-solid fa-magnifying-glass fa-fw"}
              aria-hidden="true"
            ></i>
          </label>
          <HomeSearchInput
            loading={isLoading}
            setValue={handleSetKeywords}
            placeholder="Word or phrase or city"
            startWithFocus={true}
            setSuggestInput={setSuggestInput}
            setCurrentKeywordInput={setCurrentKeywordInput}
          />
        </div>
        <div className={styles.inputContainer}>
          <label
            htmlFor="search-place"
            title="Search by Place"
            aria-label="Search by ZIP or Postal Code"
          >
            <i
              className="fa-solid fa-location-dot fa-fw"
              aria-hidden="true"
            ></i>
          </label>
          <HomeSearchLocationSelect
            setValue={handleSetLocation}
            placeholder="ZIP or postal code"
            toggleLocateMeNav={toggleLocateMeNav}
            setCurrentLocationInput={setCurrentLocationInput}
          />
        </div>
      </form>
      <div className={styles.subNavWrapper}>
        {isLocateMeNavVisible && (
          <HomeSearchLocateMeNav
            currentKeywordInput={currentKeywordInput}
            results={results}
            onSeeMoreClick={handleSeeMoreClick}
            loading={isLoading}
            error={resultsError}
          />
        )}
      </div>
      <div className={styles.suggestionBoxWrapper}>
        <SuggestionBox
          inputLetters={suggestInput}
          currentLocation={mapLocation || currentLocationInput}
          currentKeywords={currentKeywordInput}
          onClose={() => setSuggestInput("")}
        />
      </div>
    </>
  );
};

export default HomeSearchBar;
