// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jKf5352rYD8avORWL4Ds {
  color: var(--button-text-color);
  background-color: var(--accent-color);
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  font-family: var(--button-font);
  font-weight: var(--button-font-weight);
  border-radius: var(--button-corner-radius);
  text-decoration: none;
  box-shadow: var(--button-shadow);
  transition: var(--global-nav-transition);
  cursor: pointer;
}

.jKf5352rYD8avORWL4Ds:hover {
  background-color: var(--button-color-hover);
  box-shadow: var(--button-shadow-hover);
}

.MxNmCIqxaiRWlm1r8utZ {
  font-size: var(--step--1);
  line-height: var(--step-0);
  padding: var(--space-2xs) var(--space-s);
}

.fz7zJ8Oux7Gzc85M46FZ {
  font-size: var(--step--1);
  line-height: var(--step-0);
  gap: var(--space-3xs);
  padding: var(--space-xs) var(--space-s);
}

.laEotZc6y6dq76NUJJHg {
  gap: var(--space-2xs);
  font-size: var(--step-0);
  line-height: var(--step-1);
  padding: var(--space-xs) var(--space-m);
}

.h0R77FEsirYiWunJqGYr {
  padding: var(--space-xs) var(--space-l);
  font-size: var(--step-0);
  line-height: var(--step-1);
}

.BkpOd6C0r2YBq8Fg0ybM {
  background-color: var(--disabled-text-color-darker);
  color: var(--disabled-text-color);
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/buttons/PrimaryButton.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,qCAAqC;EACrC,SAAS;EACT,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,+BAA+B;EAC/B,sCAAsC;EACtC,0CAA0C;EAC1C,qBAAqB;EACrB,gCAAgC;EAChC,wCAAwC;EACxC,eAAe;AACjB;;AAEA;EACE,2CAA2C;EAC3C,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,qBAAqB;EACrB,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,0BAA0B;EAC1B,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;EACvC,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE,mDAAmD;EACnD,iCAAiC;EACjC,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".btn {\n  color: var(--button-text-color);\n  background-color: var(--accent-color);\n  border: 0;\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  vertical-align: middle;\n  text-align: center;\n  font-family: var(--button-font);\n  font-weight: var(--button-font-weight);\n  border-radius: var(--button-corner-radius);\n  text-decoration: none;\n  box-shadow: var(--button-shadow);\n  transition: var(--global-nav-transition);\n  cursor: pointer;\n}\n\n.btn:hover {\n  background-color: var(--button-color-hover);\n  box-shadow: var(--button-shadow-hover);\n}\n\n.btn-xs {\n  font-size: var(--step--1);\n  line-height: var(--step-0);\n  padding: var(--space-2xs) var(--space-s);\n}\n\n.btn-sm {\n  font-size: var(--step--1);\n  line-height: var(--step-0);\n  gap: var(--space-3xs);\n  padding: var(--space-xs) var(--space-s);\n}\n\n.btn-md {\n  gap: var(--space-2xs);\n  font-size: var(--step-0);\n  line-height: var(--step-1);\n  padding: var(--space-xs) var(--space-m);\n}\n\n.btn-lg {\n  padding: var(--space-xs) var(--space-l);\n  font-size: var(--step-0);\n  line-height: var(--step-1);\n}\n\n.disabled {\n  background-color: var(--disabled-text-color-darker);\n  color: var(--disabled-text-color);\n  opacity: 0.5;\n  cursor: not-allowed;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `jKf5352rYD8avORWL4Ds`,
	"btn-xs": `MxNmCIqxaiRWlm1r8utZ`,
	"btn-sm": `fz7zJ8Oux7Gzc85M46FZ`,
	"btn-md": `laEotZc6y6dq76NUJJHg`,
	"btn-lg": `h0R77FEsirYiWunJqGYr`,
	"disabled": `BkpOd6C0r2YBq8Fg0ybM`
};
export default ___CSS_LOADER_EXPORT___;
