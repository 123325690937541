import React from "react";

import { Link } from "react-router-dom";

import serverDownImg from "../../imgs/undraw_server_down.svg";

import styles from "./ErrorPage.css";

const ErrorPage = ({
  image = serverDownImg,
  header = "Sorry, an unknown error occured\u2026",
  message = "There's a problem on our end.",
  searchKw = "",
}) => {
  let searchAlternative = "";
  if (searchKw) {
    searchAlternative = (
      <>
        {" "}
        <Link to={`/search?k=${searchKw}`}>{"Try searching everywhere"}</Link>
      </>
    );
  }
  return (
    <div className={styles.container}>
      <div>
        <h3>{header}</h3>
        <p>
          {message}
          {searchAlternative}
        </p>
        <img src={image} alt="Failure image" />
      </div>
    </div>
  );
};

export default ErrorPage;
