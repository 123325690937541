import React from "react";

import styles from "./DebugConsole.css";

const DebugConsole = ({ data, hide = false }) => {
  return (
    !hide && (
      <div className={styles.container}>{JSON.stringify(data, null, 1)}</div>
    )
  );
};

export default DebugConsole;
