import React, { useState, setState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { arrayEquals, toQueryString } from "../utilities/funcs.js";

import {
  encodeTopics,
  decodeTopics,
  encodeLocation,
  decodeLocation,
  encodeKeywords,
  decodeKeywords,
} from "./queryParams.js";

import App from "../views/App.js";

const RouteHandler = ({ mode }) => {
  const windowLocation = useLocation();
  const queryParams = useParams();
  const navigate = useNavigate();

  const [topics, setTopics] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [location, setLocation] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [keywords, setKeywords] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [startDate, setStartDate] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [endDate, setEndDate] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [featuredTopic, setFeaturedTopic] = useState({
    value: undefined,
    triggerEffect: false,
  });

  const handleSetTopics = (val, triggerEffect = false) => {
    if (topics.value === val || arrayEquals(topics.value, val)) {
      return;
    }
    setTopics({ value: val, triggerEffect });
  };

  const handleSetStartDate = (sd, triggerEffect = false) => {
    if (startDate.value !== sd) {
      setStartDate({ value: sd, triggerEffect });
    }
  };

  const handleSetEndDate = (ed, triggerEffect = false) => {
    if (endDate.value !== ed) {
      setEndDate({ value: ed, triggerEffect });
    }
  };

  const handleSetFeaturedTopic = (ft, triggerEffect = false) => {
    if (featuredTopic.value !== ft) {
      setFeaturedTopic({ value: ft, triggerEffect });
      handleSetTopics([]);
      handleSetLocation(null);
      document.title = "CivicSearch: " + ft;
    }
  };

  const handleSetLocation = (val, triggerEffect = false) => {
    if (location.value === val || location.value?.equals(val)) {
      return;
    }
    setLocation({ value: val, triggerEffect });
  };

  const handleSetKeywords = (val, triggerEffect = false) => {
    if (keywords.value === val) {
      return;
    }
    if (!val) {
      document.title = "CivicSearch";
    } else {
      document.title = "CivicSearch: " + val;
    }
    setKeywords({ value: val, triggerEffect });
  };

  useEffect(() => {
    if (
      !topics.triggerEffect &&
      !location.triggerEffect &&
      !keywords.triggerEffect
    ) {
      return;
    }
    // should only ever run when topic or location is modified from outside of RouteHandler
    const t = topics.value;
    const l = location.value;
    const k = keywords.value;
    const usingTopics = t.length > 0;
    const usingLocation = l !== null;
    const usingKeywords = k !== "";
    const route = (() => {
      if (usingTopics && usingLocation && usingKeywords) {
        return `/${encodeLocation(l)}/${encodeTopics(t)}/${encodeURIComponent(k)}`;
      }
      if (usingTopics && usingLocation) {
        return `/${encodeLocation(l)}/${encodeTopics(t)}`;
      }
      const params = {
        t: usingTopics ? encodeTopics(t) : undefined,
        l: usingLocation ? encodeLocation(l) : undefined,
        k: usingKeywords ? encodeKeywords(k) : undefined,
      };
      return `/search?${toQueryString(params)}`;
    })();
    navigate(route);
  }, [topics, location, keywords]);

  useEffect(() => {
    const redirectOnError = () => {
      navigate("/");
      handleSetTopics([]);
      handleSetLocation(null);
      handleSetKeywords("");
    };
    const handleSearch = async (t, l, k, start_date, end_date) => {
      const dt = decodeTopics(t);
      const dl = await decodeLocation(l);
      const dk = decodeKeywords(k);
      if (t !== undefined && dt.length === 0) {
        redirectOnError();
        return;
      } else if (l !== undefined && dl === null) {
        redirectOnError();
        return;
      } else if (k !== undefined && dk === "") {
        redirectOnError();
        return;
      }
      handleSetKeywords(dk);
      handleSetTopics(dt);
      handleSetLocation(dl);
      handleSetStartDate(start_date);
      handleSetEndDate(end_date);
    };
    const handleByMode = {
      search: async () => {
        const params = new URLSearchParams(windowLocation.search);
        const t = params.has("t") ? params.get("t") : undefined;
        const l = params.has("l") ? params.get("l") : undefined;
        const k = params.has("k") ? params.get("k") : undefined;
        const start_date = params.has("start_date")
          ? params.get("start_date")
          : undefined;
        const end_date = params.has("end_date")
          ? params.get("end_date")
          : undefined;
        await handleSearch(t, l, k, start_date, end_date);
      },
      list_topics: async () => {
        const l = queryParams.location;
        const dl = await decodeLocation(l);
        if (dl === null || dl.type !== "named") {
          redirectOnError();
          return;
        }
        handleSetTopics([]);
        handleSetLocation(dl);
        handleSetKeywords("");
      },
      show_results: async () => {
        const { topics: t, location: l, keywords: k } = queryParams;
        await handleSearch(t, l, k);
      },
      featured: async () => {
        handleSetFeaturedTopic(queryParams.featuredTopic);
      },
      subscribe: async () => {
        const params = new URLSearchParams(windowLocation.search);
        const k = params.has("k") ? params.get("k") : undefined;
        const dk = decodeKeywords(k);
        handleSetKeywords(dk);
        handleSetTopics([]);
        handleSetLocation(null);
      },
      invalid_route: () => {
        redirectOnError();
        return;
      },
    };
    if (mode in handleByMode) {
      handleByMode[mode]();
    } else {
      handleSetTopics([]);
      handleSetLocation(null);
      handleSetKeywords("");
    }
  }, [windowLocation]);

  if (
    ((topics.value === undefined && keywords.value === undefined) ||
      location.value === undefined) &&
    !(mode === "featured" && featuredTopic.value !== undefined)
  ) {
    return null;
  }
  return (
    <App
      mode={mode}
      topics={[topics.value, (val) => handleSetTopics(val, true)]}
      location={[location.value, (val) => handleSetLocation(val, true)]}
      keywords={[keywords.value, (val) => handleSetKeywords(val, true)]}
      startDate={[startDate.value, (val) => handleSetStartDate(val, true)]}
      endDate={[endDate.value, (val) => handleSetEndDate(val, true)]}
      featuredTopic={[
        featuredTopic.value,
        (val) => handleSetFeaturedTopic(val, true),
      ]}
    />
  );
};

export default RouteHandler;
