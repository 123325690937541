// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KN_XVeh8Cf23TvTIMKDs {
  background-color: var(--primary-color);
}

.jteMMSjW2m7HwHHJ8oKr {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.jteMMSjW2m7HwHHJ8oKr > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 300vh; /* Adjust based on the total height needed for all sections */
  scroll-snap-type: y mandatory; /* Enables snapping scroll vertically */
}

.Lru5DLNpo5fd28gwx1fG {
  height: 100vh; /* Each section takes up the full height of the viewport */
  width: 100%;
  scroll-snap-align: start; /* Ensures the section snaps into place on scroll */
  overflow: hidden; /* Prevents inner content from causing scroll issues */
}

.ziYCtZ5UK3kJbN_DFM45 {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes up the full height of the viewport */
}

.ziYCtZ5UK3kJbN_DFM45 > * {
  flex-shrink: 0; /* Prevents any content from shrinking */
}

.ziYCtZ5UK3kJbN_DFM45 > :nth-child(2) {
  flex-grow: 1; /* Ensures the Body section grows to fill available space */
}
`, "",{"version":3,"sources":["webpack://./src/views/App.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,aAAa,EAAE,6DAA6D;EAC5E,6BAA6B,EAAE,uCAAuC;AACxE;;AAEA;EACE,aAAa,EAAE,0DAA0D;EACzE,WAAW;EACX,wBAAwB,EAAE,mDAAmD;EAC7E,gBAAgB,EAAE,sDAAsD;AAC1E;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAE,mEAAmE;AACxF;;AAEA;EACE,cAAc,EAAE,wCAAwC;AAC1D;;AAEA;EACE,YAAY,EAAE,2DAA2D;AAC3E","sourcesContent":[".background {\n  background-color: var(--primary-color);\n}\n\n.container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.container > div {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  height: 300vh; /* Adjust based on the total height needed for all sections */\n  scroll-snap-type: y mandatory; /* Enables snapping scroll vertically */\n}\n\n.fullHeightSection {\n  height: 100vh; /* Each section takes up the full height of the viewport */\n  width: 100%;\n  scroll-snap-align: start; /* Ensures the section snaps into place on scroll */\n  overflow: hidden; /* Prevents inner content from causing scroll issues */\n}\n\n.pageContainer {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh; /* Ensures the container takes up the full height of the viewport */\n}\n\n.pageContainer > * {\n  flex-shrink: 0; /* Prevents any content from shrinking */\n}\n\n.pageContainer > :nth-child(2) {\n  flex-grow: 1; /* Ensures the Body section grows to fill available space */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `KN_XVeh8Cf23TvTIMKDs`,
	"container": `jteMMSjW2m7HwHHJ8oKr`,
	"fullHeightSection": `Lru5DLNpo5fd28gwx1fG`,
	"pageContainer": `ziYCtZ5UK3kJbN_DFM45`
};
export default ___CSS_LOADER_EXPORT___;
