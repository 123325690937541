import React, { useState, useEffect } from "react";
import { fetchWithStatus } from "../../utilities/funcs.js";

import styles from "./FeaturedTopicPage.css";
import LoadingPage from "./../pages/LoadingPage.js";
import ErrorPage from "./../pages/ErrorPage.js";
import { parse } from "../../models/parser.js";

const FeaturedTopicPage = ({ featuredTopic }) => {
  const [featuredInfo, setFeaturedInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      try {
        let url = `featured?query_id=${featuredTopic}`;
        const [res, status] = await fetchWithStatus(url);
        if (status !== 200) {
          throw res;
        }
        setFeaturedInfo(res);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }, 350);
  }, []);

  if (loading) {
    return <LoadingPage />;
  } else if (error) {
    return <ErrorPage />;
  }

  return (
    <div className={styles.featuredTopicPage}>
      <div className={`${styles.container} content-container`}>
        {parse(featuredInfo.html)}
      </div>
    </div>
  );
};

export default FeaturedTopicPage;
