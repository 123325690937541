// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LsemuCYbnhr6Z7gtou9q {
  flex-direction: column;
  min-height: calc(30vh - var(--results-nav-height));
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.LsemuCYbnhr6Z7gtou9q h2 {
  font-size: var(--step-1);
}

.ZBqR4MWu8cR3IztopxXf {
  color: var(--topic-color);
}

.Awg8flCyiEk8Li1IUYV5 {
  color: var(--keyword-color);
}

.opcf3zEvB3nLlQq0a09H {
  color: white;
  background-color: hsla(222, 18%, 19%, 0.721);
}

.i8bxqvfiukJUxPXlsMiU {
  min-height: calc(50vh - var(--results-nav-height));
}

@keyframes _5SnPEKoWZ9qRFg5catgq {
  0% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

.gBp2W0Ayc90KkNuTqo8b {
  min-height: calc(100vh - var(--results-nav-height));

  pointer-events: none;
  animation: _5SnPEKoWZ9qRFg5catgq 0.8s ease-in-out infinite;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/ResultsSummarySection.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kDAAkD;EAClD,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;EAChC,qBAAqB,EAAE,gBAAgB;EACvC,wBAAwB,EAAE,mCAAmC;AAC/D;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE,kDAAkD;AACpD;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE,mDAAmD;;EAEnD,oBAAoB;EACpB,0DAAkD;AACpD","sourcesContent":[".resultsSummary {\n  flex-direction: column;\n  min-height: calc(30vh - var(--results-nav-height));\n  padding: 1.5rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: all 0.3s ease-in-out;\n  scrollbar-width: none; /* For Firefox */\n  -ms-overflow-style: none; /* For Internet Explorer and Edge */\n}\n\n.resultsSummary h2 {\n  font-size: var(--step-1);\n}\n\n.displayTextTopic {\n  color: var(--topic-color);\n}\n\n.displayTextKeyword {\n  color: var(--keyword-color);\n}\n\n.customAccordionExpand {\n  color: white;\n  background-color: hsla(222, 18%, 19%, 0.721);\n}\n\n.loadingResultsSummary {\n  min-height: calc(50vh - var(--results-nav-height));\n}\n\n@keyframes skeletonPulse {\n  0% {\n    opacity: 0.6;\n  }\n  70% {\n    opacity: 0.8;\n  }\n  100% {\n    opacity: 0.6;\n  }\n}\n\n.loadingResultsSummarySkeleton {\n  min-height: calc(100vh - var(--results-nav-height));\n\n  pointer-events: none;\n  animation: skeletonPulse 0.8s ease-in-out infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultsSummary": `LsemuCYbnhr6Z7gtou9q`,
	"displayTextTopic": `ZBqR4MWu8cR3IztopxXf`,
	"displayTextKeyword": `Awg8flCyiEk8Li1IUYV5`,
	"customAccordionExpand": `opcf3zEvB3nLlQq0a09H`,
	"loadingResultsSummary": `i8bxqvfiukJUxPXlsMiU`,
	"loadingResultsSummarySkeleton": `gBp2W0Ayc90KkNuTqo8b`,
	"skeletonPulse": `_5SnPEKoWZ9qRFg5catgq`
};
export default ___CSS_LOADER_EXPORT___;
