// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../imgs/blob-blue-duo-2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zvRW0y5rvvCQV4HbhjJn {
  background-color: var(--secondary-alt-color);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: right 20%;
  background-size: auto 150%;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 1rem 5rem;
  transition:
    height 300ms ease-in-out,
    opacity 300ms ease-in-out;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-grow: 1;
}

.zvRW0y5rvvCQV4HbhjJn::-webkit-scrollbar {
  display: none;
}

.zvRW0y5rvvCQV4HbhjJn.SKuh2olDAFb2DMYwzJU_ {
  display: flex;
}

.zvRW0y5rvvCQV4HbhjJn.Fl9dZqKRqeqhmE1YnlWQ,
.zvRW0y5rvvCQV4HbhjJn:empty {
  display: none;
}

.zvRW0y5rvvCQV4HbhjJn h2 {
  color: var(--white-color);
  font-size: var(--step-0);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/ResultsRelatedSection/ResultsRelatedSection.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,yDAA4D;EAC5D,8BAA8B;EAC9B,0BAA0B;EAC1B,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB;;6BAE2B;EAC3B,qBAAqB;EACrB,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,wBAAwB;EACxB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".relatedResults {\n  background-color: var(--secondary-alt-color);\n  background-image: url(../../../../imgs/blob-blue-duo-2x.png);\n  background-position: right 20%;\n  background-size: auto 150%;\n  background-repeat: no-repeat;\n  flex-direction: column;\n  justify-content: flex-start;\n  padding: 1rem 1rem 5rem;\n  transition:\n    height 300ms ease-in-out,\n    opacity 300ms ease-in-out;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  flex-grow: 1;\n}\n\n.relatedResults::-webkit-scrollbar {\n  display: none;\n}\n\n.relatedResults.open {\n  display: flex;\n}\n\n.relatedResults.closed,\n.relatedResults:empty {\n  display: none;\n}\n\n.relatedResults h2 {\n  color: var(--white-color);\n  font-size: var(--step-0);\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 0.3rem;\n  flex-wrap: wrap;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relatedResults": `zvRW0y5rvvCQV4HbhjJn`,
	"open": `SKuh2olDAFb2DMYwzJU_`,
	"closed": `Fl9dZqKRqeqhmE1YnlWQ`
};
export default ___CSS_LOADER_EXPORT___;
