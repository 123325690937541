import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchWithStatus } from "../../utilities/funcs.js";

import entityStyles from "../Entities.css";
import Accordion from "./../widgets/Accordion.js";
import { PointsOfInterestMap } from "./../widgets/Map.js";
import { Location } from "../../models/location.js";
import { Topic } from "../../models/topic.js";

import LoadingPage from "./../pages/LoadingPage.js";
import ErrorPage from "./../pages/ErrorPage.js";

import styles from "./ResultsPlacePage.css";
import { TopicLink } from "../widgets/NewPillLink.js";
import ResultsHeader from "../ResultsHeader.js";
import ResultsRelatedSection from "../ResultsRelatedSection.js";
import { BiBookBookmark } from "react-icons/bi";
import RESULT_TYPES from "../../models/ResultTypes.js";

const filterPoiBySinceSelection = (pointsOfInterest, since) => {
  // filter a set of points of interest to respect the selectbox above the point-of-interest map.
  // since=="last24" means return pointsOfInterest no older than 24 months ago, etc.
  let months = parseInt(since.replace("last", ""), 10);
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - months);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  let minDate = `${year}-${month}-${day}`;
  return pointsOfInterest.filter((point) => {
    return point["last_mention"]["date"] > minDate;
  });
};

const ResultsPlacePage = ({ place }) => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [poiSince, setPoiSince] = useState("last24");

  const handleOnPoiSelectboxChange = (event) => {
    const val = event.target.value;
    setPoiSince(val);
  };

  useEffect(() => {
    if (place === undefined || place === null) {
      return;
    }
    setTimeout(async () => {
      try {
        const [res, status] = await fetchWithStatus(
          `get_topics_by_city?query_id=${place.queryText}`,
        );
        if (status !== 200) {
          throw res;
        }
        setTopics(res);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }, 350);
  }, [place]);

  if (loading || !place) {
    return <LoadingPage />;
  } else if (error) {
    return <ErrorPage />;
  }
  return (
    <div className={styles.resultsPage}>
      <section className={styles.leftSection}>
        <ResultsHeader
          headerTitle={place.displayText}
          headerType={RESULT_TYPES.PLACE}
        />

        <section className={styles.cityMeetingSection}>
          <Accordion maxHeight={300} recalculateOnMount={true}>
            <h2>
              {topics["recent_meetings"].length} Public Meetings in{" "}
              <span className={styles.displayText}>{place.displayText}</span>
            </h2>
            {topics["recent_meetings"].map((meeting, i) => (
              <div className={styles.meetingRow} key={i}>
                <div className={styles.dateIcon}>
                  <li className={styles.date} />
                  <div>
                    {meeting.meeting_date}
                    {": "}
                  </div>
                </div>
                <div className={styles.linkRow}>
                  <li className={styles.more} />
                  <Link
                    to={meeting.vid_url || `https://www.youtube.com/watch?v=${meeting.vid_id}`}
                    className={styles.linkNoUnderline}
                  >
                    <span>{meeting.vid_title}</span>
                  </Link>
                </div>
              </div>
            ))}
          </Accordion>
        </section>

        <ResultsRelatedSection
          entityName={place.displayText}
          topics={topics}
          resultType={RESULT_TYPES.PLACE}
          place={place}
        />
      </section>

      <section className={styles.resultsSection}>
        <section className={styles.cityMapSection}>
          <div className={styles.mapTitleRow}>
            <p>
              Locations Discussed in{" "}
              <span className={styles.locationText}>{place.displayText}</span>
            </p>
            <select
              value={poiSince}
              onChange={handleOnPoiSelectboxChange}
              className={styles.dateSelect}
            >
              <option value="last24">in the past 2 years</option>
              <option value="last12">in the past year</option>
              <option value="last6">in the past 6 months</option>
              <option value="last2">in the past 2 months</option>
            </select>
          </div>

          <div className={styles.cityMapWrapper}>
            <PointsOfInterestMap
              cityQueryId={place.queryText}
              pointsOfInterest={filterPoiBySinceSelection(
                topics.points_of_interest,
                poiSince,
              )}
              centroidLongitude={place.lon}
              centroidLatitude={place.lat}
            />
          </div>
        </section>

        <section className={styles.topTopicsSection}>
          <div className={styles.topTopicsBox}>
            <p>
              <BiBookBookmark
                size={24}
                color="var(--topic-color)"
                style={{ marginRight: "10px" }}
              />
              Popular Topics Discussed in{" "}
              <span className={styles.displayText}>{place.displayText}</span>
            </p>

            <ol className={styles.numberedList}>
              {topics.topics.map((topic_stats_obj, i) => {
                let topic = Topic.fromId(topic_stats_obj.topic_id);
                if (topic != null) {
                  let pct = 100.0 * topic_stats_obj.prob;
                  if (pct > 0) {
                    let relative_prob = topic_stats_obj.relative_prob;
                    let relative_prob_class =
                      relative_prob > 1
                        ? styles.topic_prob_higher_than_avg
                        : styles.topic_prob_lower_than_avg;
                    return (
                      <li key={i}>
                        <div className={styles.topic_info_box}>
                          <TopicLink
                            size="smaller"
                            url={`/${place.queryText}/${topic.queryText}`}
                          >
                            {topic.displayText}
                          </TopicLink>
                          <span className={styles.topic_info}>
                            {` ${pct.toFixed(2)}% of this town's transcripts; `}
                            <span className={relative_prob_class}>
                              {`${relative_prob.toFixed(1)}x`}
                            </span>
                            <span>{" other towns"}</span>
                          </span>
                        </div>
                      </li>
                    );
                  }
                }
              })}
            </ol>
          </div>
        </section>
      </section>
    </div>
  );
};

export default ResultsPlacePage;
