import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { classList as cl } from "../../utilities/funcs.js";
import Accordion from "./Accordion.js";

import styles from "./Carousel.css";
import { Pagination } from "./Pagination.js";

const Carousel = ({
  children,
  gapSize = 16,
  minSlideHeight = "auto",
  maxSlideHeight = "auto",
  className: myClassName = null,
  onExpandChange,
  id,
  onChange = (_newSelectedIndex) => {},
}) => {
  const containerRef = useRef();

  const [isScrolling, setIsScrolling] = useState(false);
  const [targetedIndex, setTargetedIndex] = useState(0);
  const [targetedIndexIsExpanded, setIsTargetedIndexIsExpanded] =
    useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    let scrollTimer;
    let handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(() => setIsScrolling(false), 100);
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
      clearTimeout(scrollTimer);
    };
  }, [containerRef?.current]);

  useEffect(() => {
    const goToSlide = (index) => {
      const container = containerRef.current;
      if (!container) {
        return;
      }
      const width = container.clientWidth;
      container.scrollTo({ left: index * (width + gapSize) });
    };

    goToSlide(targetedIndex);
    onChange(targetedIndex);
  }, [targetedIndex, containerRef?.current]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || isScrolling) {
      return;
    }
    const width = container.clientWidth;
    const index = Math.round(container.scrollLeft / (width + gapSize));
    setTargetedIndex(index);
  }, [isScrolling, containerRef?.current]);

  let slides = useMemo(
    () => children.map((slide, i) => <li key={i}>{slide}</li>),
    [children],
  );
  return (
    <div className={cl(styles.container, myClassName)}>
      <div
        className={styles.slideContainer}
        // style={{ minHeight: minSlideHeight, maxHeight: maxSlideHeight }}
      >
        <button
          className={cl("fa-solid", "fa-chevron-left", styles.arrow)}
          disabled={isScrolling || targetedIndex <= 0}
          onClick={() => setTargetedIndex(targetedIndex - 1)}
        />
        <ul ref={containerRef} style={{ gap: gapSize }}>
          {slides}
        </ul>
        <button
          className={cl("fa-solid", "fa-chevron-right", styles.arrow)}
          disabled={isScrolling || targetedIndex >= children.length - 1}
          onClick={() => setTargetedIndex(targetedIndex + 1)}
        />
      </div>
      <Pagination
        value={[targetedIndex, setTargetedIndex]}
        numSlides={children.length}
        disabled={isScrolling}
      />
    </div>
  );
};

export default Carousel;
