import React, { useRef, useEffect, useState } from "react";
import "../../Variables.css";
import styles from "./subscribeDialog.css";
import PrimaryButton from "../buttons/PrimaryButton.js";
import Input from "../forms/Input.js";
import { useDialog } from "../../../context/DialogContext.js";

const SubscribeDialog = () => {
  const { openDialogs, closeDialog } = useDialog();
  const dialogRef = useRef(null);
  const isOpen = openDialogs.includes("subscribe");

  const [email, setEmail] = useState("");
  const [zip, setZip] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleZipChange = (e) => {
    setZip(e.target.value);
  };

  useEffect(() => {
    if (isOpen) {
      if (dialogRef.current.showModal) {
        dialogRef.current.showModal();
      } else {
        console.log("The <dialog> API is not supported by this browser");
      }
    } else {
      dialogRef.current.close();
    }
  }, [isOpen]);

  return (
    <dialog id="subscribeDialog" className={styles.modal} ref={dialogRef}>
      <div className={styles.modalCloseBtnWrapper}>
        <button
          id="closeSubscribeDialog"
          className={`${styles.modalCloseBtn} fa-solid fa-xmark`}
          title="Close"
          aria-label="close"
          onClick={() => closeDialog("subscribe")}
        ></button>
      </div>
      <div className={styles.modalContent}>
        <div id="mc_embed_shell">
          <link
            href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
            rel="stylesheet"
            type="text/css"
          />
          <div id="mc_embed_signup">
            <form
              action="https://civicsearch.us14.list-manage.com/subscribe/post?u=072876ecccbe8f476c67f0bdc&amp;id=460b3d01f7&amp;f_id=00f1a5e0f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
            >
              <div id="mc_embed_signup_scroll" className={styles.signUpScroll}>
                <h2>Stay informed with CivicSearch</h2>
                <p>
                  We'll send you updates (at most weekly) about important issues
                  in local governance, focusing on a different topic every
                  update. (See our{" "}
                  <a href="https://civicsearch.org/feature/all">past issues</a>{" "}
                  for the topics we've studied so far.) If you provide a zip
                  code, we'll tailor the updates to that location. You can also
                  subscribe to specific keywords by searching for the keyword on
                  CivicSearch and then clicking the Subscribe button. If you've
                  already subscribed, the keyword will be added to your updates.
                </p>
                <div className={styles.inputGroup}>
                  <Input
                    className={styles.emailInput}
                    label="Email"
                    isRequired
                    type="email"
                    name="EMAIL"
                    id="mce-EMAIL"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <div className={styles.errorMessage}>
                    Please provide a valid email address
                  </div>
                </div>
                <div className={styles.inputGroup}>
                  <Input
                    className={styles.zipInput}
                    label="ZIP/Postal Code"
                    type="text"
                    name="MMERGE6"
                    id="mce-MMERGE6"
                    value={zip}
                    onChange={handleZipChange}
                  />
                </div>
                <div className={styles.btnGroup}>
                  <PrimaryButton
                    type="submit"
                    id="openSubscribeDialogButton"
                    size="md"
                    className={`${styles.btnSm} ${styles.subscribeButton}`}
                  >
                    Subscribe
                  </PrimaryButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default SubscribeDialog;
