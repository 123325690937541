import React, { useState, useRef } from "react";
import Input from "../../../../../widgets/forms/Input.js";

import styles from "./HomeSearchInput.css";

const HomeSearchInput = ({
  setValue,
  loading,
  placeholder,
  setSuggestInput,
  setCurrentKeywordInput,
}) => {
  const inputRef = useRef();

  const [inputValue, setInputValue] = useState("");

  const handleOnChange = (event) => {
    const val = event.target.value;
    setInputValue(val);
    setCurrentKeywordInput(val);
    setSuggestInput(val);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      setValue(inputValue);
    }
  };

  const handleClearInput = () => {
    setInputValue("");
    setCurrentKeywordInput("");
    setSuggestInput("");
    setValue("");
  };

  return (
    <div className={styles.keywordInputWrapper}>
      <Input
        inputRef={inputRef}
        type="text"
        value={loading ? "" : inputValue}
        disabled={loading}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        placeholder={loading ? "" : placeholder}
        spellCheck={false}
        className={styles.keywordInput}
      />
      {inputValue !== "" && (
        <button
          className={`${styles.inputClearButton} fa-solid fa-xmark`}
          title="Clear Search"
          aria-label="Clear Search"
          onClick={handleClearInput}
        ></button>
      )}
    </div>
  );
};

export default HomeSearchInput;
