import React from "react";
import styles from "./HomeSearchLocateMeNav.css";
import PrimaryButton from "../../../../../widgets/buttons/PrimaryButton.js";
import { LocationLink } from "../../../../../widgets/buttons/PillLink.js";

const HomeSearchLocateMeNav = React.memo(
  ({ results, onSeeMoreClick, loading, currentKeywordInput, error }) => {
    if (error) {
      return (
        <nav
          id="subnav-locate-me"
          className={styles.searchSubnav}
          aria-label="Locate Me"
        >
          <div>
            <div className={styles.container}>
              <h6>Error loading results. Please try again.</h6>
            </div>
          </div>
        </nav>
      );
    }

    if (
      loading ||
      !results ||
      !results.places ||
      (results && results.places.length === 0)
    ) {
      return (
        <nav
          id="subnav-locate-me"
          className={styles.searchSubnav}
          aria-label="Locate Me"
        >
          <div>
            <div className={styles.container}>
              <i className="fa-solid fa-spinner fa-spin" />
            </div>
          </div>
        </nav>
      );
    }

    return (
      <nav
        id="subnav-locate-me"
        className={styles.searchSubnav}
        aria-label="Locate Me"
      >
        <div>
          <h6>Places closest to your location</h6>
          <div className={styles.pillLinkWrapper}>
            {results.places.slice(0, 10).map((place, i) => {
              const location_query_id = place.query_id;
              const location_display = place.display_name;
              const keyword_query = currentKeywordInput
                ? `&k=${currentKeywordInput}`
                : "";
              const location_query = location_query_id
                ? `l=${location_query_id}`
                : "";

              return (
                <LocationLink
                  url={`/search?${location_query}${keyword_query}`}
                  key={i}
                  size="smaller"
                >
                  {location_display}
                </LocationLink>
              );
            })}
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <PrimaryButton
            type="submit"
            id="openSubscribeDialogButton"
            size="xs"
            className={`${styles.btnSm} ${styles.subscribeButton}`}
            onClick={onSeeMoreClick}
          >
            See More
          </PrimaryButton>
        </div>
      </nav>
    );
  },
);

HomeSearchLocateMeNav.displayName = "LocateMeNav";

export default HomeSearchLocateMeNav;
