import React from "react";

import ErrorPage from "../../../ErrorPage/ErrorPage.js";
import SearchResultItem from "./components/SearchResultItem.js";

import locationSearchImg from "../../../../imgs/undraw_location_search.svg";
import starsImg from "../../../../imgs/undraw_stars.svg";
import styles from "./SearchResultsList.css";

const SearchResultsList = ({
  results,
  isLoading,
  searchRequestFailed,
  location,
  setLocation,
  onVideoSelect,
}) => {
  if (isLoading) {
    return (
      <section className={styles.skeletonResultList}>
        {Array.from({ length: 5 }).map((_, index) => (
          <div
            key={`skeleton-${index}`}
            className={styles.skeletonResultItem}
          />
        ))}
      </section>
    );
  }

  if (!results || searchRequestFailed) {
    return <ErrorPage />;
  }

  if (results.num_places === 0) {
    return (
      <ErrorPage
        image={locationSearchImg}
        header={"Sorry, the search failed\u2026"}
        message="Our dataset doesn't cover any nearby places."
      />
    );
  }

  if (results.num_results === 0) {
    return (
      <ErrorPage
        image={starsImg}
        header={null}
        message={`No results found in ${results.num_places} nearby ${
          results.num_places === 1 ? "place" : "places"
        }.`}
        searchKw={location != null ? results.keywords : null}
      />
    );
  }

  return (
    <section>
      {results.results.map(
        (
          { title, vid_id, date, location: place, distance, snippets, vid_url },
          i,
        ) => (
          <SearchResultItem
            key={i}
            title={title}
            date={date}
            place={place}
            distance={distance}
            onVideoLinkClick={(timestamp) => {
              onVideoSelect(vid_id, timestamp, title, vid_url);
            }}
            snippets={snippets}
            showDistance={location !== null && location?.type !== "named"}
            setLocation={setLocation}
          />
        ),
      )}
    </section>
  );
};

export default SearchResultsList;
