import React from "react";
import ResultsPlacePage from "./ResultsPlacePage.js";
import ResultsTopicPage from "./ResultsTopicPage.js";
import ResultsKeywordPage from "./ResultsKeywordPage.js";
import NearbyLocationsPage from "../NearbyLocationPage/NearbyLocationsPage.js";

const ResultsPageSelector = ({
  topics,
  keywords,
  location,
  setLocation,
  startDate,
  endDate,
}) => {
  const searchingByLocation = location && !topics.length > 0 && !keywords;

  const searchingByTopic = topics.length > 0 && !keywords;

  if (searchingByLocation) {
    if (location.type === "named") {
      return <ResultsPlacePage place={location} />;
    }
    /* Location Zip only */
    return (
      <div>
        <NearbyLocationsPage location={location} />
      </div>
    );
  }

  if (searchingByTopic) {
    return (
      <ResultsTopicPage
        topics={topics}
        location={location}
        setLocation={setLocation}
        keywords={keywords}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }
  // TODO: There is a bug in the routing that is causing this page to render twice when navigating from /cities to a specific location; the first render sets topics and keywords per below, and triggers an error in
  // ResultsKeywordPage (which is otherwise unrelated to the desired navigation). Fixing this requires fixing the routing, which is a
  // PR for another time.
  const routerBug = topics.length == 0 && keywords == "";
  if (routerBug) {
    return <></>;
  }

  return (
    <ResultsKeywordPage
      topics={topics}
      location={location}
      setLocation={setLocation}
      keywords={keywords}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

export default ResultsPageSelector;
