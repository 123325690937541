import React from "react";
import styles from "./SecondaryButton.css";
import { classList as cl } from "../../utilities/funcs.js";

const SecondaryButton = ({
  children,
  onClick,
  type = "button",
  disabled = false,
  iconLeft,
  iconRight,
  className,
  size = "md",
}) => {
  return (
    <button
      type={type}
      className={cl(styles.btnSecondary, styles[`btn-${size}`], className, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {iconLeft && <i className={`fa-regular ${iconLeft}`}></i>}
      {children}
      {iconRight && <i className={`fa-regular ${iconRight}`}></i>}
    </button>
  );
};

export default SecondaryButton;
