import React, { createContext, useState, useContext } from "react";

const LocationContext = createContext();

export const LocationProvider = ({ children }) => {
  const [mapLocation, setMapLocation] = useState(null);

  return (
    <LocationContext.Provider value={{ mapLocation, setMapLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useMapLocation = () => useContext(LocationContext);
