import React, { useState } from "react";
import styles from "./ResultsHeader.css";
import PrimaryButton from "../../../widgets/buttons/PrimaryButton.js";

import { MdOutlineAutoStories, MdFmdGood } from "react-icons/md";
import { BiBookBookmark } from "react-icons/bi";
import { VscGraph } from "react-icons/vsc";
import { useDialog } from "../../../context/DialogContext.js";
import RESULT_TYPES from "../../../models/ResultTypes.js";

const ResultsHeader = ({
  headerTitle,
  keywords,
  place,
  headerType,
  startDate,
  endDate,
}) => {
  const { openDialog } = useDialog();
  const [isSubNavOpen, setIsSubNavOpen] = useState(true);

  let subscribeText = "Subscribe" + (keywords ? ' to "' + keywords + '"' : "");
  // TODO: encodeURIComponent(keywords) when constructing URL query string parameter values.
  let subscribeUrl = "/subscribe" + (keywords ? "?k=" + keywords : "");

  const formatTopicForUrl = (topic) => {
    return topic
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
  };

  const getSearchUrl = () => {
    if (keywords && headerType === RESULT_TYPES.KEYWORD) {
      return `/search?k=${encodeURIComponent(keywords)}`;
    } else if (headerTitle && headerType === RESULT_TYPES.TOPIC) {
      const formattedTopic = formatTopicForUrl(headerTitle);
      return `/search?t=${formattedTopic}`;
    }
  };

  const toggleSubNav = () => {
    setIsSubNavOpen(!isSubNavOpen);
  };

  const handleGraphIconClick = () => openDialog("graphbox");

  const getHeaderIcon = () => {
    switch (headerType) {
      case RESULT_TYPES.KEYWORD:
        return (
          <button className={styles.iconButton}>
            <MdOutlineAutoStories
              size={32}
              color="var(--keyword-color)"
              onClick={() => {
                window.location.href = `/keywords`;
              }}
              className={styles.icon}
            />
          </button>
        );
      case RESULT_TYPES.PLACE:
        return (
          <button className={styles.iconButton}>
            <MdFmdGood
              size={32}
              color="var(--place-color)"
              onClick={() => {
                window.location.href = `/cities`;
              }}
              className={styles.icon}
            />
          </button>
        );
      case RESULT_TYPES.TOPIC:
      default:
        return (
          <button className={styles.iconButton}>
            <BiBookBookmark
              size={32}
              color="var(--topic-color)"
              onClick={() => {
                window.location.href = `/topics`;
              }}
              className={styles.icon}
            />
          </button>
        );
    }
  };

  const getHeaderColor = () => {
    switch (headerType) {
      case RESULT_TYPES.KEYWORD:
        return { color: "var(--keyword-color)" };
      case RESULT_TYPES.PLACE:
        return { color: "var(--place-color)" };
      case RESULT_TYPES.TOPIC:
      default:
        return { color: "var(--topic-color)" };
    }
  };

  return (
    <>
      <header id="results-header" className={styles.container}>
        <div id="results-header-content" className={styles.containerContent}>
          <div className={styles.headerRowOne}>
            <div className={styles.headerTitle}>
              {getHeaderIcon()}
              <h1 style={getHeaderColor()}> {headerTitle} </h1>
            </div>
            {/* sub menu button hidden until more subfeatures are added/needed */}
            <button
              title="Result Menu"
              className={`${styles.resultNavButton} ${styles.hidden}`}
              aria-label="Result Menu"
              onClick={toggleSubNav}
              aria-hidden={true}
            >
              <i className="fa-solid fa-bars" aria-hidden="true"></i>
            </button>
          </div>

          {place && (
            <div className={styles.headerRowTwo}>
              <div className={styles.locationLink}>
                {place.type === "named" ? (
                  <button
                    className={`${styles.customLocationLink} ${styles.isSmaller}`}
                    onClick={() => {
                      window.location.href = `/${place.queryText}`;
                    }}
                  >
                    <div className={styles.locationIconWrapper}>
                      <MdFmdGood size={24} color="var(--place-color)" />
                    </div>
                    <p className={styles.locationText}>
                      Mentions near{" "}
                      <span className={styles.displayText}>
                        {place.displayText}
                      </span>
                    </p>
                  </button>
                ) : (
                  <p className={styles.zipText}>
                    Mentions near{" "}
                    <span className={styles.displayTextZip}>
                      {place.displayText}
                    </span>
                  </p>
                )}

                <button
                  className={styles.closeButton}
                  onClick={() => {
                    window.location.href = getSearchUrl();
                  }}
                >
                  ✕
                </button>
              </div>
            </div>
          )}

          {(startDate || endDate) && (
            <div className={styles.headerRowTwo}>
              <div className={styles.dateLink}>
                <div className={styles.dateLinkInfo}>
                  <div className={styles.date} />

                  <span className={styles.dateTextContainer}>
                    <strong className={styles.displayTextDate}>
                      {startDate}
                    </strong>{" "}
                    to{" "}
                    <strong className={styles.displayTextDate}>
                      {endDate}
                    </strong>
                  </span>
                </div>

                <button
                  className={styles.closeButtonDate}
                  onClick={() => {
                    window.location.href = getSearchUrl();
                  }}
                >
                  ✕
                </button>
              </div>
            </div>
          )}
        </div>
      </header>
      {headerType === RESULT_TYPES.KEYWORD && (
        <nav
          id="sub-nav"
          className={`${styles.subNav}  ${isSubNavOpen ? styles.open : ""}`}
          aria-label="sub menu"
        >
          {!place && (
            <div className={styles.graphButton} onClick={handleGraphIconClick}>
              <VscGraph size={32} color="var(--white-color)" />
            </div>
          )}
          <PrimaryButton
            id="openSubscribeDialogButton"
            size="xs"
            className={`${styles.btnSm} ${styles.subscribeButton}`}
            onClick={() => (window.location.href = subscribeUrl)}
          >
            {subscribeText}
          </PrimaryButton>
        </nav>
      )}
    </>
  );
};

export default ResultsHeader;
