import React, { createContext, useState, useContext, useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

const MediaQueryContext = createContext(null);

export const MediaQueryProvider = ({ children }) => {
  const [baseHeight, setBaseHeight] = useState(220);
  const isMobile = useMediaQuery("screen and (max-width: 1024px)");
  const mq480 = useMediaQuery("(max-width: 480px)");

  useEffect(() => {
    if (mq480) {
      setBaseHeight(270);
    } else if (isMobile) {
      setBaseHeight(250);
    } else {
      setBaseHeight(220);
    }
  }, [mq480, isMobile]);

  return (
    <MediaQueryContext.Provider value={{ mq480, isMobile, baseHeight }}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export const useMediaQueryContext = () => {
  const context = useContext(MediaQueryContext);
  if (context === null) {
    throw Error("useMediaQueryContext must be used within MediaQueryProvider");
  }
  return context;
};
