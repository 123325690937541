import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "./Carousel.css";

/**
 * Displays n/m pagination indicator.
 *
 * If m>1 and n is clicked, n turns into an input box for the user to jump
 * directly to a specific slide.
 *
 * By only using an input box for n when requested, we avoid an expensive
 * procedure of measuring the width of n via a useLayoutEffect.
 */
export const Pagination = ({ value: [value, setValue], numSlides }) => {
  let handleOnBlurFlag = true;

  // editMode becomes true when user clicks on the current page number, and
  // false when the input blurs or [enter] is pressed.
  const [editMode, setEditMode] = useState(false);
  const [inputBox, setInputBox] = useState(null);
  // used to measure the ideal size of the input box.
  const inputWidthMeasurerRef = useRef();

  // displayValue stores the current value of the input box (which may differ
  // from value).
  const [displayValue, setDisplayValue] = useState(value + 1);

  const handleOnChange = (event) => {
    if (event.target.value === "") {
      setDisplayValue(0); // display as 0
    } else if (/^\d+$/.test(event.target.value)) {
      const index = Number(event.target.value);
      setDisplayValue(index);
    }
  };

  const handleOnBlur = () => {
    if (handleOnBlurFlag) {
      setDisplayValue(value + 1);
      setEditMode(false);
    }
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      const index = Math.min(Math.max(displayValue, 1), numSlides);
      setDisplayValue(index);
      setValue(index - 1);
      setEditMode(false);
      // blur without calling handleOnBlur
      handleOnBlurFlag = false;
      document.activeElement.blur();
      handleOnBlurFlag = true;
    }
  };

  useEffect(() => {
    setDisplayValue(value + 1);
  }, [value]);

  useLayoutEffect(() => {
    if (!editMode || !inputBox || !inputWidthMeasurerRef?.current) {
      return;
    }
    const inputWidthMeasurer = inputWidthMeasurerRef.current;
    inputWidthMeasurer.innerHTML = displayValue;
    inputBox.style.width = inputWidthMeasurer.clientWidth + "px";
  }, [displayValue, editMode, inputBox]);

  useEffect(() => {
    // When we are in editMode, and the <input> DOM element exists, and
    // focus is not already in the input box, focus on it and select all the
    // text in the box.
    if (editMode && inputBox && document.activeElement !== inputBox) {
      // TODO: this might need to have .focus()?
      // TODO: test on mobile
      inputBox.select();
    }
  }, [editMode, inputBox]);

  return (
    <div className={styles.paginationContainer}>
      {editMode ? (
        <input
          ref={setInputBox}
          value={displayValue}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
        />
      ) : (
        <span
          onClick={numSlides > 1 ? () => setEditMode(true) : null}
          style={{ cursor: numSlides > 1 ? "pointer" : "default" }}
        >
          {displayValue}
        </span>
      )}
      <div className={styles.total}>{numSlides}</div>
      <div ref={inputWidthMeasurerRef} className={styles.inputWidthMeasurer} />
    </div>
  );
};
