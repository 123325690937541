import { fetchWithStatus } from "../utilities/funcs.js";

const getPlaceFromAPI = async (queryStr) => {
  try {
    const [res, status] = await fetchWithStatus(`get_place?${queryStr}`);
    if (status !== 200) {
      throw res;
    }
    const { lon, lat, query_id, display_name } = res;
    return [lon, lat, query_id, display_name];
  } catch {
    return null;
  }
};

class Location {
  static fromLonLat(lon, lat, res = 100) {
    return new Location("lon_lat", lon, lat, res, null, null);
  }

  static async fromLonLatStr(str, delim = ",") {
    const pattern = new RegExp(
      `^([^\\s${delim}]+)\\s*${delim}\\s*([^\\s${delim}]+)$`,
    );
    const matches = str.match(pattern);
    if (!matches) {
      return null;
    }
    if (isNaN(matches[1]) || isNaN(matches[2])) {
      return null;
    }
    const lon = Number(matches[1]),
      lat = Number(matches[2]);
    if (Math.abs(lon) > 180 || Math.abs(lat) > 90) {
      return null;
    }
    return new Location("lon_lat", lon, lat, 0, null, null);
  }

  static async fromZipCodeStr(str) {
    if (
      !str.match(/^\d{5}$/) &&
      !str.match(/^[A-Za-z]\d[A-Za-z](\s?\d[A-Za-z]\d)?$/)
    ) {
      return null;
    }
    const place = await getPlaceFromAPI(`zip_code=${str}`);
    if (place === null) {
      return null;
    }
    const [lon, lat] = place;
    return new Location("zip_code", lon, lat, 100, str, str);
  }

  static async fromQueryIdStr(str) {
    const place = await getPlaceFromAPI(`query_id=${str}`);
    if (place === null) {
      return null;
    }
    const [lon, lat, query_id, display_name] = place;
    return new Location("named", lon, lat, 100, display_name, query_id);
  }

  static async fromDisplayNameStr(str) {
    if (!str) {
      return null;
    }
    const place = await getPlaceFromAPI(`display_name=${str}`);
    if (place === null) {
      return null;
    }
    const [lon, lat, query_id, display_name] = place;
    return new Location("named", lon, lat, 100, display_name, query_id);
  }

  constructor(type, lon, lat, res, displayText, queryText) {
    this.type = type;
    this.lon = lon;
    this.lat = lat;
    if (res !== 0) {
      lon = Math.round(lon * res) / res;
      lat = Math.round(lat * res) / res;
    }
    this.displayText = displayText === null ? lon + ", " + lat : displayText;
    this.queryText = queryText === null ? lon + "_" + lat : queryText;
  }

  equals(location) {
    try {
      return this.lon === location.lon && this.lat === location.lat;
    } catch {
      return false;
    }
  }
}

export { Location };
