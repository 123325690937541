import React, { useState, useRef, useLayoutEffect } from "react";
import { classList as cl } from "../../utilities/funcs.js";

import styles from "./Input.css";

const Input = ({
  parent,
  value: [value, setValue],
  loading,
  placeholder,
  fa,
  color = "inherit",
  flexGrow,
  startWithFocus,
  setSuggestInput,
  setCurrentKeywordInput,
  onSearchComplete,
}) => {
  const spinnerFa = "fa-solid fa-spinner fa-fw fa-spin";

  const inputRef = useRef();

  const containerRef = useRef();

  const [inputValue, setInputValue] = useState("");

  const hasFocus = containerRef.current?.contains(document.activeElement);

  const handleClearInput = () => {
    setSuggestInput("");
    setInputValue("");
    // setValue("");
    inputRef.current?.focus();
  };

  const handleOnBlur = (e) => {
    if (!parent.current?.contains(e.relatedTarget)) {
      setSuggestInput("");
    }
  };

  const handleOnChange = (event) => {
    const val = event.target.value;
    setInputValue(val);
    setCurrentKeywordInput(val);
    setSuggestInput(val);
  };

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setValue(inputValue);
      setCurrentKeywordInput(inputValue);
      setSuggestInput("");
      onSearchComplete?.();
    }
  };

  useLayoutEffect(() => {
    if (startWithFocus) {
      inputRef?.current?.focus();
    }
    setSuggestInput("");
  }, [inputRef]);

  useLayoutEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div ref={containerRef} className={styles.container} style={{ flexGrow }}>
      <i className={loading ? spinnerFa : fa} style={{ color: color }} />
      <input
        ref={inputRef}
        type="text"
        value={loading ? "" : inputValue}
        disabled={loading}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        placeholder={loading ? "" : placeholder}
        spellCheck={false}
      />
      {hasFocus && inputValue !== "" && (
        <button
          className={cl("fa-regular", "fa-circle-xmark", styles.clearButton)}
          style={{ color: color }}
          title="Clear input"
          disabled={loading}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={() => handleClearInput()}
        />
      )}
    </div>
  );
};

export default Input;
