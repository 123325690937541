import React from "react";
import GlobalNav from "../layout/GlobalNavContent.js";
import AboutPage from "../pages/AboutPage/AboutPage.js";
import GlobalFooter from "../layout/GlobalFooterContent.js";
import styles from "../layout/App.css";

const About = () => (
  <div className={styles.pageContainer}>
    <GlobalNav isStickyByDefault />
    <AboutPage />
    <GlobalFooter />
  </div>
);

export default About;
