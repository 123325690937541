// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v3rEcGtGfkfFuJWMZDPf {
  position: relative;
  width: 300px;
  margin: 1rem 0;
  color: black;
  font-size: var(--step-1);
}

.KXPLPmXjPJU0EtnjUW7U {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
}

.iwrx4wbMmc1pKruVFhQ6 {
  position: absolute;
  top: 100%;
  background-color: var(--grey-color);
  width: 100%;
  border: none;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0.5rem;
  padding: 0;
  margin: 0;
}

.iwrx4wbMmc1pKruVFhQ6 li {
  color: (--white-color);
  padding: 0.5rem;
  cursor: pointer;
}

.iwrx4wbMmc1pKruVFhQ6 li:hover {
  color: var(--place-color);
  font-weight: bolder;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/forms/StateSelector.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,YAAY;EACZ,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,mCAAmC;EACnC,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".stateSelector {\n  position: relative;\n  width: 300px;\n  margin: 1rem 0;\n  color: black;\n  font-size: var(--step-1);\n}\n\n.stateInput {\n  width: 100%;\n  padding: 0.5rem;\n  font-size: 1rem;\n}\n\n.stateList {\n  position: absolute;\n  top: 100%;\n  background-color: var(--grey-color);\n  width: 100%;\n  border: none;\n  max-height: 200px;\n  overflow-y: auto;\n  border-radius: 0.5rem;\n  padding: 0;\n  margin: 0;\n}\n\n.stateList li {\n  color: (--white-color);\n  padding: 0.5rem;\n  cursor: pointer;\n}\n\n.stateList li:hover {\n  color: var(--place-color);\n  font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stateSelector": `v3rEcGtGfkfFuJWMZDPf`,
	"stateInput": `KXPLPmXjPJU0EtnjUW7U`,
	"stateList": `iwrx4wbMmc1pKruVFhQ6`
};
export default ___CSS_LOADER_EXPORT___;
