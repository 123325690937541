// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qwu3IOAKxlyBerngGO7C {
  text-decoration: none;
  color: var(--white-color);
  font-weight: bolder;
}

.UFddqU1P1pwbVl9nm6LN {
  text-decoration: none;
  color: var(--video-color);
  font-weight: bolder;
}

.BAp8qrwfELO4lrnqTVAR {
  text-decoration: none;
  color: var(--place-color);
  font-weight: bolder;
}
`, "",{"version":3,"sources":["webpack://./src/models/parser.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".link {\n  text-decoration: none;\n  color: var(--white-color);\n  font-weight: bolder;\n}\n\n.videoLink {\n  text-decoration: none;\n  color: var(--video-color);\n  font-weight: bolder;\n}\n\n.placeLink {\n  text-decoration: none;\n  color: var(--place-color);\n  font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `qwu3IOAKxlyBerngGO7C`,
	"videoLink": `UFddqU1P1pwbVl9nm6LN`,
	"placeLink": `BAp8qrwfELO4lrnqTVAR`
};
export default ___CSS_LOADER_EXPORT___;
