import React from "react";
import styles from "./AccordionHeader.css";
import { MdHorizontalRule } from "react-icons/md";
import { BiPlus } from "react-icons/bi";

const AccordionHeader = ({ isOpen, onToggle }) => (
  <div className={styles.accordionMenuBar}>
    <div className={styles.accordionHeaderWrapper}>
      <h2>Related Topics, Keywords, and Places</h2>
    </div>
    <button className={styles.accordionButton} onClick={onToggle}>
      {isOpen ? (
        <MdHorizontalRule size={32} color="var(--white-color)" />
      ) : (
        <BiPlus size={32} color="var(--white-color)" />
      )}
    </button>
  </div>
);

export default AccordionHeader;
