import React, { useState, createContext, useEffect, useContext } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { arrayEquals, toQueryString } from "../utilities/funcs.js";
import {
  encodeTopics,
  decodeTopics,
  encodeLocation,
  decodeLocation,
  encodeKeywords,
  decodeKeywords,
} from "./queryParams.js";
import App from "../layout/App.js";

const RouteHandler = ({ mode }) => {
  const windowLocation = useLocation();
  const queryParams = useParams();
  const navigate = useNavigate();

  const [topics, setTopics] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [location, setLocation] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [keywords, setKeywords] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [startDate, setStartDate] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [endDate, setEndDate] = useState({
    value: undefined,
    triggerEffect: false,
  });
  const [featuredTopic, setFeaturedTopic] = useState({
    value: undefined,
    triggerEffect: false,
  });

  useEffect(() => {
    if (
      !topics.triggerEffect &&
      !location.triggerEffect &&
      !keywords.triggerEffect
    ) {
      return;
    }

    const t = topics.value;
    const l = location.value;
    const k = keywords.value;

    if (t.length === 0 && !l && !k) {
      return;
    }

    const usingTopics = t.length > 0;
    const usingLocation = l !== null;
    const usingKeywords = k !== "";

    const route = (() => {
      if (usingTopics && usingLocation && usingKeywords) {
        return `/${encodeLocation(l)}/${encodeTopics(t)}/${encodeURIComponent(k)}`;
      }
      if (usingTopics && usingLocation) {
        return `/${encodeLocation(l)}/${encodeTopics(t)}`;
      }

      const params = {
        t: usingTopics ? encodeTopics(t) : undefined,
        l: usingLocation ? encodeLocation(l) : undefined,
        k: usingKeywords ? encodeKeywords(k) : undefined,
      };

      return `/search?${toQueryString(params)}`;
    })();

    navigate(route);
  }, [topics, location, keywords, navigate]);

  useEffect(() => {
    const setters = {
      handleSetTopics: (val, triggerEffect = false) => {
        if (topics.value === val || arrayEquals(topics.value, val)) {
          return;
        }
        setTopics({ value: val, triggerEffect });
      },

      handleSetLocation: (val, triggerEffect = false) => {
        if (location.value === val || location.value?.equals(val)) {
          return;
        }
        setLocation({ value: val, triggerEffect });
      },

      handleSetKeywords: (val, triggerEffect = false) => {
        if (keywords.value === val) {
          return;
        }
        if (!val) {
          document.title = "CivicSearch";
        } else {
          document.title = "CivicSearch: " + val;
        }
        setKeywords({ value: val, triggerEffect });
      },

      handleSetStartDate: (sd, triggerEffect = false) => {
        if (startDate.value !== sd) {
          setStartDate({ value: sd, triggerEffect });
        }
      },

      handleSetEndDate: (ed, triggerEffect = false) => {
        if (endDate.value !== ed) {
          setEndDate({ value: ed, triggerEffect });
        }
      },

      handleSetFeaturedTopic: (ft, triggerEffect = false) => {
        if (featuredTopic.value !== ft) {
          setFeaturedTopic({ value: ft, triggerEffect });
          setters.handleSetTopics([]);
          setters.handleSetLocation(null);
          document.title = "CivicSearch: " + ft;
        }
      },
    };

    const redirectOnError = () => {
      navigate("/");
      setters.handleSetTopics([]);
      setters.handleSetLocation(null);
      setters.handleSetKeywords("");
    };

    const handleSearch = async (t, l, k, start_date, end_date) => {
      const dt = decodeTopics(t);
      const dl = await decodeLocation(l);
      const dk = decodeKeywords(k);

      if (t !== undefined && dt.length === 0) {
        redirectOnError();
        return;
      } else if (l !== undefined && dl === null) {
        redirectOnError();
        return;
      } else if (k !== undefined && dk === "") {
        redirectOnError();
        return;
      }

      setters.handleSetKeywords(dk);
      setters.handleSetTopics(dt);
      setters.handleSetLocation(dl);
      setters.handleSetStartDate(start_date);
      setters.handleSetEndDate(end_date);
    };

    const handleByMode = {
      search: async () => {
        const params = new URLSearchParams(windowLocation.search);
        const t = params.has("t") ? params.get("t") : undefined;
        const l = params.has("l") ? params.get("l") : undefined;
        const k = params.has("k") ? params.get("k") : undefined;
        const start_date = params.has("start_date")
          ? params.get("start_date")
          : undefined;
        const end_date = params.has("end_date")
          ? params.get("end_date")
          : undefined;
        await handleSearch(t, l, k, start_date, end_date);
      },

      list_topics: async () => {
        const l = queryParams.location;
        const dl = await decodeLocation(l);
        if (dl === null || dl.type !== "named") {
          redirectOnError();
          return;
        }
        setters.handleSetTopics([]);
        setters.handleSetLocation(dl);
        setters.handleSetKeywords("");
      },

      show_results: async () => {
        const { topics: t, location: l, keywords: k } = queryParams;
        await handleSearch(t, l, k);
      },

      featured: async () => {
        setters.handleSetFeaturedTopic(queryParams.featuredTopic);
      },

      subscribe: async () => {
        const params = new URLSearchParams(windowLocation.search);
        const k = params.has("k") ? params.get("k") : undefined;
        const dk = decodeKeywords(k);
        setters.handleSetKeywords(dk);
        setters.handleSetTopics([]);
        setters.handleSetLocation(null);
      },

      invalid_route: () => {
        redirectOnError();
      },
    };

    if (mode in handleByMode) {
      handleByMode[mode]();
    } else {
      setters.handleSetTopics([]);
      setters.handleSetLocation(null);
      setters.handleSetKeywords("");
    }
    //TODO: Fix React Router Code
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowLocation, mode, navigate]);

  // Context value for external access
  const contextValue = {
    topics: topics.value,
    setTopics: (val) => setTopics({ value: val, triggerEffect: true }),
    location: location.value,
    setLocation: (val) => setLocation({ value: val, triggerEffect: true }),
    keywords: keywords.value,
    setKeywords: (val) => setKeywords({ value: val, triggerEffect: true }),
    startDate: startDate.value,
    endDate: endDate.value,
    featuredTopic: featuredTopic.value,
  };

  if (
    ((topics.value === undefined && keywords.value === undefined) ||
      location.value === undefined) &&
    !(mode === "featured" && featuredTopic.value !== undefined)
  ) {
    return null;
  }

  return (
    <RouteHandlerContext.Provider value={contextValue}>
      <App mode={mode} />
    </RouteHandlerContext.Provider>
  );
};

export const RouteHandlerContext = createContext(null);

export const useRouteHandlerContext = () => {
  const context = useContext(RouteHandlerContext);
  if (context === null) {
    throw Error(
      "useRouteHandlerContext must be used within RouteHandleProvider",
    );
  }
  return context;
};

export default RouteHandler;
