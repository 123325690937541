// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DMpp_7Tu3pxijNDesmcj {
  height: 80vh;
  justify-content: center;
  text-align: center;
  background-color: var(--md-grey-color);
  padding-top: 2rem;
  color: var(--white-color);
}

.DMpp_7Tu3pxijNDesmcj img {
  width: 70%;
  min-width: 280px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ErrorPage/ErrorPage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,uBAAuB;EACvB,kBAAkB;EAClB,sCAAsC;EACtC,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 80vh;\n  justify-content: center;\n  text-align: center;\n  background-color: var(--md-grey-color);\n  padding-top: 2rem;\n  color: var(--white-color);\n}\n\n.container img {\n  width: 70%;\n  min-width: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DMpp_7Tu3pxijNDesmcj`
};
export default ___CSS_LOADER_EXPORT___;
