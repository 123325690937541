import React, { useRef } from "react";

import LoadingPage from "../LoadingPage/LoadingPage.js";
import ErrorPage from "../ErrorPage/ErrorPage.js";

import styles from "./AllPlacesPage.css";
import { LocationLink } from "../../widgets/buttons/PillLink.js";
import Map, { getCityMarkerPopupHTML } from "../../widgets/Map/Map.js";
import { MdFmdGood } from "react-icons/md";
import StateSelector from "../../widgets/forms/StateSelector.js";
import { useApiGetPlaceList } from "../../hooks/api-hooks.js";

const sortPlaces = (places) => {
  return places.sort((a, b) => {
    let k1 = a.state_name + " " + a.name;
    let k2 = b.state_name + " " + b.name;
    return k1.localeCompare(k2);
  });
};

const groupByState = (places) => {
  return places.reduce((acc, place) => {
    if (!acc[place.state_name]) {
      acc[place.state_name] = [];
    }
    acc[place.state_name].push(place);
    return acc;
  }, {});
};

const AllPlacesPage = () => {
  const { data: places, error, isLoading } = useApiGetPlaceList();

  const stateRefs = useRef({});

  const scrollToState = (stateName) => {
    stateRefs.current[stateName]?.scrollIntoView({ behavior: "smooth" });
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const sortedPlaces = sortPlaces(places);
  const placesByState = groupByState(sortedPlaces);
  const states = Object.keys(placesByState);

  return (
    <div className={styles.allPlacesPage}>
      <section className={styles.aboutSection}>
        <div className={styles.topAbout}>
          <h1 className={styles.aboutSectionTitle}>
            <MdFmdGood
              size={32}
              color="var(--white-color)"
              style={{ marginRight: "5px" }}
            />
            Places
          </h1>
          <p>
            The map, and the list below, have links to all of the cities and
            towns with meetings indexed by CivicSearch. Click on a place to go
            to the &quot;home page&quot; for the place, which gives you insight
            specific to that location as well as a starting point for searching
            its meetings.
          </p>
        </div>
        <div className={styles.map}>
          <div className={styles.mapWrapper}>
            <Map
              places={places}
              getMarkerPopupHTML={getCityMarkerPopupHTML}
              initialLongitude={-96.5}
              initialLatitude={38.5}
            />
          </div>
        </div>
      </section>
      <section className={styles.pillLinkSection}>
        <div className={styles.pillSearchBar}>
          <StateSelector states={states} onStateSelect={scrollToState} />
        </div>
        <div className={styles.stateGroupWrapper}>
          {Object.entries(placesByState).map(([stateName, statePlaces]) => (
            <div
              key={stateName}
              className={styles.stateGroup}
              ref={(el) => (stateRefs.current[stateName] = el)}
            >
              <h2 className={styles.stateHeader}>{stateName}</h2>
              <div className={styles.citiesContainer}>
                {statePlaces.map((place) => (
                  <LocationLink
                    iSmaller
                    key={place.query_id}
                    url={`/${place.query_id}`}
                  >
                    {place.name.replaceAll(", " + place.state_name, "")}
                  </LocationLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default AllPlacesPage;
