import React, { useState, useEffect } from "react";
import styles from "./ResultsRelatedSection.css";
import {
  MdOutlineAutoStories,
  MdFmdGood,
  MdHorizontalRule,
} from "react-icons/md";
import { BiPlus, BiBookBookmark } from "react-icons/bi";
import { LocationLink, TopicLink, KeywordLink } from "./widgets/NewPillLink.js";
import { Topic } from "../models/topic.js";
import Accordion from "./widgets/Accordion.js";
import RESULT_TYPES from "../models/ResultTypes.js";

const RelatedSection = ({
  icon,
  iconColor,
  title,
  content,
  entityName,
  highlightColor,
  maxHeight = 250,
  resultType,
}) => {
  const renderTitle = () => {
    if (title.includes("{entityName}")) {
      const [firstPart, secondPart] = title.split("{entityName}");
      return (
        <>
          {firstPart}
          <span style={{ color: `var(${highlightColor})` }}>{entityName}</span>
          {secondPart}
        </>
      );
    }
    return (
      <>
        {title}{" "}
        <span style={{ color: `var(${highlightColor})` }}>{entityName}</span>
      </>
    );
  };

  return (
    <div className={styles.relatedInfoBox}>
      <h2>
        <div>
          {React.cloneElement(icon, { size: 24, color: `var(${iconColor})` })}
        </div>
        {renderTitle()}
      </h2>
      <Accordion
        maxHeight={maxHeight}
        className={styles.customAccordion}
        expandButtonClassName={styles.customAccordionExpand}
        resultType={resultType}
      >
        <div className={styles.pillLinkList}>{content}</div>
      </Accordion>
    </div>
  );
};

const ResultsRelatedSection = ({
  entityName,
  loading,
  results,
  keywords,
  location,
  topics,
  resultType,
  summaryData,
  place,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsAccordionOpen(!window.matchMedia("(max-width: 1024px)").matches);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let relatedKeywordsContent = [];
  let relatedTopicsContent = [];
  let relatedLocationsContent = [];

  const renderRelatedKeywords = () => {
    if (
      loading ||
      !results ||
      !results.related_keywords ||
      results.related_keywords.length == 0
    ) {
      return null;
    }
    return (
      <div className={styles.relatedInfo}>
        {results.related_keywords.slice(0, 10).map((kw, i) => {
          return (
            <KeywordLink
              size="smaller"
              key={i}
              url={`/search?k=${kw}${location ? "&l=" + location.queryText : ""}`}
            >
              {kw}
            </KeywordLink>
          );
        })}
      </div>
    );
  };

  const renderLocationsRelatedToSearch = () => {
    if (
      loading ||
      !results ||
      !results.location_counts ||
      results.location_counts.length == 0 ||
      (location != null && location.type === "named")
    ) {
      return null;
    }
    return (
      <div className={styles.relatedInfo}>
        {results.location_counts.map((location_count_pair, i) => {
          let location_query_id = location_count_pair[0][0];
          let location_display = location_count_pair[0][1];

          return (
            <LocationLink
              size="smaller"
              key={i}
              url={`/search?k=${entityName}&l=${location_query_id}`}
            >
              {location_display}
            </LocationLink>
          );
        })}
      </div>
    );
  };

  const renderTopicsRelatedToSearch = () => {
    if (
      loading ||
      location != null ||
      !results ||
      !results.topic_counts ||
      (topics && topics.length > 0) ||
      results.topic_counts.length == 0
    ) {
      // Don't show this component for location-specific queries
      return null;
    }
    return (
      <div className={styles.relatedInfo}>
        {results.topic_counts.slice(0, 10).map((topicid_count, i) => {
          let topic = Topic.fromId(topicid_count[0]);
          return (
            <TopicLink
              size="smaller"
              key={i}
              url={`/search?t=${topic.queryText}${location ? "&l=" + location.queryText : ""}`}
            >
              {topic.displayText}
            </TopicLink>
          );
        })}
      </div>
    );
  };

  if (resultType === RESULT_TYPES.KEYWORD) {
    relatedKeywordsContent = renderRelatedKeywords();
    relatedTopicsContent = renderTopicsRelatedToSearch();
    relatedLocationsContent = renderLocationsRelatedToSearch();
  }

  let similar_topics = [];
  let top_places = [];
  let topic_keywords = [];

  if (resultType === RESULT_TYPES.TOPIC && topics) {
    const getSimilarTopics = () => {
      if (summaryData.similar_topics != null) {
        return summaryData.similar_topics.map((x) =>
          Topic.fromId(x["topic_id"]),
        );
      } else {
        return [];
      }
    };

    const getTopicKeywords = () => {
      if (summaryData.keywords != null) {
        return summaryData.keywords;
      } else {
        return [];
      }
    };

    const getSimilarPlaces = () => {
      if (summaryData.similar_places != null) {
        return summaryData.similar_places;
      } else {
        return [];
      }
    };

    similar_topics = getSimilarTopics();
    top_places = getSimilarPlaces();
    topic_keywords = getTopicKeywords();
  }

  const highlightColor = topics ? "--topic-color" : "--keyword-color";

  const hasKeywordContent =
    resultType === RESULT_TYPES.KEYWORD &&
    (relatedKeywordsContent || relatedTopicsContent || relatedLocationsContent);
  const hasTopicContent =
    resultType === RESULT_TYPES.TOPIC &&
    (topic_keywords.length > 0 ||
      similar_topics.length > 0 ||
      top_places.length > 0);
  const hasPlaceContent =
    resultType === RESULT_TYPES.PLACE &&
    topics &&
    (topics.issue_keywords?.length > 0 ||
      topics.keywords?.length > 0 ||
      topics.similar_places?.length > 0);

  if (hasKeywordContent || hasTopicContent || hasPlaceContent) {
    return (
      <>
        <div className={styles.accordionMenuBar}>
          <div className={styles.accordionHeaderWrapper}>
            <h2>Related Topics, Keywords, and Places</h2>
          </div>
          <button className={styles.accordionButton} onClick={toggleAccordion}>
            {isAccordionOpen ? (
              <MdHorizontalRule size={32} color="var(--white-color)" />
            ) : (
              <BiPlus size={32} color="var(--white-color)" />
            )}
          </button>
        </div>

        <section
          id="related-results"
          className={`${styles.relatedResults} ${isAccordionOpen ? styles.open : styles.closed}`}
        >
          {resultType === RESULT_TYPES.KEYWORD && (
            <>
              {relatedLocationsContent && (
                <RelatedSection
                  icon={<MdFmdGood />}
                  iconColor="--place-color"
                  title="View {entityName} Mentioned in These Places"
                  content={relatedLocationsContent}
                  entityName={entityName}
                  highlightColor="--keyword-color"
                  resultType={resultType}
                />
              )}

              {relatedTopicsContent && (
                <RelatedSection
                  icon={<BiBookBookmark />}
                  iconColor="--topic-color"
                  title="Topics Related to"
                  content={relatedTopicsContent}
                  entityName={entityName}
                  highlightColor="--keyword-color"
                  resultType={resultType}
                />
              )}
              {relatedKeywordsContent && (
                <RelatedSection
                  icon={<MdOutlineAutoStories style={{ marginTop: "5px" }} />}
                  iconColor="--keyword-color"
                  title="Keywords Related to"
                  content={relatedKeywordsContent}
                  entityName={entityName}
                  highlightColor="--keyword-color"
                  resultType={resultType}
                />
              )}
            </>
          )}
          {resultType === RESULT_TYPES.TOPIC && (
            <>
              <RelatedSection
                icon={<MdFmdGood />}
                iconColor="--place-color"
                title="Top Places discussingss"
                content={top_places.map((location, i) => (
                  <LocationLink
                    key={i}
                    size="smaller"
                    url={`/${location.query_id}/`}
                  >
                    {location.display_name}
                  </LocationLink>
                ))}
                entityName={entityName}
                highlightColor={highlightColor}
                maxHeight={250}
                resultType={resultType}
              />

              <RelatedSection
                icon={<BiBookBookmark />}
                iconColor="--topic-color"
                title="Topics Related to"
                content={similar_topics.map((topic, i) => (
                  <TopicLink
                    key={i}
                    size="smaller"
                    url={`/search?t=${topic.queryText}`}
                  >
                    {topic.displayText}
                  </TopicLink>
                ))}
                entityName={entityName}
                highlightColor={highlightColor}
                maxHeight={250}
                resultType={resultType}
              />

              <RelatedSection
                icon={<MdOutlineAutoStories style={{ marginTop: "5px" }} />}
                iconColor="--keyword-color"
                title="Keywords Related to"
                content={topic_keywords.map((kw, i) => (
                  <KeywordLink key={i} size="smaller" url={`/search?k=${kw}`}>
                    {kw}
                  </KeywordLink>
                ))}
                entityName={entityName}
                highlightColor={highlightColor}
                maxHeight={250}
                resultType={resultType}
              />
            </>
          )}
          {resultType === RESULT_TYPES.PLACE && (
            <>
              <RelatedSection
                icon={<MdOutlineAutoStories style={{ marginTop: "5px" }} />}
                iconColor="--keyword-color"
                title="Top Discussed Issues in {entityName} Transcripts "
                entityName={entityName}
                content={topics.issue_keywords.map((kw, i) => (
                  <KeywordLink
                    key={i}
                    url={`/search?k=${kw}&l=${place.queryText}`}
                    className={styles.pillLink}
                  >
                    {kw}
                  </KeywordLink>
                ))}
                highlightColor="--place-color"
                maxHeight={350}
                resultType={resultType}
              />

              <RelatedSection
                icon={<MdOutlineAutoStories />}
                iconColor="--keyword-color"
                title="Words and Phrases Distinctive to {entityName} Meeting Transcripts"
                entityName={entityName}
                content={topics.keywords.map((kw, i) => (
                  <KeywordLink
                    key={i}
                    url={`/search?k=${kw}&l=${place.queryText}`}
                    className={styles.pillLink}
                  >
                    {kw}
                  </KeywordLink>
                ))}
                highlightColor="--place-color"
                maxHeight={350}
                resultType={resultType}
              />

              <RelatedSection
                icon={<MdFmdGood />}
                iconColor="--place-color"
                title="Similar Places Based on Discussion Topics"
                content={topics.similar_places.map((similar_place_obj, i) => (
                  <LocationLink
                    key={i}
                    url={`/${similar_place_obj.query_id}`}
                    className={styles.pillLink}
                  >
                    {similar_place_obj.display_name}
                  </LocationLink>
                ))}
                highlightColor="--place-color"
                maxHeight={350}
                resultType={resultType}
              />
            </>
          )}
        </section>
      </>
    );
  } else {
    return null;
  }
};

export default ResultsRelatedSection;
