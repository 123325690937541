import React, { useEffect, useRef, useState } from "react";

import ErrorPage from "../ErrorPage/ErrorPage.js";
import ResultsHeader from "./components/ResultsHeader.js";
import ResultsRelatedSection from "./components/ResultsRelatedSection/ResultsRelatedSection.js";
import ResultsSummarySection from "./components/ResultsSummarySection.js";
import SearchResultsList from "./components/SearchResultsList/SearchResultsList.js";
import TranscriptsVideoSection from "./components/TranscriptVideoSection/TranscriptsVideoSection.js";

import { useMediaQueryContext } from "../../context/MediaQueryContext.js";
import RESULT_TYPES from "../../models/ResultTypes.js";
import {
  getSummaryData,
  handleScrollToVideoSection,
  processEmbedResults,
} from "../../utilities/resultsHelper.js";

import styles from "./ResultsPage.css";
import {
  useApiFetchSummaryResults,
  useApiSearch,
} from "../../hooks/api-hooks.js";

const handleMoreClick = (leftSectionRef, videoSectionRef) => {
  if (window.innerWidth > 1024) {
    handleScrollToVideoSection(leftSectionRef, videoSectionRef);
  }
};

const ResultsTopicPage = ({
  topics,
  location,
  setLocation,
  keywords,
  startDate,
  endDate,
}) => {
  const [searchRequestFailed, setSearchRequestFailed] = useState(false);
  const [embedVideoId, setEmbedVideoId] = useState(null);
  const [embedVideoTitle, setEmbedVideoTitle] = useState(null);
  const [embedVideoUrl, setEmbedVideoUrl] = useState(null);
  const [embedTimestamp, setEmbedTimestamp] = useState(null);
  const [autoplayEmbed, setAutoplayEmbed] = useState(false);

  const { isMobile } = useMediaQueryContext();

  const leftSectionRef = useRef(null);
  const videoSectionRef = useRef(null);

  const searchParams = { topics, location, keywords, startDate, endDate };

  const {
    data: results,
    error: resultsError,
    isLoading,
  } = useApiSearch(searchParams);

  const {
    data: summaryResults,
    error: summaryError,
    isLoading: isSummaryLoading,
  } = useApiFetchSummaryResults(topics, location);

  useEffect(() => {
    if (results) {
      const { videoId, timestamp, videoTitle, videoUrl } =
        processEmbedResults(results);
      setEmbedVideoId(videoId);
      setEmbedTimestamp(timestamp);
      setEmbedVideoTitle(videoTitle);
      setSearchRequestFailed(false);
      if (videoUrl) setEmbedVideoUrl(videoUrl);
    }
  }, [results]);

  const handleVideoSelect = (videoId, timestamp, videoTitle, videoUrl) => {
    setEmbedVideoId(videoId);
    setEmbedTimestamp(timestamp);
    setAutoplayEmbed(true);
    setEmbedVideoTitle(videoTitle);

    handleMoreClick(timestamp);

    if (videoUrl) setEmbedVideoUrl(videoUrl);
  };

  const headerTitle = isSummaryLoading
    ? "Loading..."
    : summaryResults?.topic_name;

  const headerComponent = (
    <ResultsHeader
      headerTitle={headerTitle}
      keywords={keywords}
      place={location}
      headerType={RESULT_TYPES.TOPIC}
      startDate={startDate}
      endDate={endDate}
    />
  );

  const relatedSectionComponent = (
    <ResultsRelatedSection
      entityName={headerTitle}
      location={location}
      topics={topics}
      loading={isSummaryLoading}
      summaryData={summaryResults}
      resultType={RESULT_TYPES.TOPIC}
    />
  );

  const mainContentComponent = (
    <>
      <ResultsSummarySection
        getSummaryData={() =>
          getSummaryData(isSummaryLoading, summaryResults, summaryError)
        }
        headerTitle={headerTitle}
        resultType={RESULT_TYPES.TOPIC}
        isLoading={isSummaryLoading}
      />

      <div className={styles.stickyVideoWrapper}>
        <TranscriptsVideoSection
          embedVideoId={embedVideoId}
          embedTimestamp={embedTimestamp}
          embedVideoTitle={embedVideoTitle}
          embedVideoUrl={embedVideoUrl}
          autoplayEmbed={autoplayEmbed}
          keywords={keywords}
          summaryResults={summaryResults}
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoading}
          results={results}
          searchRequestFailed={searchRequestFailed}
          resultType={RESULT_TYPES.TOPIC}
        />
      </div>
      <div className={styles.scrollableContent}>
        <div className={styles.transcriptsHeader}>
          Relevant remarks from meeting transcripts
        </div>
        <ul className={styles.container}>
          <SearchResultsList
            results={results}
            isLoading={isLoading}
            searchRequestFailed={searchRequestFailed}
            location={location}
            setLocation={setLocation}
            onVideoSelect={handleVideoSelect}
          />
        </ul>
      </div>
    </>
  );

  if (resultsError) {
    return (
      <ErrorPage
        header="An error occurred"
        message={`${resultsError?.message || "Failed to load content"}`}
      />
    );
  }

  return (
    <div className={styles.resultsPage}>
      {!isMobile ? (
        <>
          <section className={styles.leftSection} ref={leftSectionRef}>
            {headerComponent}
            {relatedSectionComponent}
          </section>
          <section className={styles.resultsSection} ref={videoSectionRef}>
            {mainContentComponent}
          </section>
        </>
      ) : (
        <section className={styles.resultsSection}>
          {headerComponent}
          {relatedSectionComponent}
          {mainContentComponent}
        </section>
      )}
    </div>
  );
};

export default ResultsTopicPage;
