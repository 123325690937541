import React from "react";
import styles from "./NewPillLink.css";

const getPillLinkClassName = (size, customClass) => {
  let sizeClass = styles.pillLink; // Default size
  if (size === "smaller") {
    sizeClass = styles.pillLinkSmaller;
  } else if (size === "extraSmall") {
    sizeClass = styles.pillLinkExtraSmall; // New class for extra small
  }
  return `${styles.pillLinkBase} ${sizeClass} ${customClass || ""}`;
};

export const KeywordLink = ({ children, url, size = "default", className }) => (
  <a href={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.keywords}`}>
      {children}
    </span>
  </a>
);

export const LocationLink = ({
  children,
  url,
  size = "default",
  className,
}) => (
  <a href={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.location}`}>
      {children}
    </span>
  </a>
);

export const TopicLink = ({ children, url, size = "default", className }) => (
  <a href={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.topic}`}>{children}</span>
  </a>
);

export const SubscribeLink = ({
  children,
  url,
  size = "default",
  className,
}) => (
  <a href={url} className={getPillLinkClassName(size, className)}>
    <span className={`${styles.pillLinkText} ${styles.topic}`}>{children}</span>
  </a>
);
