import React from "react";
import { Link } from "react-router-dom";
import Accordion from "../../../widgets/Accordion/Accordion.js";
import styles from "./MeetingsSection.css";

const MeetingsSection = ({ isLoading, topics, place }) => {
  return (
    <section className={styles.cityMeetingSection}>
      <Accordion maxHeight={300} recalculateOnMount={true} requireMeasurement>
        <div className={`${isLoading ? styles.meetingSectionSkeleton : null}`}>
          <h2>
            {!isLoading ? (
              <>
                {topics["recent_meetings"].length} Public Meetings in{" "}
                <span className={styles.displayText}>{place.displayText}</span>
              </>
            ) : (
              <>
                Loading Meetings From{" "}
                <span className={styles.displayText}>{place.displayText}</span>
              </>
            )}
          </h2>

          {!isLoading && (
            <>
              {topics["recent_meetings"].map((meeting, i) => (
                <div className={styles.meetingRow} key={i}>
                  <div className={styles.dateIcon}>
                    <li className={styles.date} />
                    <div>
                      {meeting.meeting_date}
                      {": "}
                    </div>
                  </div>
                  <div className={styles.linkRow}>
                    <li className={styles.more} />
                    <Link
                      to={
                        meeting.vid_url ||
                        `https://www.youtube.com/watch?v=${meeting.vid_id}`
                      }
                      className={styles.linkNoUnderline}
                    >
                      <span>{meeting.vid_title}</span>
                    </Link>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Accordion>
    </section>
  );
};

export default MeetingsSection;
