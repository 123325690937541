// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoIL3Anp22sbMSSpk4X3 {
  background-color: transparent;
  border: solid 0.25em var(--accent-color);
  color: var(--accent-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  font-family: var(--button-font);
  font-weight: var(--button-font-weight);
  border-radius: var(--button-corner-radius);
  text-decoration: none;
  box-shadow: var(--button-shadow);
  transition: var(--global-nav-transition);
  cursor: pointer;
}

.GoIL3Anp22sbMSSpk4X3:hover {
  background: var(--selection-bg-color);
  color: var(--selection-color);
  text-shadow: none;
}

.cind6hwkkvJ8zUWdmhBi {
  font-size: var(--step--1);
  line-height: var(--step-0);
  gap: var(--space-3xs);
  padding: var(--space-xs) var(--space-s);
}

.oeXUqBdVjdPY0D7O8Nmf {
  gap: var(--space-2xs);
  font-size: var(--step-0);
  line-height: var(--step-1);
  padding: var(--space-xs) var(--space-m);
}

.wtuGWeQkjasbhYaCENd6 {
  padding: var(--space-xs) var(--space-l);
  font-size: var(--step-0);
  line-height: var(--step-1);
}

.Y6z_59uc1V5FpZsNk_ch {
  color: var(--disabled-bg-color-darker);
  border-color: var(--disabled-bg-color-darker);
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/buttons/SecondaryButton.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,wCAAwC;EACxC,0BAA0B;EAC1B,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;EAClB,+BAA+B;EAC/B,sCAAsC;EACtC,0CAA0C;EAC1C,qBAAqB;EACrB,gCAAgC;EAChC,wCAAwC;EACxC,eAAe;AACjB;;AAEA;EACE,qCAAqC;EACrC,6BAA6B;EAC7B,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,qBAAqB;EACrB,uCAAuC;AACzC;;AAEA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,0BAA0B;EAC1B,uCAAuC;AACzC;;AAEA;EACE,uCAAuC;EACvC,wBAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE,sCAAsC;EACtC,6CAA6C;EAC7C,YAAY;EACZ,mBAAmB;EACnB,oBAAoB;AACtB","sourcesContent":[".btnSecondary {\n  background-color: transparent;\n  border: solid 0.25em var(--accent-color);\n  color: var(--accent-color);\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  vertical-align: middle;\n  text-align: center;\n  font-family: var(--button-font);\n  font-weight: var(--button-font-weight);\n  border-radius: var(--button-corner-radius);\n  text-decoration: none;\n  box-shadow: var(--button-shadow);\n  transition: var(--global-nav-transition);\n  cursor: pointer;\n}\n\n.btnSecondary:hover {\n  background: var(--selection-bg-color);\n  color: var(--selection-color);\n  text-shadow: none;\n}\n\n.btn-sm {\n  font-size: var(--step--1);\n  line-height: var(--step-0);\n  gap: var(--space-3xs);\n  padding: var(--space-xs) var(--space-s);\n}\n\n.btn-md {\n  gap: var(--space-2xs);\n  font-size: var(--step-0);\n  line-height: var(--step-1);\n  padding: var(--space-xs) var(--space-m);\n}\n\n.btn-lg {\n  padding: var(--space-xs) var(--space-l);\n  font-size: var(--step-0);\n  line-height: var(--step-1);\n}\n\n.disabled {\n  color: var(--disabled-bg-color-darker);\n  border-color: var(--disabled-bg-color-darker);\n  opacity: 0.5;\n  cursor: not-allowed;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnSecondary": `GoIL3Anp22sbMSSpk4X3`,
	"btn-sm": `cind6hwkkvJ8zUWdmhBi`,
	"btn-md": `oeXUqBdVjdPY0D7O8Nmf`,
	"btn-lg": `wtuGWeQkjasbhYaCENd6`,
	"disabled": `Y6z_59uc1V5FpZsNk_ch`
};
export default ___CSS_LOADER_EXPORT___;
