import React from "react";

import styles from "./FeaturedTopicPage.css";
import LoadingPage from "../LoadingPage/LoadingPage.js";
import ErrorPage from "../ErrorPage/ErrorPage.js";
import { parse } from "../../models/parser.js";
import { useApiGetFeaturedTopic } from "../../hooks/api-hooks.js";

const FeaturedTopicPage = ({ featuredTopic }) => {
  const {
    data: featuredInfo,
    error,
    isLoading,
  } = useApiGetFeaturedTopic(featuredTopic);

  if (isLoading) {
    return <LoadingPage />;
  } else if (error) {
    return <ErrorPage />;
  }

  return (
    <div className={styles.featuredTopicPage}>
      <div className={`${styles.container} content-container`}>
        {parse(featuredInfo.html)}
      </div>
    </div>
  );
};

export default FeaturedTopicPage;
