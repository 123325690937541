import React, { useState, useEffect } from "react";
import styles from "./stateSelector.css";

const StateSelector = ({ states, onStateSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStates, setFilteredStates] = useState(states);

  useEffect(() => {
    setFilteredStates(
      states.filter((state) =>
        state.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, states]);

  const handleSelectState = (state) => {
    onStateSelect(state);
    setSearchTerm("");
  };

  return (
    <div className={styles.stateSelector}>
      <input
        type="text"
        placeholder="Type to find a state or province..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.stateInput}
      />
      {searchTerm && (
        <ul className={styles.stateList}>
          {filteredStates.map((state) => (
            <li key={state} onClick={() => handleSelectState(state)}>
              {state}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default StateSelector;
