// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PffQLtmP4oYhY6D_BsL0 {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: space-between;
  gap: var(--space-2xs);
  background-color: var(--primary-color);
  padding: var(--space-s);
  border-radius: 1em;
  box-shadow: 0 0.5em 0.5em 0 rgba(0, 0, 0, 0.16);
  max-height: calc(50cqh - calc(var(--global-nav-height) * 1 + var(--space-l)));
  min-height: calc(var(--space-5xl) + var(--space-3xs));
  overflow-y: auto;
  transition: var(--global-nav-transition);
  position: absolute;
  min-width: 16rem;
  max-width: 20rem;
  z-index: 999;
}

.TYSHXiuXByxfQ7ANNQHw {
  display: block;
}

.PffQLtmP4oYhY6D_BsL0 h2 {
  font-size: var(--step--0);
  color: var(--white-color);
  text-align: start;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/SugesstionBox/SuggestionBox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,8BAA8B;EAC9B,0BAA0B;EAC1B,qBAAqB;EACrB,sCAAsC;EACtC,uBAAuB;EACvB,kBAAkB;EAClB,+CAA+C;EAC/C,6EAA6E;EAC7E,qDAAqD;EACrD,gBAAgB;EAChB,wCAAwC;EACxC,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: nowrap;\n  justify-content: space-between;\n  align-items: space-between;\n  gap: var(--space-2xs);\n  background-color: var(--primary-color);\n  padding: var(--space-s);\n  border-radius: 1em;\n  box-shadow: 0 0.5em 0.5em 0 rgba(0, 0, 0, 0.16);\n  max-height: calc(50cqh - calc(var(--global-nav-height) * 1 + var(--space-l)));\n  min-height: calc(var(--space-5xl) + var(--space-3xs));\n  overflow-y: auto;\n  transition: var(--global-nav-transition);\n  position: absolute;\n  min-width: 16rem;\n  max-width: 20rem;\n  z-index: 999;\n}\n\n.suggestion {\n  display: block;\n}\n\n.container h2 {\n  font-size: var(--step--0);\n  color: var(--white-color);\n  text-align: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PffQLtmP4oYhY6D_BsL0`,
	"suggestion": `TYSHXiuXByxfQ7ANNQHw`
};
export default ___CSS_LOADER_EXPORT___;
