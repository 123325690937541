import React, { useState } from "react";
import styles from "./SubscribeSection.css";
import subscribeImg from "../../../imgs/subscribe-to-civicsearch.svg";
import PrimaryButton from "../../../widgets/buttons/PrimaryButton.js";
import Input from "../../../widgets/forms/Input.js";

const SubscribeSection = () => {
  const [email, setEmail] = useState("");
  const [zip, setZip] = useState("");

  return (
    <>
      <section id="subscribe" className={styles.subscribe}>
        <div className={styles.sectionContentPadding}>
          <div className={`${styles.uContainer} ${styles.sectionContent}`}>
            <div className={styles.colL}>
              <img src={subscribeImg} alt="Subscribe to CivicSearch" />
            </div>
            <div className={styles.colR}>
              <h2>Alert me!</h2>
              <p>
                Get updates based on topic, keyword, or location. Don’t miss
                what’s happening in local politics.
              </p>
              <form
                id="home-subscribe"
                action="https://civicsearch.us14.list-manage.com/subscribe/post?u=072876ecccbe8f476c67f0bdc&amp;id=460b3d01f7&amp;f_id=00f1a5e0f0"
                method="post"
                name="mc-embedded-subscribe-form"
                target="_blank"
              >
                <div className={styles.inputGroupWrapper}>
                  <div className={styles.inputGroupEmail}>
                    <Input
                      className={styles.emailInput}
                      label="Email"
                      isRequired
                      type="email"
                      name="EMAIL"
                      id="mce-EMAIL"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className={styles.errorMessageField}>
                      Please provide a valid email address
                    </div>
                  </div>
                  <div
                    className={`$ ${styles.inputGroupZip} ${styles.shortInput}`}
                  >
                    <Input
                      className={styles.zipInput}
                      label="ZIP/Postal Code"
                      type="text"
                      name="MMERGE6"
                      id="mce-MMERGE6"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                </div>
                <div id="mce-home-responses">
                  <div
                    id="mce-home-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    id="mce-home-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  <input
                    type="text"
                    name="b_072876ecccbe8f476c67f0bdc_460b3d01f7"
                    tabIndex="-1"
                    value=""
                    readOnly
                  />
                </div>
                <div className={styles.btnGroup}>
                  <PrimaryButton
                    type="submit"
                    id="openSubscribeDialogButton"
                    size="md"
                    className={styles.subscribeButton}
                  >
                    Subscribe
                  </PrimaryButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={styles.pageDownBtnWrapper}>
          <a
            href="#hero-home"
            className={styles.pageDownBtn}
            role="button"
            title="Scroll to Top"
            aria-label="scroll to top"
          >
            <i className="fa-solid fa-circle-chevron-up" aria-hidden="true"></i>
          </a>
        </div>
      </section>
    </>
  );
};

export default SubscribeSection;
