import React from "react";
import ReactDOMServer from "react-dom/server";
import PrimaryButton from "../../../../widgets/buttons/PrimaryButton.js";

function MailingListSignupHTML(querySource) {
  let title = "Stay Informed with CivicSearch";
  let desc =
    "We'll send you updates (at most weekly) about town and city council meetings in the US. (See <a href=\"/feature/all\">past issues</a>). If you provide a zip code, we'll tailor the updates to that location.";

  if (querySource) {
    title = `Stay up-to-date on "${querySource}"`;
    desc = `We'll send you updates (at most weekly) about ${querySource}. If you provide a zip code, we'll tailor the updates to that location. If you've already subscribed, we'll add this query to your digest.`;
  }

  const MailchimpForm = () => (
    <>
      <div
        id="mc_embed_shell"
        style={{
          color: "var(--white-color)",
        }}
      >
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <div
          id="mc_embed_signup"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "2rem",
          }}
        >
          <form
            action="https://civicsearch.us14.list-manage.com/subscribe/post?u=072876ecccbe8f476c67f0bdc&amp;id=460b3d01f7&amp;f_id=00f1a5e0f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
          >
            <div id="mc_embed_signup_scroll">
              <center>
                <h2
                  style={{
                    color: "var(--white-color)",
                    fontSize: "var(--step-3)",
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </h2>
              </center>
              {!querySource ? (
                <div
                  style={{
                    width: "100%",
                    lineHeight: "var(--base-line-height)",
                    textWrap: "balance",
                    marginTop: "2rem",
                    textAlign: "center",
                    fontSize: "var(--step-1)",
                    paddingBottom: "3rem",
                  }}
                >
                  <span>
                    You can subscribe to specific keywords! Just search for the
                    keyword in the search bar above and click the Subscribe
                    button. If you&apos;ve already subscribed, the keyword will
                    be added to your updates.
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email address <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  required
                  autoComplete="email"
                  value=""
                  style={{
                    color: "var(--black-color)",
                    fontSize: "var(--step-1)",
                    height: "3rem",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    border: "none",
                    borderRadius: "5px",
                  }}
                />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-MMERGE6">
                  Zip / postal code (optional)
                </label>
                <input
                  type="zip"
                  name="MMERGE6"
                  className="zip"
                  id="mce-MMERGE6"
                  value=""
                  style={{
                    color: "var(--black-color)",
                    fontSize: "var(--step-1)",
                    height: "3rem",
                    backgroundColor: "rgba(255, 255, 255, 0.3)",
                    border: "none",
                    borderRadius: "5px",
                  }}
                />
                <input
                  type="hidden"
                  className="text"
                  name="MMERGE7"
                  id="mce-MMERGE7"
                  value={querySource}
                />
              </div>
              <div id="mce-responses" className="foot">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                />
              </div>
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                <input
                  type="text"
                  name="b_072876ecccbe8f476c67f0bdc_460b3d01f7"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <div className="optionalParent">
                <div style={{ marginTop: "20px" }}>
                  <PrimaryButton
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    size="md"
                  >
                    Subscribe
                  </PrimaryButton>
                </div>
                <div
                  style={{
                    width: "100%",
                    lineHeight: "var(--base-line-height)",
                    textWrap: "balance",
                    marginTop: "2rem",
                    marginBottom: "5rem",
                    maxWidth: "50rem",
                  }}
                >
                  <span
                    id="mce-MMERGE6-HELPERTEXT"
                    // className="helper_text"
                    dangerouslySetInnerHTML={{ __html: desc }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <script
          type="text/javascript"
          src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
        />
        <script type="text/javascript">
          {`
          (function($) {
            window.fnames = new Array();
            window.ftypes = new Array();
            fnames[0]='EMAIL';ftypes[0]='email';
            fnames[6]='MMERGE6';ftypes[6]='zip';
            fnames[1]='FNAME';ftypes[1]='text';
            fnames[2]='LNAME';ftypes[2]='text';
            fnames[3]='ADDRESS';ftypes[3]='address';
            fnames[4]='PHONE';ftypes[4]='phone';
            fnames[5]='BIRTHDAY';ftypes[5]='birthday';
          }(jQuery));
          var $mcj = jQuery.noConflict(true);
        `}
        </script>
      </div>
    </>
  );

  const htmlString = ReactDOMServer.renderToString(<MailchimpForm />);
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
}

export default MailingListSignupHTML;
