import { Topic } from "../models/topic.js";
import RESULT_TYPES from "../models/ResultTypes.js";

export const getResultToEmbed = (results) => {
  if (results.num_results === 0) return null;
  let resultIdx = 0;
  let maxInteractionCount = 0;
  let maxInteractionIdx = 0;
  while (resultIdx < results.num_results) {
    const viewCount = results.results[resultIdx].viewCount;
    const likeCount = results.results[resultIdx].likeCount;
    if (viewCount + likeCount > maxInteractionCount) {
      maxInteractionCount = viewCount + likeCount;
      maxInteractionIdx = resultIdx;
    }
    resultIdx++;
  }
  return results.results[maxInteractionIdx];
};

export const capitalizeTitle = (title) => {
  return title.replace(/\b\w/g, (char) => char.toUpperCase());
};

export const determineHeaderDetails = (keywords, topics) => {
  if (keywords && keywords.id) {
    let this_topic = Topic.fromId(keywords.id);
    return {
      headerTitle: capitalizeTitle(this_topic.displayText),
      headerType: RESULT_TYPES.TOPIC,
    };
  }

  if (topics.length > 0) {
    let this_topic = Topic.fromId(topics[topics.length - 1]);
    return {
      headerTitle: capitalizeTitle(this_topic.displayText),
      headerType: RESULT_TYPES.TOPIC,
    };
  }

  if (keywords) {
    return {
      headerTitle: capitalizeTitle(keywords),
      headerType: RESULT_TYPES.KEYWORD,
    };
  }
};

export const renderResultsTitle = (isLoading, results, startDate, endDate) => {
  if (isLoading) {
    return "";
  } else if (results) {
    let ss = results.location_counts.length == 1 ? "" : "s";
    let when = startDate || endDate ? "" : "recent";
    return `${results.num_snippets} matching remarks from ${results.num_results} ${when} meeting transcripts in ${results.location_counts.length} town${ss}:`;
  }
};

export const getSummaryData = (
  isSummaryLoading,
  results,
  summaryResults,
  summaryError,
) => {
  if (isSummaryLoading) return "";

  const summary = results?.summary || summaryResults?.summary;

  return !summary
    ? summaryError
      ? "Error loading summary data"
      : ""
    : summary.replaceAll("\n", "<br/>");
};

export const processEmbedResults = (results) => {
  if (!results) return { videoId: null, timestamp: null, videoTitle: null };

  const resultToEmbed = getResultToEmbed(results);
  if (!resultToEmbed)
    return { videoId: null, timestamp: null, videoTitle: null };

  return {
    videoId: resultToEmbed["vid_id"],
    timestamp: resultToEmbed["snippets"][0]["timestamp"],
    videoTitle: resultToEmbed["title"],
    videoUrl: resultToEmbed["vid_url"],
  };
};

export const handleScrollToVideoSection = (leftSectionRef, videoSectionRef) => {
  if (leftSectionRef.current && videoSectionRef.current) {
    const leftSectionTop = leftSectionRef.current.offsetTop;
    const videoSectionTop = videoSectionRef.current.offsetTop;
    leftSectionRef.current.scrollTo({
      top: videoSectionTop - leftSectionTop,
      behavior: "smooth",
    });
  }
};
