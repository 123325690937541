// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i5eE39FcIKVdslSHqtea {
  width: 100%;
  max-width: 90vw;
  max-height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  border-radius: 10px;
}

.i5eE39FcIKVdslSHqtea embed {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/GranicusEmbed.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".video_responsive {\n  width: 100%;\n  max-width: 90vw;\n  max-height: 100%;\n  aspect-ratio: 16 / 9;\n  object-fit: contain;\n  border-radius: 10px;\n}\n\n.video_responsive embed {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video_responsive": `i5eE39FcIKVdslSHqtea`
};
export default ___CSS_LOADER_EXPORT___;
