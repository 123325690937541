import React, { useState } from "react";
import { PointsOfInterestMap } from "../../../widgets/Map/Map.js";

import styles from "./CityMapSection.css";

const filterPoiBySinceSelection = (pointsOfInterest, since) => {
  // filter a set of points of interest to respect the selectbox above the point-of-interest map.
  // since=="last24" means return pointsOfInterest no older than 24 months ago, etc.
  let months = parseInt(since.replace("last", ""), 10);
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - months);
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  let minDate = `${year}-${month}-${day}`;
  return pointsOfInterest.filter((point) => {
    return point["last_mention"]["date"] > minDate;
  });
};

const MapSection = ({ place, isLoading, topics }) => {
  const [poiSince, setPoiSince] = useState("last24");

  const handleOnPoiSelectboxChange = (event) => {
    const val = event.target.value;
    setPoiSince(val);
  };

  return (
    <section className={styles.cityMapSection}>
      <div className={styles.mapTitleRow}>
        <p>
          Locations Discussed in{" "}
          <span className={styles.locationText}>{place.displayText}</span>
        </p>
        <select
          value={poiSince}
          onChange={handleOnPoiSelectboxChange}
          className={styles.dateSelect}
        >
          <option value="last24">in the past 2 years</option>
          <option value="last12">in the past year</option>
          <option value="last6">in the past 6 months</option>
          <option value="last2">in the past 2 months</option>
        </select>
      </div>

      <div className={styles.cityMapWrapper}>
        <PointsOfInterestMap
          cityQueryId={place.queryText}
          pointsOfInterest={
            !isLoading && topics
              ? filterPoiBySinceSelection(
                  topics.points_of_interest || [],
                  poiSince,
                )
              : []
          }
          centroidLongitude={place.lon}
          centroidLatitude={place.lat}
        />
      </div>
    </section>
  );
};

export default MapSection;
