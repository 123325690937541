// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IPq644zZwsApiQvPSz3e {
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.IPq644zZwsApiQvPSz3e img {
  width: 50%;
  min-width: 280px;
}
`, "",{"version":3,"sources":["webpack://./src/views/pages/ErrorPage.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.container img {\n  width: 50%;\n  min-width: 280px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `IPq644zZwsApiQvPSz3e`
};
export default ___CSS_LOADER_EXPORT___;
