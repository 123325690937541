// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../imgs/blob-blue-duo-2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cEhNRdQwGA93puyIigL3 {
  background: var(--md-grey-color);
  display: flex;
  padding-top: 10rem;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: var(--white-color);
}

.je2dRMJ7mBTIpPRyWNti {
  color: var(--white-color);
  background-color: var(--secondary-alt-color);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: Left 50%;
  background-size: auto 400%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
}

.vvWoFwdgVFVRS1n9yfbM {
  padding-top: 5rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.qZ3fJu6P61O1ZdqDjhFW {
  display: flex;
  padding-top: 2rem;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
}

.F8iRHqNUDPN9oiWuTrAC {
  width: 90%;
}

.XEegv3E3VPFFvcF2bOzT {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: var(--space-s);
}
`, "",{"version":3,"sources":["webpack://./src/pages/NearbyLocationPage/NearbyLocationsPage.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,4CAA4C;EAC5C,yDAAsD;EACtD,6BAA6B;EAC7B,0BAA0B;EAC1B,4BAA4B;EAC5B,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".errorPage {\n  background: var(--md-grey-color);\n  display: flex;\n  padding-top: 10rem;\n  align-items: center;\n  justify-content: center;\n  height: 100vh;\n  color: var(--white-color);\n}\n\n.relatedInfoBox {\n  color: var(--white-color);\n  background-color: var(--secondary-alt-color);\n  background-image: url(../../imgs/blob-blue-duo-2x.png);\n  background-position: Left 50%;\n  background-size: auto 400%;\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  height: auto;\n  min-height: 100vh;\n}\n\n.relatedInfoBoxTop {\n  padding-top: 5rem;\n  width: 80%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.relatedInfoBoxBottom {\n  display: flex;\n  padding-top: 2rem;\n  width: 100%;\n  text-align: center;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-bottom: 5rem;\n}\n\n.relatedInfoMap {\n  width: 90%;\n}\n\n.pillLinkBox {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  gap: var(--space-s);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": `cEhNRdQwGA93puyIigL3`,
	"relatedInfoBox": `je2dRMJ7mBTIpPRyWNti`,
	"relatedInfoBoxTop": `vvWoFwdgVFVRS1n9yfbM`,
	"relatedInfoBoxBottom": `qZ3fJu6P61O1ZdqDjhFW`,
	"relatedInfoMap": `F8iRHqNUDPN9oiWuTrAC`,
	"pillLinkBox": `XEegv3E3VPFFvcF2bOzT`
};
export default ___CSS_LOADER_EXPORT___;
