import React, { useEffect, useState, useRef } from "react";
import "./Variables.css";
import styles from "./GlobalNavContent.css";
import PrimaryButton from "./widgets/buttons/PrimaryButton.js";
import { useDialog } from "../context/DialogContext.js";

import civicSearchLogoRgb1 from "../imgs/civicsearch-logo-rgb-small.png";
import civicSearchLogoRgb2 from "../imgs/civicsearch-logo-rgb.png";
import SearchBar from "./SearchBar.js";

const GlobalNav = ({
  isStickyByDefault = false,
  isSearchOpenByDefault = false,
  topics: [propTopics, setPropTopics],
  location: [propLocation, setPropLocation],
  keywords: [propKeywords, setPropKeywords],
  startWithTopicSelectFocus,
}) => {
  const [isSticky, setIsSticky] = useState(isStickyByDefault);
  const [isSubNavOpen, setIsSubNavOpen] = useState(false);
  const [isSubNavSearchOpen, setIsSubNavSearchOpen] = useState(
    isSearchOpenByDefault,
  );
  const [imageSrc, setImageSrc] = useState(getImageSource(window.innerWidth));
  const [isSearchVisible, setIsSearchVisible] = useState(isSearchOpenByDefault);
  const searchFormRef = useRef(null);

  const { openDialog } = useDialog();

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.getElementById("global-nav");
      const isAtTop = nav.getBoundingClientRect().top <= 0;
      if (nav) {
        setIsSticky(isAtTop);
      }

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      const scrollPercent = (scrollTop / (documentHeight - windowHeight)) * 100;

      // Check if we're in the top 20% (80% scrolled up)
      const isNearTop = scrollPercent < 10;

      setIsSearchVisible(!isAtTop || isNearTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSubNavSearchOpen]);

  useEffect(() => {
    const handleResize = () => {
      setImageSrc(getImageSource(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSubNav = () => {
    setIsSubNavOpen(!isSubNavOpen);
    setIsSubNavSearchOpen(false);
  };

  const toggleSubNavSearch = () => {
    const isHomePage =
      window.location.pathname === "/" || window.location.pathname === "";

    if (isHomePage) {
      const heroSection = document.getElementById("hero-home");
      if (heroSection) {
        heroSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      setIsSubNavSearchOpen(!isSubNavSearchOpen);
      setIsSubNavOpen(false);
    }
  };

  const handleSubscribeClick = () => {
    openDialog("subscribe");
  };

  const handleSearchComplete = () => {
    setIsSubNavSearchOpen(false);
  };

  function getImageSource(screenWidth) {
    if (screenWidth <= 500) {
      return civicSearchLogoRgb1;
    } else {
      return civicSearchLogoRgb2;
    }
  }

  return (
    <>
      {!isSticky && (
        <nav
          id="sub-nav"
          className={`${styles.subNav}  ${isSubNavOpen ? styles.open : ""}`}
          aria-label="sub menu"
        >
          <a href="/topics">Topics</a>
          <a href="/keywords">Keywords</a>
          <a href="/cities">Places</a>
        </nav>
      )}
      <nav
        id="global-nav"
        className={`${styles.globalNav} ${isSticky ? styles.sticky : ""}`}
        aria-label="global"
      >
        <div className={`${styles.globalNavContent}`}>
          <a
            href="https://civicsearch.org"
            title="Home"
            className={styles.navLogo}
          >
            <img src={imageSrc} alt="CivicSearch" />
          </a>
          <button
            title="Main Menu"
            className={styles.navMenu}
            aria-label="Main Menu"
            onClick={toggleSubNav}
          >
            <i className="fa-solid fa-bars" aria-hidden="true"></i>
          </button>
          <nav id="nav-main" className={styles.navMain} aria-label="main menu">
            <p className={styles.browse}>BROWSE BY:</p>
            <a href="/topics">Topics</a>
            <a href="/keywords">Keywords</a>
            <a href="/cities">Places</a>
          </nav>
          <a
            onClick={toggleSubNavSearch}
            title="Search"
            className={styles.navSearch}
            aria-label="Search"
            role="button"
          >
            <i className="fa-solid fa-magnifying-glass" aria-hidden="true"></i>
          </a>
          <PrimaryButton
            id="openSubscribeDialogButton"
            size="sm"
            className={`${styles.btnSm} ${styles.subscribeButton}`}
            onClick={handleSubscribeClick}
          >
            Subscribe
          </PrimaryButton>
        </div>
      </nav>

      {isSticky && (
        <nav
          id="sub-nav"
          className={`${styles.subNav} ${isSticky ? styles.stickySubNav : ""} ${isSubNavOpen ? styles.open : ""}`}
          aria-label="sub menu"
        >
          <a href="/topics">Topics</a>
          <a href="/keywords">Keywords</a>
          <a href="/cities">Places</a>
        </nav>
      )}

      {isSticky && (
        <form
          ref={searchFormRef}
          id="sub-nav-search"
          className={`${styles.subNavSearch} ${isSticky ? styles.stickySubNavSearch : ""} ${isSubNavSearchOpen ? styles.open : ""} ${!isSearchVisible ? styles.subNavSearchFixed : ""}`}
          aria-label="sub search menu"
        >
          <div className={styles.searchBarWraper}>
            <SearchBar
              topics={[propTopics, setPropTopics]}
              location={[propLocation, setPropLocation]}
              keywords={[propKeywords, setPropKeywords]}
              startWithTopicSelectFocus={startWithTopicSelectFocus}
              onSearchComplete={handleSearchComplete}
            />
          </div>
        </form>
      )}
    </>
  );
};

export default GlobalNav;
