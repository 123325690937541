import React from "react";
import { MdOutlineAutoStories, MdFmdGood } from "react-icons/md";
import {
  LocationLink,
  KeywordLink,
} from "../../../../../widgets/buttons/PillLink.js";
import RESULT_TYPES from "../../../../../models/ResultTypes.js";
import RelatedItem from "../components/RelatedItem.js";
import SkeletonContent from "../components/SkeletonContent.js";
import styles from "./RelatedContent.css";

const PlaceContent = ({ entityName, loading, topics, place }) => {
  return (
    <>
      <RelatedItem
        icon={<MdOutlineAutoStories style={{ marginTop: "5px" }} />}
        iconColor="--keyword-color"
        title="Top Discussed Issues in {entityName} Transcripts"
        entityName={entityName}
        content={
          loading ? (
            <SkeletonContent count={25} />
          ) : (
            <div className={styles.relatedInfo}>
              {topics?.issue_keywords?.map((kw, i) => (
                <KeywordLink
                  key={i}
                  url={`/search?k=${kw}&l=${place.queryText}`}
                  className={styles.pillLink}
                >
                  {kw}
                </KeywordLink>
              ))}
            </div>
          )
        }
        highlightColor="--place-color"
        maxHeight={350}
        resultType={RESULT_TYPES.PLACE}
      />

      <RelatedItem
        icon={<MdOutlineAutoStories />}
        iconColor="--keyword-color"
        title="Words and Phrases Distinctive to {entityName} Meeting Transcripts"
        entityName={entityName}
        content={
          loading ? (
            <SkeletonContent count={25} />
          ) : (
            <div className={styles.relatedInfo}>
              {topics?.keywords?.map((kw, i) => (
                <KeywordLink
                  key={i}
                  url={`/search?k=${kw}&l=${place.queryText}`}
                  className={styles.pillLink}
                >
                  {kw}
                </KeywordLink>
              ))}
            </div>
          )
        }
        highlightColor="--place-color"
        maxHeight={350}
        resultType={RESULT_TYPES.PLACE}
      />

      <RelatedItem
        icon={<MdFmdGood />}
        iconColor="--place-color"
        title="Similar Places Based on Discussion Topics"
        content={
          loading ? (
            <SkeletonContent count={25} />
          ) : (
            <div className={styles.relatedInfo}>
              {topics?.similar_places?.map((similar_place_obj, i) => (
                <LocationLink
                  key={i}
                  url={`/${similar_place_obj.query_id}`}
                  className={styles.pillLink}
                >
                  {similar_place_obj.display_name}
                </LocationLink>
              ))}
            </div>
          )
        }
        highlightColor="--place-color"
        maxHeight={350}
        resultType={RESULT_TYPES.PLACE}
      />
    </>
  );
};

export default PlaceContent;
