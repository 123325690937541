import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import mapboxgl from "mapbox-gl";
import { DialogProvider } from "./context/DialogContext.js";
import { LocationProvider } from "./context/LocationContext.js";
import { MediaQueryProvider } from "./context/MediaQueryContext.js";
import App from "./layout/App.js";
import RouteHandler from "./models/RouteHandler.js";

import "./index.css";

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

const routes = [
  {
    path: "/",
    element: <RouteHandler mode="home" />,
  },
  {
    path: "/about",
    element: <RouteHandler mode="about" />,
  },
  {
    path: "/search",
    element: <RouteHandler mode="search" />,
  },
  {
    path: "/cities",
    element: <RouteHandler mode="list_places" />,
  },
  {
    path: "/topics",
    element: <RouteHandler mode="list_all_topics" />,
  },
  {
    path: "/keywords",
    element: <RouteHandler mode="list_keywords" />,
  },
  {
    path: "/subscribe",
    element: <RouteHandler mode="subscribe" />,
  },
  {
    path: "/feature/:featuredTopic",
    element: <RouteHandler mode="featured" />,
  },
  {
    path: "/:location",
    element: <RouteHandler mode="list_topics" />,
  },
  {
    path: "/:location/:topics",
    element: <RouteHandler mode="show_results" />,
  },
  {
    path: "/:location/:topics/:keywords",
    element: <RouteHandler mode="show_results" />,
  },
  {
    path: "*",
    element: <RouteHandler mode="invalid_route" />,
  },
];

const router = {
  browser: createBrowserRouter,
  hash: createHashRouter,
}[process.env.ROUTER_TYPE];

const root = createRoot(document.getElementById("root"));
root.render(
  <MediaQueryProvider>
    <LocationProvider>
      <DialogProvider>
        <RouterProvider router={router(routes)}>
          <App />
        </RouterProvider>
      </DialogProvider>
    </LocationProvider>
  </MediaQueryProvider>,
);
