import React from "react";
import styles from "./TranscriptsVideoSection.css";
import RESULT_TYPES from "../models/ResultTypes.js";

const TranscriptsVideoSection = ({ renderVideoEmbed, resultType }) => {
  return (
    <section
      className={`${styles.transcriptVideoSection} ${resultType === RESULT_TYPES.KEYWORD ? styles.keywordPadding : ""}`}
    >
      <div className={styles.videoWrapper}>{renderVideoEmbed()}</div>
    </section>
  );
};

export default TranscriptsVideoSection;
