import { fetchWithStatus, toQueryString } from "./funcs.js";

export const getResultToEmbed = (results) => {
  if (results.num_results === 0) return null;
  let resultIdx = 0;
  let maxInteractionCount = 0;
  let maxInteractionIdx = 0;
  while (resultIdx < results.num_results) {
    const viewCount = results.results[resultIdx].viewCount;
    const likeCount = results.results[resultIdx].likeCount;
    if (viewCount + likeCount > maxInteractionCount) {
      maxInteractionCount = viewCount + likeCount;
      maxInteractionIdx = resultIdx;
    }
    resultIdx++;
  }
  return results.results[maxInteractionIdx];
};

export const fetchResults = async ({
  topics,
  location,
  keywords,
  startDate,
  endDate,
  controller,
}) => {
  const params = {
    topics: topics.length > 0 ? topics.join(",") : undefined,
    lonlat: location ? `${location.lon},${location.lat}` : undefined,
    keywords: keywords !== "" ? keywords : undefined,
    search_radius: location?.type === "named" ? 0 : process.env.SEARCH_RADIUS,
    start_date: startDate ? startDate : undefined,
    end_date: endDate ? endDate : undefined,
  };

  try {
    const [res, status] = await fetchWithStatus(
      `search?${toQueryString(params)}`,
      { signal: controller.signal },
    );
    if (status !== 200) {
      throw res;
    }
    return res;
  } catch (err) {
    if (err?.name === "AbortError") {
      throw new Error("Request aborted");
    }
    throw err;
  }
};
