const getCSSVariable = (attr) => {
  return getComputedStyle(document.body).getPropertyValue("--" + attr);
};

const fetchWithStatus = async (endpoint, options) => {
  const url = new URL(endpoint, process.env.BE_URL).href;
  const res = await fetch(url, options);
  const status = res.status;
  const data = await res.json();
  return [data, status];
};

const toQueryString = (params) => {
  params = Object(Object.entries(params).filter((x) => x[1] !== undefined)); // remove undefined values
  return new URLSearchParams(params).toString();
};

// const classList = (...classes) => {
//   const filteredClasses = classes.filter((c) => c !== null);
//   return filteredClasses.join(" ");
// };

// function that supports both straightforward class names and conditional classes
const classList = (...classes) => {
  let filteredClasses = [];

  for (let item of classes) {
    if (typeof item === "string") {
      filteredClasses.push(item);
    } else if (typeof item === "object" && item !== null) {
      for (const [key, value] of Object.entries(item)) {
        if (value) {
          filteredClasses.push(key);
        }
      }
    }
  }

  return filteredClasses.join(" ");
};

const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

const getPlaceDisplayName = (location) => {
  const { place, state } = location;
  const parts = place.split(" ");
  const raw_name = parts.length > 1 ? parts.slice(0, -1).join(" ") : place;
  return raw_name + ", " + state;
};

export {
  fetchWithStatus,
  classList,
  toQueryString,
  getCSSVariable,
  arrayEquals,
  getPlaceDisplayName,
};
