// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KhCNrsq37m1227iQrnG3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: var(--space-4xs);
}

.Yb082h3qPhuZDH01EJFl {
  margin: 0.5rem 0.5rem 0.5rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/ResultsRelatedSection/components/RelatedContent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".relatedInfo {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  gap: var(--space-4xs);\n}\n\n.pillLink {\n  margin: 0.5rem 0.5rem 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"relatedInfo": `KhCNrsq37m1227iQrnG3`,
	"pillLink": `Yb082h3qPhuZDH01EJFl`
};
export default ___CSS_LOADER_EXPORT___;
