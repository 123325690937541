import React, { useState } from "react";
import { parse } from "../../../../../models/parser.js";
import { Location } from "../../../../../models/location.js";

import Carousel from "./Carousel.js";

import Accordion from "../../../../../widgets/Accordion/Accordion.js";
import { useMediaQueryContext } from "../../../../../context/MediaQueryContext.js";
import styles from "./SearchResultItem.css";

const getDisplayTimestamp = (seconds) =>
  new Date(seconds * 1000).toISOString().substring(11, 19);

const SearchResultItem = ({
  title,
  onVideoLinkClick,
  date,
  place,
  distance,
  snippets,
  showDistance,
  setLocation,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(null);

  const { baseHeight } = useMediaQueryContext();

  const handleExpandChange = (i, expanded) => {
    if (!expanded) {
      setIsExpanded(null);
    } else {
      setIsExpanded(i);
    }
  };

  const handleClickLeftRight = (idx) => {
    setIsExpanded(null);
    setSelectedIndex(idx);
  };

  const [year, month, day] = date.split("-").map((num) => parseInt(num, 10));

  const displayDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(new Date(year, month - 1, day));

  const displayDistance = (Math.round(distance * 10) / 10).toFixed(1);

  return (
    <li>
      <Carousel
        className={styles.snippets}
        onChange={handleClickLeftRight}
        expandedSlide={isExpanded}
      >
        {snippets.map(({ text, timestamp }, i) => {
          timestamp = Math.floor(timestamp);
          return (
            <div
              key={i}
              className={styles.snippetSlide}
              style={{
                height: isExpanded !== null ? "auto" : baseHeight,
                paddingBottom: isExpanded !== null ? "10px" : "0",
                transition:
                  "height 0.3s ease-out, padding-bottom 0.3s ease-out",
              }}
            >
              <div className={styles.customAccordionWrapper}>
                <Accordion
                  onExpandChange={(expanded) => handleExpandChange(i, expanded)}
                  expanded={isExpanded === i}
                  maxHeight={baseHeight - 50}
                  className={styles.customAccordion}
                  expandButtonClassName={styles.customAccordionExpand}
                >
                  <div className={styles.accordionTopContent}>
                    <div className={styles.more}>
                      <a
                        onClick={() =>
                          onVideoLinkClick(
                            Math.floor(snippets[selectedIndex].timestamp),
                          )
                        }
                      >
                        {title}
                      </a>
                    </div>

                    <div className={styles.transcriptInfoRow}>
                      <div className={styles.date}>{displayDate}</div>
                      <div className={styles.location}>
                        <span
                          tabIndex={0}
                          onClick={async () => {
                            setLocation(
                              await Location.fromDisplayNameStr(place),
                            );
                          }}
                        >
                          {place}
                        </span>
                      </div>
                      {showDistance && (
                        <div className={styles.distance}>
                          {displayDistance} mi away
                        </div>
                      )}

                      <span className={styles.timestamp}>
                        <a onClick={() => onVideoLinkClick(timestamp)}>
                          {getDisplayTimestamp(timestamp)}
                        </a>
                      </span>
                    </div>
                  </div>

                  <div className={styles.textWidth}>
                    <blockquote>{parse(text)}</blockquote>
                  </div>
                </Accordion>
              </div>
            </div>
          );
        })}
      </Carousel>
    </li>
  );
};

export default SearchResultItem;
