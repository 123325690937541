import React from "react";
import YoutubeEmbed from "./YoutubeEmbed.js";
import GranicusEmbed from "./GranicusEmbed.js";

import styles from "./VideoEmbed.css";

const VideoEmbed = ({
  embedVideoId,
  embedTimestamp,
  embedVideoUrl,
  autoplayEmbed,

  embedVideoTitle,
  isLoading,
  results,
  searchRequestFailed,
}) => {
  const renderVideoEmbed = () => {
    const showVideo =
      !isLoading && results && !searchRequestFailed && embedVideoId;

    return showVideo ? (
      <center className={styles.pictureInPicture}>
        <p className={styles.vCaption}>{embedVideoTitle || "Loading..."}</p>
        {embedVideoUrl ? (
          <GranicusEmbed
            baseUrl={embedVideoUrl}
            start={embedTimestamp ?? 0}
            autoplay={autoplayEmbed}
          />
        ) : (
          <YoutubeEmbed
            embedId={embedVideoId}
            start={embedTimestamp ?? 0}
            autoplay={autoplayEmbed}
          />
        )}
      </center>
    ) : (
      <center className={styles.pictureInPicture}>
        <p className={styles.vCaption}>
          {!isLoading && !embedVideoId
            ? "No Video Results for this Search"
            : embedVideoTitle || "Loading Most popular Meeting Video..."}
        </p>
        <div className={styles.skeletonVideo} />
      </center>
    );
  };

  return <div className={styles.videoSection}>{renderVideoEmbed()}</div>;
};

export default VideoEmbed;
