// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../imgs/blob-blue-duo-2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U1NM0QaOqggkNJm5QIAr {
  display: flex;
  height: 100vh;
  background-color: var(--md-grey-color);
  flex-direction: row;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.U1NM0QaOqggkNJm5QIAr::-webkit-scrollbar {
  display: none;
}

.XKONB48iGsgZvvCKW9ck {
  width: 65%;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  display: flex;
  flex-direction: column;
}

.XKONB48iGsgZvvCKW9ck::-webkit-scrollbar {
  display: none;
}

.L88PTdsVwQiMPCZ1GlQh {
  width: 35%;
  overflow-y: auto;
  background-color: var(--secondary-color);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center center;
  background-size: 500% 150%;
  background-repeat: no-repeat;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.L88PTdsVwQiMPCZ1GlQh::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .U1NM0QaOqggkNJm5QIAr {
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
  }

  .XKONB48iGsgZvvCKW9ck,
  .L88PTdsVwQiMPCZ1GlQh {
    width: 100%;
    height: auto;
    overflow-y: visible;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/ResultsPlacePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sCAAsC;EACtC,mBAAmB;EACnB,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,qBAAqB;EACrB,wBAAwB;EACxB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,wCAAwC;EACxC,yDAAsD;EACtD,kCAAkC;EAClC,0BAA0B;EAC1B,4BAA4B;EAC5B,qBAAqB;EACrB,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,sBAAsB;IACtB,aAAa;IACb,gBAAgB;EAClB;;EAEA;;IAEE,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;AACF","sourcesContent":[".resultsPage {\n  display: flex;\n  height: 100vh;\n  background-color: var(--md-grey-color);\n  flex-direction: row;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n\n.resultsPage::-webkit-scrollbar {\n  display: none;\n}\n\n.leftSection {\n  width: 65%;\n  height: 100vh;\n  overflow-y: auto;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  display: flex;\n  flex-direction: column;\n}\n\n.leftSection::-webkit-scrollbar {\n  display: none;\n}\n\n.resultsSection {\n  width: 35%;\n  overflow-y: auto;\n  background-color: var(--secondary-color);\n  background-image: url(../../imgs/blob-blue-duo-2x.png);\n  background-position: center center;\n  background-size: 500% 150%;\n  background-repeat: no-repeat;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n\n.resultsSection::-webkit-scrollbar {\n  display: none;\n}\n\n@media screen and (max-width: 1024px) {\n  .resultsPage {\n    flex-direction: column;\n    height: 100vh;\n    overflow-y: auto;\n  }\n\n  .leftSection,\n  .resultsSection {\n    width: 100%;\n    height: auto;\n    overflow-y: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultsPage": `U1NM0QaOqggkNJm5QIAr`,
	"leftSection": `XKONB48iGsgZvvCKW9ck`,
	"resultsSection": `L88PTdsVwQiMPCZ1GlQh`
};
export default ___CSS_LOADER_EXPORT___;
