import React from "react";
import "./Variables.css";
import styles from "./GlobalFooterContent.css";
import civicSearchLogoRevRgb from "../imgs/civicsearch-logo-rev-rgb.svg";
const GlobalFooter = () => {
  const handleContactClick = () => {
    window.location.href =
      "https://onelook.com/?c=about&sel=civicsearch#feedback";
  };

  return (
    <footer id="global-footer" className={styles.globalFooter}>
      <div className={`${styles.uContainer} ${styles.globalFooterContent}`}>
        <a href="https://civicsearch.org" className={styles.footerLogo}>
          <img src={civicSearchLogoRevRgb} alt="Civic Search" />
        </a>
        <nav className={styles.navFooter} aria-label="footer">
          <a href="/about">About</a>
          <button onClick={handleContactClick} className={styles.contactButton}>
            Contact
          </button>
        </nav>
        <div className={styles.copyright}>
          <p>
            &copy; {new Date().getFullYear()} Datamuse. All rights reserved.
          </p>
          <a href="/privacy-policy">Privacy Policy &amp; Terms of Use</a>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
