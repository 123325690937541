import React from "react";
import styles from "./FeaturedTopicsSection.css";
import PrimaryButton from "./widgets/buttons/PrimaryButton.js";
import SecondaryButton from "./widgets/buttons/SecondaryButton.js";
import { Link } from "react-router-dom";
import { useDialog } from "../context/DialogContext.js";
import { TopicLink } from "./widgets/NewPillLink.js";

const FeaturedTopicsSection = () => {
  const { openDialog } = useDialog();

  const handleSubscribeClick = () => {
    openDialog("subscribe");
  };

  const topics = [
    { name: "Voter turnout", url: "/feature/voter-turnout" },
    { name: "Ranked-choice voting", url: "/feature/ranked-choice-voting" },
    {
      name: "Participatory budgeting",
      url: "/feature/participatory-budgeting",
    },
    { name: "Rent control", url: "/feature/rent-control" },
    {
      name: "Accessory dwelling units",
      url: "/feature/accessory-dwelling-units",
    },
    { name: "Short-term rentals", url: "/feature/short-term-rentals" },
    { name: "Immigration", url: "/feature/immigration" },
    { name: "Climate change", url: "/feature/climate-change" },
    { name: "Cycling", url: "/feature/cycling" },
    { name: "Pickleball", url: "/feature/pickleball" },
    { name: "School absenteeism", url: "/feature/absenteeism" },
  ];

  return (
    <section id="featured-topics" className={styles.featuredTopics}>
      <div className={`${styles.uContainer} ${styles.sectionContent}`}>
        <div className={styles.colL}>
          <h2>CivicSearch Newsletters</h2>
          <p>
              Each issue of our email newsletter examines an urgent topic facing local governments across the United States and Canada.
	      Subscribe to get regular updates tailored to your location, or browse all of our past issues.
          </p>
          <div className={styles.btnGroup}>
            <PrimaryButton
              size="md"
              id="openSubscribeDialogButton"
              className={`${styles.btnSm} ${styles.subscribeButton}`}
              onClick={handleSubscribeClick}
            >
              Sign Up
            </PrimaryButton>
            <Link to="/feature/all">
              <SecondaryButton size="md">Browse All</SecondaryButton>
            </Link>
          </div>
        </div>
        <div className={styles.colR}>
          <div className={styles.pillLinkList}>
            {topics.map((topic, index) => (
              <TopicLink key={index} url={topic.url}>
                {topic.name}
              </TopicLink>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.pageDownBtnWrapper}>
        <a
          href="#trending"
          className={styles.pageDownBtn}
          role="button"
          title="Scroll Down"
          aria-label="scroll down"
        >
          <i className="fa-solid fa-circle-chevron-down" aria-hidden="true"></i>
        </a>
      </div>
    </section>
  );
};

export default FeaturedTopicsSection;
