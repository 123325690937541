import React, { useState, useEffect } from "react";
import styles from "./ResultsRelatedSection.css";
import RESULT_TYPES from "../../../../models/ResultTypes.js";
import AccordionHeader from "./components/AccordionHeader.js";
import KeywordContent from "./components/KeywordContent.js";
import TopicContent from "./components/TopicContent.js";
import PlaceContent from "./components/PlaceContent.js";
import { useMediaQueryContext } from "../../../../context/MediaQueryContext.js";

const ResultsRelatedSection = ({
  entityName,
  loading,
  results,
  location,
  topics,
  resultType,
  summaryData,
  place,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const { isMobile } = useMediaQueryContext();

  useEffect(() => {
    setIsAccordionOpen(!isMobile);
  }, [isMobile]);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  useEffect(() => {
    setIsAccordionOpen(!isMobile);
  }, [isMobile]);

  return (
    <>
      <AccordionHeader isOpen={isAccordionOpen} onToggle={toggleAccordion} />

      <section
        id="related-results"
        className={`${styles.relatedResults} ${isAccordionOpen ? styles.open : styles.closed}`}
      >
        {resultType === RESULT_TYPES.KEYWORD && (
          <KeywordContent
            results={results}
            location={location}
            entityName={entityName}
            loading={loading}
            topics={topics}
          />
        )}
        {resultType === RESULT_TYPES.TOPIC && (
          <TopicContent
            entityName={entityName}
            loading={loading}
            summaryData={summaryData}
          />
        )}
        {resultType === RESULT_TYPES.PLACE && (
          <PlaceContent
            entityName={entityName}
            loading={loading}
            topics={topics}
            place={place}
          />
        )}
      </section>
    </>
  );
};

export default ResultsRelatedSection;
