// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../imgs/blob-warm-duo-2x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ufaqDZHoHI0vA1vYjoSV {
  background-color: var(--secondary-color);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position-x: right;
  background-position-y: 50%;
  background-size: auto 150%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  transition: all 0.3s ease-in-out;
  min-height: 0;
}

.S1dw_GgKsivL4ic3Cv7k {
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 1rem 0;
}

@media screen and (min-width: 1024px) {
  .w2_cZfXKl017jyxiaLjv {
    padding: 2rem 0 5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/TranscriptVideoSection/TranscriptsVideoSection.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,yDAA4D;EAC5D,4BAA4B;EAC5B,0BAA0B;EAC1B,0BAA0B;EAC1B,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".transcriptVideoSection {\n  background-color: var(--secondary-color);\n  background-image: url(../../../../imgs/blob-warm-duo-2x.png);\n  background-position-x: right;\n  background-position-y: 50%;\n  background-size: auto 150%;\n  background-repeat: no-repeat;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-grow: 1;\n  height: 100%;\n  transition: all 0.3s ease-in-out;\n  min-height: 0;\n}\n\n.videoWrapper {\n  width: 100%;\n  max-width: 800px;\n  margin: auto;\n  padding: 1rem 0;\n}\n\n@media screen and (min-width: 1024px) {\n  .keywordPadding {\n    padding: 2rem 0 5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transcriptVideoSection": `ufaqDZHoHI0vA1vYjoSV`,
	"videoWrapper": `S1dw_GgKsivL4ic3Cv7k`,
	"keywordPadding": `w2_cZfXKl017jyxiaLjv`
};
export default ___CSS_LOADER_EXPORT___;
