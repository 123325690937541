// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uJotWf4paBnKFCaG8cmm {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  width: 95%;
  outline: none;
}

.FzeZMbZvy1yrEJK6eTSF {
  color: white;
  height: 2rem;
  background-color: hsla(60, 1%, 73%, 0.202);
}

.PyCIaAAoE2xMDfl0eQ0D {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: var(--space-4xs);
}

.eeFPGnntiNfkvsSLTlfa {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eeFPGnntiNfkvsSLTlfa:empty {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/ResultsRelatedSection/components/RelatedItem.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,eAAe;EACf,UAAU;EACV,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".customAccordion {\n  background-color: rgba(255, 255, 255, 0.2);\n  padding: 0.5rem;\n  width: 95%;\n  outline: none;\n}\n\n.customAccordionExpand {\n  color: white;\n  height: 2rem;\n  background-color: hsla(60, 1%, 73%, 0.202);\n}\n\n.pillLinkList {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  gap: var(--space-4xs);\n}\n\n.relatedInfoBox {\n  margin-bottom: 1rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.relatedInfoBox:empty {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customAccordion": `uJotWf4paBnKFCaG8cmm`,
	"customAccordionExpand": `FzeZMbZvy1yrEJK6eTSF`,
	"pillLinkList": `PyCIaAAoE2xMDfl0eQ0D`,
	"relatedInfoBox": `eeFPGnntiNfkvsSLTlfa`
};
export default ___CSS_LOADER_EXPORT___;
