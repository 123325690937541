import React from "react";

import ErrorPage from "../ErrorPage/ErrorPage.js";
import ResultsHeader from "./components/ResultsHeader.js";
import ResultsRelatedSection from "./components/ResultsRelatedSection/ResultsRelatedSection.js";
import MeetingsSection from "./components/MeetingsSection.js";
import MapSection from "./components/CityMapSection.js";
import TopTopicsSection from "./components/TopTopicsSection.js";

import RESULT_TYPES from "../../models/ResultTypes.js";

import styles from "./ResultsPlacePage.css";
import { useApiGetTopicsByCity } from "../../hooks/api-hooks.js";

const ResultsPlacePage = ({ place }) => {
  const { data: topics, error, isLoading } = useApiGetTopicsByCity(place);

  if (error) {
    return <ErrorPage />;
  }
  return (
    <div className={styles.resultsPage}>
      <section className={styles.leftSection}>
        <ResultsHeader
          headerTitle={place.displayText}
          headerType={RESULT_TYPES.PLACE}
        />
        <MeetingsSection isLoading={isLoading} topics={topics} place={place} />

        <ResultsRelatedSection
          entityName={place.displayText}
          topics={topics}
          resultType={RESULT_TYPES.PLACE}
          place={place}
          loading={isLoading}
        />
      </section>

      <section className={styles.resultsSection}>
        <MapSection place={place} isLoading={isLoading} topics={topics} />

        <TopTopicsSection topics={topics} isLoading={isLoading} place={place} />
      </section>
    </div>
  );
};

export default ResultsPlacePage;
