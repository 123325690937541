// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QL44_nTGH2piAl206BNe {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.QL44_nTGH2piAl206BNe p {
  text-align: center;
  font-size: var(--step-1);
}

.lsxfqJyQAO7tvltKddoU {
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  background-color: var(--grey-color);
}

.n4TAmIRijPGIM7FXR8MA {
  color: var(--place-color);
  font-weight: bolder;
}

.gcYJMylyBip4S4kTu1Jl {
  list-style-type: decimal;
  padding-left: 1.5rem;
  width: 95%;
  font-weight: bolder;
}

.gcYJMylyBip4S4kTu1Jl li {
  margin-bottom: 1rem;
}

.GsW4jzFoyO5HjiKPtOHt {
  font-weight: lighter;
  display: flex;
  flex-direction: column;
}

.auhpicMmYvq_npuf95W9 {
  font-size: var(--step-0);
}

.BJbMjGer4HdztqE30EtJ {
  font-weight: bolder;
  color: var(--positive-color);
}

.zV9tGsiARRysuERIwt4m {
  font-weight: bolder;
  color: var(--negative-color);
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/TopTopicsSection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,mCAAmC;AACrC;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,oBAAoB;EACpB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;EACnB,4BAA4B;AAC9B","sourcesContent":[".topTopicsSection {\n  padding: 1rem;\n  display: flex;\n  justify-content: center;\n}\n\n.topTopicsSection p {\n  text-align: center;\n  font-size: var(--step-1);\n}\n\n.topTopicsBox {\n  width: 100%;\n  border-radius: 10px;\n  padding: 1rem;\n  background-color: var(--grey-color);\n}\n\n.displayText {\n  color: var(--place-color);\n  font-weight: bolder;\n}\n\n.numberedList {\n  list-style-type: decimal;\n  padding-left: 1.5rem;\n  width: 95%;\n  font-weight: bolder;\n}\n\n.numberedList li {\n  margin-bottom: 1rem;\n}\n\n.topicInfoBox {\n  font-weight: lighter;\n  display: flex;\n  flex-direction: column;\n}\n\n.topic_info {\n  font-size: var(--step-0);\n}\n\n.topic_prob_higher_than_avg {\n  font-weight: bolder;\n  color: var(--positive-color);\n}\n\n.topic_prob_lower_than_avg {\n  font-weight: bolder;\n  color: var(--negative-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topTopicsSection": `QL44_nTGH2piAl206BNe`,
	"topTopicsBox": `lsxfqJyQAO7tvltKddoU`,
	"displayText": `n4TAmIRijPGIM7FXR8MA`,
	"numberedList": `gcYJMylyBip4S4kTu1Jl`,
	"topicInfoBox": `GsW4jzFoyO5HjiKPtOHt`,
	"topic_info": `auhpicMmYvq_npuf95W9`,
	"topic_prob_higher_than_avg": `BJbMjGer4HdztqE30EtJ`,
	"topic_prob_lower_than_avg": `zV9tGsiARRysuERIwt4m`
};
export default ___CSS_LOADER_EXPORT___;
