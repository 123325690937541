import React from "react";
import styles from "./NearbyLocationsPage.css";
import ErrorPage from "../ErrorPage/ErrorPage.js";
import LoadingPage from "../LoadingPage/LoadingPage.js";
import { LocationLink } from "../../widgets/buttons/PillLink.js";
import { PointsOfInterestMap } from "../../widgets/Map/Map.js";
import locationSearchImg from "../../imgs/undraw_location_search.svg";
import { useApiSearch } from "../../hooks/api-hooks.js";

const NearbyLocationsPage = ({ location }) => {
  const searchParams = {
    topics: [],
    location,
    keywords: "",
    startDate: null,
    endDate: null,
  };

  const {
    data: results,
    error: resultsError,
    isLoading,
  } = useApiSearch(searchParams);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!results?.places || results.places.length === 0 || resultsError) {
    return (
      <div className={styles.errorPage}>
        <ErrorPage
          image={locationSearchImg}
          header={"Sorry, the search failed\u2026"}
          message="Our dataset doesn't cover any nearby places."
        />
      </div>
    );
  }

  return (
    <div className={styles.relatedInfoBox}>
      <div className={styles.relatedInfoBoxTop}>
        <h2>Closest Places in the Database</h2>
        <br />
        <br />{" "}
        <div className={styles.pillLinkBox}>
          {results.places.slice(0, 10).map((place, i) => {
            let location_query_id = place.query_id;
            let location_display = place.display_name;

            return (
              <LocationLink key={i} url={`/${location_query_id}`}>
                {location_display}
              </LocationLink>
            );
          })}
        </div>
      </div>
      <div className={styles.relatedInfoBoxBottom}>
        <h2>Nearby Places Mentioned in Recent Meetings</h2>
        <br />
        <br />
        <div className={styles.relatedInfoMap}>
          <PointsOfInterestMap
            cityQueryId={location.queryText}
            pointsOfInterest={results.points_of_interest}
            centroidLongitude={location.lon}
            centroidLatitude={location.lat}
          />
        </div>
      </div>
    </div>
  );
};

export default NearbyLocationsPage;
