import React from "react";
import styles from "./GranicusEmbed.css";

const GranicusEmbed = ({ baseUrl, start, autostart }) => {
    const url = `${baseUrl}&view_id=1&redirect=true&entrytime=${start}&autostart=${autostart}&embed=1`;
    
    return (
	<div className={styles.video_responsive}>
	<embed
	    width="640px"
	    height="360px"
	    allowfullscreen="true"
	    src={url}
	/>
	</div>
    )
};

export default GranicusEmbed;
