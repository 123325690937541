import { Location } from "../models/location.js";

export const getUserLocation = async () => {
  const pos = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, {
      timeout: 5000,
    });
  });
  return [pos.coords.longitude, pos.coords.latitude];
};

export const handleLocation = async (handleSetValue, toggleLocateMeNav) => {
  try {
    if (toggleLocateMeNav) toggleLocateMeNav();
    const lonLat = await getUserLocation();
    setTimeout(() => {
      handleSetValue(Location.fromLonLat(...lonLat));
    }, 0);
  } catch (err) {
    alert("We were unable to get your current location.");
    console.warn(err);
  }
};
