// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xOvkomrCmUoRS3cLq_OA {
  position: relative;
  transition: 0.4s;
  overflow: hidden;
  border: none;
  outline: 3px dashed var(--lt-grey-color);
  outline-offset: 5px;
  display: flex;
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--dk-grey-color);
  color: var(--lt-grey-color);
  height: min-content;
  justify-content: center;
  width: 97%;
}

.a6FFWRxIMgRAZ9B5i14t {
  position: absolute;
  bottom: 0;
  display: none;
  width: 100%;
  padding: 0;
  border: none;
  background-color: hsla(70, 90%, 96%, 0.1);
  user-select: none;
  -webkit-user-select: none;
  color: var(--grey-color);
}

.x5ORabpZd_tVuCTaSgS2 .a6FFWRxIMgRAZ9B5i14t {
  display: block;
}

.a6FFWRxIMgRAZ9B5i14t > i {
  transform: rotate(0);
  transition: 0.4s;
}

.OtC3wVJV1ZhPGD8ndtZh .a6FFWRxIMgRAZ9B5i14t > i {
  transform: rotate(180deg);
}

.jJqLuU2Ni_6hNF6y8aKO {
  width: 100%;
}

.L9xI9FOnjjL1xYLRyDoC {
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/views/widgets/Accordion.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,wCAAwC;EACxC,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sCAAsC;EACtC,2BAA2B;EAC3B,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,aAAa;EACb,WAAW;EACX,UAAU;EACV,YAAY;EACZ,yCAAyC;EACzC,iBAAiB;EACjB,yBAAyB;EACzB,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  position: relative;\n  transition: 0.4s;\n  overflow: hidden;\n  border: none;\n  outline: 3px dashed var(--lt-grey-color);\n  outline-offset: 5px;\n  display: flex;\n  border-radius: 1rem;\n  padding: 1rem;\n  background-color: var(--dk-grey-color);\n  color: var(--lt-grey-color);\n  height: min-content;\n  justify-content: center;\n  width: 97%;\n}\n\n.expandButton {\n  position: absolute;\n  bottom: 0;\n  display: none;\n  width: 100%;\n  padding: 0;\n  border: none;\n  background-color: hsla(70, 90%, 96%, 0.1);\n  user-select: none;\n  -webkit-user-select: none;\n  color: var(--grey-color);\n}\n\n.expandable .expandButton {\n  display: block;\n}\n\n.expandButton > i {\n  transform: rotate(0);\n  transition: 0.4s;\n}\n\n.expanded .expandButton > i {\n  transform: rotate(180deg);\n}\n\n.childWrapper {\n  width: 100%;\n}\n\n.childWrapperCollasped {\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `xOvkomrCmUoRS3cLq_OA`,
	"expandButton": `a6FFWRxIMgRAZ9B5i14t`,
	"expandable": `x5ORabpZd_tVuCTaSgS2`,
	"expanded": `OtC3wVJV1ZhPGD8ndtZh`,
	"childWrapper": `jJqLuU2Ni_6hNF6y8aKO`,
	"childWrapperCollasped": `L9xI9FOnjjL1xYLRyDoC`
};
export default ___CSS_LOADER_EXPORT___;
