import React from "react";
import { Topic } from "../../../models/topic.js";
import {
  TopicLink,
  SkeletonPillLink,
} from "../../../widgets/buttons/PillLink.js";
import { BiBookBookmark } from "react-icons/bi";
import styles from "./TopTopicsSection.css";

const TopTopicsSection = ({ topics, isLoading, place }) => {
  return (
    <section className={styles.topTopicsSection}>
      <div className={styles.topTopicsBox}>
        <p>
          <BiBookBookmark
            size={24}
            color="var(--topic-color)"
            style={{ marginRight: "10px" }}
          />
          Popular Topics Discussed in{" "}
          <span className={styles.displayText}>{place.displayText}</span>
        </p>

        <ol className={styles.numberedList}>
          {!isLoading ? (
            <>
              {topics.topics.map((topic_stats_obj, i) => {
                // TODO: update code that is generating topic data to return a dict
                let topic = Topic.fromId(topic_stats_obj.topic_id);
                if (topic != null) {
                  let pct = 100.0 * topic_stats_obj.prob;
                  if (pct > 0) {
                    let relative_prob = topic_stats_obj.relative_prob;
                    let relative_prob_class =
                      relative_prob > 1
                        ? styles.topic_prob_higher_than_avg
                        : styles.topic_prob_lower_than_avg;
                    return (
                      <li key={i}>
                        <div className={styles.topicInfoBox}>
                          <TopicLink
                            size="smaller"
                            url={`/${place.queryText}/${topic.queryText}`}
                          >
                            {topic.displayText}
                          </TopicLink>
                          <span className={styles.topic_info}>
                            {` ${pct.toFixed(2)}% of this town's transcripts; `}
                            <span className={relative_prob_class}>
                              {`${relative_prob.toFixed(1)}x`}
                            </span>
                            <span>{" other towns"}</span>
                          </span>
                        </div>
                      </li>
                    );
                  }
                }
              })}
            </>
          ) : (
            <>
              {Array.from({ length: 10 }).map((_, index) => (
                <li key={index}>
                  <div>
                    <SkeletonPillLink />
                  </div>
                </li>
              ))}
            </>
          )}
        </ol>
      </div>
    </section>
  );
};

export default TopTopicsSection;
