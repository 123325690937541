import React from "react";
import { Link } from "react-router-dom";

import AboutPage from "./pages/AboutPage.js";
import AllPlacesPage from "./pages/AllPlacesPage.js";
import AllKeywordsPage from "./pages/AllKeywordsPage.js";
import AllTopicsPage from "./pages/AllTopicsPage.js";
import ResultsPlacePage from "./pages/ResultsPlacePage.js";
import ResultsKeywordPage from "./pages/ResultsKeywordPage.js";
import AlertSubscribePage from "./pages/AlertSubscribePage.js";
import FeaturedTopicPage from "./pages/FeaturedTopicPage.js";
import ErrorPage from "./pages/ErrorPage.js";

const Body = ({
  mode,
  topics,
  location: [location, setLocation],
  keywords,
  startDate,
  endDate,
  featuredTopic,
}) => {
  const renderBody = () => {
    if (mode === "about") {
      return <AboutPage />;
    } else if (mode === "list_places") {
      return <AllPlacesPage />;
    } else if (mode === "subscribe") {
      return <AlertSubscribePage keywords={keywords} />;
    } else if (mode === "featured" && featuredTopic) {
      return <FeaturedTopicPage featuredTopic={featuredTopic} />;
    } else if (mode === "list_topics") {
      return <ResultsPlacePage place={location} />;
    } else if (mode === "list_all_topics") {
      return <AllTopicsPage />;
    } else if (mode === "list_keywords") {
      return <AllKeywordsPage />;
    } else if (
      mode === "search" &&
      (!topics || topics.length == 0) &&
      !keywords &&
      !location
    ) {
      return <AboutPage />;
    } else if (mode === "search" || mode === "show_results") {
      return (
        <ResultsKeywordPage
          topics={topics}
          location={[location, setLocation]}
          startDate={startDate}
          endDate={endDate}
          keywords={keywords}
        />
      );
    } else if (mode === "invalid_route") {
      return (
        <ErrorPage
          header={"Uh oh, this URL is not valid\u2026"}
          message={
            <>
              Make a search using the input fields above, or click{" "}
              <Link to="/">here</Link> to return to the home page.
            </>
          }
        />
      );
    }
    return <ErrorPage />;
  };

  return (
    <div>
      <div>{renderBody()}</div>
    </div>
  );
};

export default Body;
