import React from "react";
import styles from "./RelatedContent.css";
import { MdOutlineAutoStories, MdFmdGood } from "react-icons/md";
import { BiBookBookmark } from "react-icons/bi";
import {
  LocationLink,
  TopicLink,
  KeywordLink,
} from "../../../../../widgets/buttons/PillLink.js";
import { Topic } from "../../../../../models/topic.js";
import RESULT_TYPES from "../../../../../models/ResultTypes.js";
import RelatedItem from "../components/RelatedItem.js";
import SkeletonContent from "../components/SkeletonContent.js";

const KeywordContent = ({ results, location, entityName, loading, topics }) => {
  const renderRelatedKeywords = () => {
    if (loading) {
      return <SkeletonContent />;
    }

    if (!results?.related_keywords?.length) {
      return null;
    }

    return (
      <div className={styles.relatedInfo}>
        {results.related_keywords.slice(0, 10).map((kw, i) => {
          return (
            <KeywordLink
              size="smaller"
              key={i}
              url={`/search?k=${kw}${location ? "&l=" + location.queryText : ""}`}
            >
              {kw}
            </KeywordLink>
          );
        })}
      </div>
    );
  };

  const renderLocationsRelatedToSearch = () => {
    if (location?.type === "named") {
      return null;
    }
    if (loading) {
      return <SkeletonContent />;
    }
    if (!results?.location_counts?.length) {
      return null;
    }
    return (
      <div className={styles.relatedInfo}>
        {results.location_counts.map((location_count_pair, i) => {
          let location_query_id = location_count_pair[0][0];
          let location_display = location_count_pair[0][1];

          return (
            <LocationLink
              size="smaller"
              key={i}
              url={`/search?k=${entityName}&l=${location_query_id}`}
            >
              {location_display}
            </LocationLink>
          );
        })}
      </div>
    );
  };

  const renderTopicsRelatedToSearch = () => {
    if (location?.type === "named") {
      return null;
    }

    if (loading) {
      return <SkeletonContent />;
    }

    if (
      location ||
      !results?.topic_counts?.length ||
      (topics && topics.length > 0)
    ) {
      return null;
    }
    return (
      <div className={styles.relatedInfo}>
        {results.topic_counts.slice(0, 10).map((topicid_count, i) => {
          let topic = Topic.fromId(topicid_count[0]);
          return (
            <TopicLink
              size="smaller"
              key={i}
              url={`/search?t=${topic.queryText}${location ? "&l=" + location.queryText : ""}`}
            >
              {topic.displayText}
            </TopicLink>
          );
        })}
      </div>
    );
  };

  const relatedLocationsContent = renderLocationsRelatedToSearch();
  const relatedTopicsContent = renderTopicsRelatedToSearch();
  const relatedKeywordsContent = renderRelatedKeywords();

  return (
    <>
      {relatedLocationsContent && (
        <RelatedItem
          icon={<MdFmdGood />}
          iconColor="--place-color"
          title="View {entityName} Mentioned in These Places"
          content={relatedLocationsContent}
          entityName={entityName}
          highlightColor="--keyword-color"
          resultType={RESULT_TYPES.KEYWORD}
        />
      )}

      {relatedTopicsContent && (
        <RelatedItem
          icon={<BiBookBookmark />}
          iconColor="--topic-color"
          title="Topics Related to"
          content={relatedTopicsContent}
          entityName={entityName}
          highlightColor="--keyword-color"
          resultType={RESULT_TYPES.KEYWORD}
        />
      )}
      {relatedKeywordsContent && (
        <RelatedItem
          icon={<MdOutlineAutoStories style={{ marginTop: "5px" }} />}
          iconColor="--keyword-color"
          title="Keywords Related to"
          content={relatedKeywordsContent}
          entityName={entityName}
          highlightColor="--keyword-color"
          resultType={RESULT_TYPES.KEYWORD}
        />
      )}
    </>
  );
};

export default KeywordContent;
