import React, { useEffect, useState, useRef } from "react";
import { fetchWithStatus } from "../../utilities/funcs.js";
import { classList as cl } from "../../utilities/funcs.js";
import { Topic } from "../../models/topic.js";

import styles from "./NewSuggestionBox.css";
import { KeywordLink, LocationLink, TopicLink } from "./NewPillLink.js";

const NewSuggestionBox = ({
  inputLetters,
  currentLocation,
  currentKeywords,
  onClose,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const suggestionsTimer = useRef();
  const suggestionsRef = useRef(null);

  let showOnlyLocations = false;
  if (inputLetters.startsWith("loc_")) {
    showOnlyLocations = true;
    inputLetters = inputLetters.slice(4, inputLetters.length);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    clearTimeout(suggestionsTimer.current);
    if (inputLetters === undefined || inputLetters.length < 3) {
      setSuggestions([]);
      return;
    }
    suggestionsTimer.current = setTimeout(async () => {
      try {
        const [res, status] = await fetchWithStatus(
          `suggest?s=${inputLetters}`,
        );
        if (status !== 200) {
          throw res;
        }
        setSuggestions(res["suggestions"].slice(0, 10));
      } catch (err) {
        console.error("Error fetching suggestions:", err);
      }
    }, 100);
  }, [inputLetters]);

  if (suggestions.length == 0) {
    return <div ref={suggestionsRef}></div>;
  } else {
    return (
      <div ref={suggestionsRef} className={cl(styles.container)}>
        <h2>TOP MATCHES</h2>
        {suggestions.map((sug, i) => {
          let kw = sug[0];
          if (sug[2] === "topic" && !showOnlyLocations) {
            let topic = Topic.fromQueryIdStr(kw);
            return (
              <TopicLink
                url={`/search?t=${kw}${currentLocation ? "&l=" + currentLocation.queryText : ""}`}
                key={i}
              >
                {topic.displayText}
              </TopicLink>
            );
          } else if (sug[2] === "place") {
            return (
              <LocationLink url={`/${sug[3]}`} key={i}>
                {kw}
              </LocationLink>
            );
          } else if (!showOnlyLocations) {
            return (
              <KeywordLink
                url={`/search?k=${kw}${currentLocation ? "&l=" + currentLocation.queryText : ""}`}
                key={i}
              >
                {kw}
              </KeywordLink>
            );
          }
        })}
      </div>
    );
  }
};

export default NewSuggestionBox;
