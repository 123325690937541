import React, { createContext, useState, useContext, useEffect } from "react";

const DialogContext = createContext(null);

export const DialogProvider = ({ children }) => {
  const [openDialogs, setOpenDialogs] = useState([]);

  useEffect(() => {
    if (openDialogs.length > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [openDialogs]);

  const openDialog = (dialogName) => {
    setOpenDialogs([...openDialogs, dialogName]);
  };

  const closeDialog = (dialogName) => {
    setOpenDialogs(openDialogs.filter((name) => name !== dialogName));
  };

  return (
    <DialogContext.Provider value={{ openDialogs, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (context === null) {
    throw Error("useContext can only be used within DialogProvider");
  }
  return context;
};
