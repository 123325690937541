import React from "react";

import styles from "./DebugConsole.css";
import { useRouteHandlerContext } from "../models/RouteHandler.js";

const DebugConsole = ({ mode, hide = false }) => {
  const { topics, location, keywords } = useRouteHandlerContext();

  const debugData = {
    mode,
    topics,
    location: location ? location.queryText : null,
    keywords,
  };

  return (
    !hide && (
      <div className={styles.container}>
        {JSON.stringify(debugData, null, 1)}
      </div>
    )
  );
};

export default DebugConsole;
