// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes pFjutDVrzynJujQ5cdRm {
  0% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

.GIRpb5ena061BUdzLvEu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bnKufHOOJcDZ4Vzrt2kI {
  animation: pFjutDVrzynJujQ5cdRm 0.8s ease-in-out infinite;
  box-sizing: border-box;
  height: 13rem;
  background-color: var(--white-color);
  border-radius: 8px;
  width: 95%;
  margin-bottom: 3rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/SearchResultsList/SearchResultsList.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yDAAkD;EAClD,sBAAsB;EACtB,aAAa;EACb,oCAAoC;EACpC,kBAAkB;EAClB,UAAU;EACV,mBAAmB;AACrB","sourcesContent":["@keyframes skeletonPulse {\n  0% {\n    opacity: 0.6;\n  }\n  70% {\n    opacity: 0.8;\n  }\n  100% {\n    opacity: 0.6;\n  }\n}\n\n.skeletonResultList {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.skeletonResultItem {\n  animation: skeletonPulse 0.8s ease-in-out infinite;\n  box-sizing: border-box;\n  height: 13rem;\n  background-color: var(--white-color);\n  border-radius: 8px;\n  width: 95%;\n  margin-bottom: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeletonResultList": `GIRpb5ena061BUdzLvEu`,
	"skeletonResultItem": `bnKufHOOJcDZ4Vzrt2kI`,
	"skeletonPulse": `pFjutDVrzynJujQ5cdRm`
};
export default ___CSS_LOADER_EXPORT___;
