import React from "react";
import { MdOutlineAutoStories, MdFmdGood } from "react-icons/md";
import { BiBookBookmark } from "react-icons/bi";
import {
  LocationLink,
  TopicLink,
  KeywordLink,
} from "../../../../../widgets/buttons/PillLink.js";
import { Topic } from "../../../../../models/topic.js";
import RESULT_TYPES from "../../../../../models/ResultTypes.js";
import RelatedItem from "../components/RelatedItem.js";
import SkeletonContent from "../components/SkeletonContent.js";

const TopicContent = ({ entityName, loading, summaryData }) => {
  const similar_topics =
    summaryData?.similar_topics?.map((x) => Topic.fromId(x["topic_id"])) || [];
  const top_places = summaryData?.similar_places || [];
  const topic_keywords = summaryData?.keywords || [];

  const highlightColor = "--topic-color";

  return (
    <>
      <RelatedItem
        icon={<MdFmdGood />}
        iconColor="--place-color"
        title="Top Places discussing"
        content={
          loading || !summaryData ? (
            <SkeletonContent />
          ) : (
            top_places.map((location, i) => (
              <LocationLink
                key={i}
                size="smaller"
                url={`/${location.query_id}/`}
              >
                {location.display_name}
              </LocationLink>
            ))
          )
        }
        entityName={entityName}
        highlightColor={highlightColor}
        maxHeight={250}
        resultType={RESULT_TYPES.TOPIC}
      />

      <RelatedItem
        icon={<BiBookBookmark />}
        iconColor="--topic-color"
        title="Topics Related to"
        content={
          loading || !summaryData ? (
            <SkeletonContent />
          ) : (
            similar_topics.map((topic, i) => (
              <TopicLink
                key={i}
                size="smaller"
                url={`/search?t=${topic.queryText}`}
              >
                {topic.displayText}
              </TopicLink>
            ))
          )
        }
        entityName={entityName}
        highlightColor={highlightColor}
        maxHeight={250}
        resultType={RESULT_TYPES.TOPIC}
      />

      <RelatedItem
        icon={<MdOutlineAutoStories style={{ marginTop: "5px" }} />}
        iconColor="--keyword-color"
        title="Keywords Related to"
        content={
          loading || !summaryData ? (
            <SkeletonContent />
          ) : (
            topic_keywords.map((kw, i) => (
              <KeywordLink key={i} size="smaller" url={`/search?k=${kw}`}>
                {kw}
              </KeywordLink>
            ))
          )
        }
        entityName={entityName}
        highlightColor={highlightColor}
        maxHeight={250}
        resultType={RESULT_TYPES.TOPIC}
      />
    </>
  );
};

export default TopicContent;
