// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BHUxZRwHgIYyYGk5YTZH {
  margin-inline: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Ue2BfW4En4kq5Mn45Bii {
  font-size: 16px;
  color: var(--white-color);
  text-align: left;
  width: 90%;
  margin-bottom: 1rem;
  font-weight: bold;
}

.nyBJP0IuE3qAbynaOVYr {
  top: 0;
  position: sticky;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@keyframes iWtWAFsp733JJPLli2Un {
  0% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

.luXb8JUJxWCQcazG79Oz {
  width: 100%;
  max-width: 90vw;
  max-height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: contain;
  border-radius: 10px;
  background: var(--dk-grey-color);
  color: transparent !important;
  pointer-events: none;
  animation: iWtWAFsp733JJPLli2Un 0.8s ease-in-out infinite;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResultPage/components/TranscriptVideoSection/components/VideoEmbed.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;EAChB,UAAU;EACV,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,MAAM;EACN,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB,mBAAmB;EACnB,gCAAgC;EAChC,6BAA6B;EAC7B,oBAAoB;EACpB,yDAAkD;AACpD","sourcesContent":[".pictureInPicture {\n  margin-inline: 2rem;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n\n.vCaption {\n  font-size: 16px;\n  color: var(--white-color);\n  text-align: left;\n  width: 90%;\n  margin-bottom: 1rem;\n  font-weight: bold;\n}\n\n.videoSection {\n  top: 0;\n  position: sticky;\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n}\n\n@keyframes skeletonPulse {\n  0% {\n    opacity: 0.6;\n  }\n  70% {\n    opacity: 0.8;\n  }\n  100% {\n    opacity: 0.6;\n  }\n}\n\n.skeletonVideo {\n  width: 100%;\n  max-width: 90vw;\n  max-height: 100%;\n  aspect-ratio: 16 / 9;\n  object-fit: contain;\n  border-radius: 10px;\n  background: var(--dk-grey-color);\n  color: transparent !important;\n  pointer-events: none;\n  animation: skeletonPulse 0.8s ease-in-out infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pictureInPicture": `BHUxZRwHgIYyYGk5YTZH`,
	"vCaption": `Ue2BfW4En4kq5Mn45Bii`,
	"videoSection": `nyBJP0IuE3qAbynaOVYr`,
	"skeletonVideo": `luXb8JUJxWCQcazG79Oz`,
	"skeletonPulse": `iWtWAFsp733JJPLli2Un`
};
export default ___CSS_LOADER_EXPORT___;
